import {useCallback, useState} from 'react'
import {Drawer} from '../../../../../components/Drawer/Drawer'
import {useDispatch} from 'react-redux'
import {useDrawerRef} from '../../../../../components/Drawer/useDrawerRef'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {
  getUserPayload,
  initialValues,
} from '../../../hooks/useFormDrawerUserData'
import * as RxCustPort from '../../../redux/CustomerPortalRedux'
import {UserForm, UserFormValues, userFormValidationSchema} from './UserForm'
import {PostUser} from '../../../redux/CustomerPortalCRUD'
import {useFormik} from 'formik'

export interface UserFormCreateProps {
  drawerRef: (drawer: Drawer | null) => void
  onChange: () => void
}

const UserFormCreate = ({drawerRef, onChange}: UserFormCreateProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [drawer, setDrawer] = useDrawerRef()
  const {pushError} = useAlerts()

  const createNewUser = useCallback(
    async (values: UserFormValues) => {
      try {
        const payload = getUserPayload({values})
        await PostUser(payload)
        dispatch(RxCustPort.actions.customers.createSuccess())
        onChange()
      } catch (e) {
        dispatch(RxCustPort.actions.customers.createFailed())
        throw e
      }
    },
    [dispatch, onChange]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: userFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await createNewUser(values)
        handleCloseDrawer()
        drawer?.hide()
      } catch (e: any) {
        pushError(e)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleCloseDrawer = useCallback(() => {
    formik.resetForm()
  }, [formik])

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      drawerRef(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )
  return (
    <Drawer ref={handleDrawerRef} onClose={handleCloseDrawer}>
      <UserForm
        title='Create User'
        onCloseButtonClick={drawer?.hide}
        isLoading={loading}
        formik={formik}
      />
    </Drawer>
  )
}

export default UserFormCreate
