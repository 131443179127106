import clsx from 'clsx'
import {ReactNode, useMemo} from 'react'

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>

export interface SelectInputItem {
  label: string
  value: string
  description?: string
}

export interface SelectInputProps extends SelectProps {
  label?: string | ReactNode
  items: SelectInputItem[]
  value: string
  noMargin?: boolean
  errorMessage?: string
  isTouched?: boolean
  allowInvalidValue?: boolean
}

export const SelectInput = ({
  label,
  items,
  placeholder,
  value,
  disabled,
  noMargin,
  className,
  isTouched,
  errorMessage,
  allowInvalidValue,
  ...inputProps
}: SelectInputProps) => {
  const isValueListed = useMemo(() => {
    return allowInvalidValue || value === '' || items.some((item) => item.value === value)
  }, [allowInvalidValue, items, value])

  const displayedPlaceholder = useMemo(() => {
    if (!isValueListed) {
      return 'Loading...'
    }
    return placeholder
  }, [isValueListed, placeholder])

  const validationClassName = useMemo(() => {
    if (!isTouched) {
      return null
    }
    return errorMessage ? 'is-invalid' : 'is-valid'
  }, [errorMessage, isTouched])

  return (
    <div className={clsx({'mb-5': !noMargin}, className)}>
      {label && <label className='form-label w-100'>{label}</label>}
      <select
        disabled={!isValueListed || disabled}
        className={clsx('form-select form-select-solid', validationClassName)}
        aria-label='Select Role'
        value={value}
        {...inputProps}
      >
        {placeholder && <option value=''>{displayedPlaceholder}</option>}
        {items.map((item) => (
          <option key={`${item.label}${item.value}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {isTouched && errorMessage && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{errorMessage}</span>
          </div>
        </div>
      )}
    </div>
  )
}
