import {FormikContextType} from 'formik'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../setup'
import {BookingFormValues} from '../../../../../../models/booking-wizard/BookingWizard'
import {BookingWizardSharedFinalizeStep} from '../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardSharedFinalizeStep'
import {useCustomerAuth} from '../../../../hooks/useCustomerAuth'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {ActivityModel} from '../../../../../../models/ems/ActivityModel'
export interface BookingWizardFinalizeStepStepProps {
  formik: FormikContextType<BookingFormValues>
  event?: EventModel | ActivityModel | null
}

export const BookingWizardFinalizeStep = ({formik, event}: BookingWizardFinalizeStepStepProps) => {
  const bookingForm: BookingFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingForm
  ) as BookingFormValues

  const auth = useCustomerAuth()

  return (
    <BookingWizardSharedFinalizeStep
      formik={formik}
      bookingForm={bookingForm}
      customerCode={auth?.getUser().code}
      event={event}
    />
  )
}
