import {useFormik} from 'formik'
import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useRouteMatch} from 'react-router-dom'
import {EventModel} from '../../../../../models/ems/EventModel'
import {ActivityModel} from '../../../../../models/ems/ActivityModel'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useBookingWiard} from './hook/useBookingWizard'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {
  EMPTY_FORM_VALUES,
  bulkConsolidatedBookingValidationSchema,
  getBulkPayload,
} from '../../../../../components/BookingWizard/hook/BulkConsolidatedBookingWizardHelper'
import {actions} from '../../../redux/CustomerPortalRedux'
import {PostBulkBooking} from '../../../redux/CustomerPortalCRUD'
import {BookingBulkConsolidatedWizard} from './BookingBulkConsolidatedWizard'

export interface BookingBulkConsolidatedCreateWizardProps {
  onSuccess?: () => void
  event?: EventModel | ActivityModel | null
  step?: number
}

interface RouterProps {
  params: {
    eventCode: string
    customerCode: string
  }
}

export const BookingBulkConsolidatedCreateWizard = ({
  onSuccess,
  event,
  step,
}: BookingBulkConsolidatedCreateWizardProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const {pushError, push} = useAlerts()
  const dispatch = useDispatch()
  const {
    params: {customerCode, eventCode},
  }: RouterProps = useRouteMatch()

  const {customer, getCustomer} = useBookingWiard()

  useOnChange(customerCode, () => {
    if (customerCode) getCustomer(customerCode)
  })

  useOnChange(step, () => {
    if (step) setCurrentStep(step)
  })

  useOnChange(eventCode, () => {
    if (eventCode) {
      formik.setFieldValue('products', [])
      formik.setFieldValue('vouchers', [])
      dispatch(actions.bookingBulkForm.setBookingBulkConsolidatedForm(formik.values))
    }
  })

  const formik = useFormik({
    initialValues: EMPTY_FORM_VALUES,
    validationSchema: bulkConsolidatedBookingValidationSchema,
    validateOnMount: true,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        const payload = getBulkPayload(values, event)
        const data = await PostBulkBooking(payload)
        if (data) {
          handleOnSkip(true)
          push({
            message: `Booking created successfully.`,
            variant: 'success',
            timeout: 3000,
          })
        }
      } catch (e: any) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useOnChange(customer, () => {
    if (customer) formik.setFieldValue('customer', customer)
  })

  const handleStepChange = useCallback(
    async (step: number) => {
      dispatch(actions.bookingBulkForm.setBookingBulkConsolidatedForm(formik.values))
      setCurrentStep(step)
    },
    [dispatch, formik.values]
  )

  const handleOnSkip = useCallback(
    (isFulfill?: boolean) => {
      let bookingCustomer = formik.values.bookingResults

      if (bookingCustomer?.length) {
        let index = -1
        const found = bookingCustomer.find((item, idx) => {
          if (item.isSelected) {
            index = idx
            return item
          }
        })
        if (found) {
          bookingCustomer[index] = {
            ...found,
            isSkip: isFulfill ? false : true,
            isSelected: false,
            isFulfill: isFulfill ? true : false,
            products: isFulfill ? formik.values.products : [],
          }

          const setSelected = bookingCustomer.find((item, idx) => {
            if (!item.isSelected && !item.isSkip && !item.isFulfill) {
              index = idx
              return item
            }
          })

          if (setSelected) {
            bookingCustomer[index] = {
              ...setSelected,
              isSelected: true,
            }

            formik.setFieldValue('bookingResults', bookingCustomer)
            handleStepChange(2)
          } else {
            handleStepChange(4)
          }
        }
        formik.setFieldValue('products', [])
      }
    },
    [formik, handleStepChange]
  )

  const handleSumbit = useCallback(() => {
    dispatch(actions.bookingBulkForm.resetBookingBulkConsolidatedForm())
    onSuccess?.()
  }, [dispatch, onSuccess])

  return (
    <BookingBulkConsolidatedWizard
      formik={formik}
      step={currentStep}
      onStepChange={handleStepChange}
      event={event}
      customer={customer}
      onSkip={handleOnSkip}
      onSubmit={handleSumbit}
    />
  )
}
