import {FormikContextType} from 'formik'
import {
  DrawerFormContainer,
  DrawerFormContainerProps,
} from '../../../../../components/Drawer/DrawerFormContainer'
import {TextInput} from '../../../../../components/inputs'
import {useCallback} from 'react'
import * as Yup from 'yup'
import {AvatarImageInput} from '../../../../../components/inputs/AvatarImageInput/AvatarImageInput'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {Validators} from '../../../../../utils/Validators'

export interface UserFormValues {
  firstName: string
  lastName: string
  email: string
  mobile: string
  photo: ImageInputValue | null
}

export interface UserFormProps extends Omit<DrawerFormContainerProps, 'isSubmitDisabled'> {
  formik: FormikContextType<UserFormValues>
}

export const userFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Minimum 2 characters').required('First Name is required.'),
  lastName: Yup.string().min(2, 'Minimum 2 characters').required('Last Name is required.'),
  email: Yup.string().email().required('Email is required.'),
  mobile: Yup.string().test('is-valid-mobile', 'Please enter a valid mobile number.', (value) => {
    return Validators.INTERNATIONAL_MOBILE_NUMBER_REGEX.test(value || '')
  }),
})

export const UserForm = ({formik, ...formProps}: UserFormProps) => {
  const handleAvatarChange = useCallback(
    (value: ImageInputValue | null) => {
      formik.setFieldValue('photo', value)
    },
    [formik]
  )

  return (
    <DrawerFormContainer
      isSubmitDisabled={formik.isSubmitting || !formik.isValid}
      onSubmit={formik.handleSubmit}
      errorMessage={formik.status}
      {...formProps}
    >
      <TextInput
        label='First Name'
        errorMessage={formik.errors.firstName}
        isTouched={formik.touched.firstName}
        placeholder='Enter First Name'
        {...formik.getFieldProps('firstName')}
      />
      <TextInput
        label='Last Name'
        errorMessage={formik.errors.lastName}
        isTouched={formik.touched.lastName}
        placeholder='Enter Last Name'
        {...formik.getFieldProps('lastName')}
      />
      <TextInput
        label='Email'
        isTouched={formik.touched.email}
        errorMessage={formik.errors.email}
        placeholder='Enter Email'
        {...formik.getFieldProps('email')}
      />
      <AvatarImageInput
        className='mb-5'
        value={formik.values.photo}
        onChange={handleAvatarChange}
        label='Photo'
      />
      <TextInput
        label='Mobile'
        isTouched={formik.touched.mobile}
        errorMessage={formik.errors.mobile}
        placeholder='Enter Mobile Number'
        {...formik.getFieldProps('mobile')}
      />
    </DrawerFormContainer>
  )
}
