import React, {createContext, useContext, useState, ReactNode} from 'react'

interface StepperStateContextType {
  registerSubmit: boolean
  setRegisterSubmit: (data: boolean) => void
  clickedRegister: boolean
  setClickedRegister: (data: boolean) => void
  loginSubmit: boolean
  setLoginSubmit: (data: boolean) => void
  resetSubmit: boolean
  setResetSubmit: (data: boolean) => void
}

const StepperStateContext = createContext<StepperStateContextType | undefined>(undefined)

export const StepperStateProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [registerSubmit, setRegisterSubmit] = useState(false)
  const [resetSubmit, setResetSubmit] = useState(false)
  const [clickedRegister, setClickedRegister] = useState(false)
  const [loginSubmit, setLoginSubmit] = useState(false)

  return (
    <StepperStateContext.Provider
      value={{
        registerSubmit,
        setRegisterSubmit,
        setClickedRegister,
        clickedRegister,
        loginSubmit,
        setLoginSubmit,
        resetSubmit,
        setResetSubmit,
      }}
    >
      {children}
    </StepperStateContext.Provider>
  )
}

export const useStepperState = () => {
  const context = useContext(StepperStateContext)
  if (!context) {
    throw new Error('useStepperState must be used within a StepperStateProvider')
  }
  return context
}