import clsx from 'clsx'
import {ReactElement} from 'react'
import {GroupedTableData} from './Table'
import type {BaseTableColumnOptions} from './TableColumn'
import {useColumnStyle} from './useColumnStyle'

export interface TableColumnRenderComponentProps<T> {
  data: T
  groupData?: GroupedTableData<T>
}

export interface TableColumnRenderOptions<T> extends BaseTableColumnOptions<T> {
  render: (props: TableColumnRenderComponentProps<T>) => ReactElement | null
}

export interface TableColumnRenderProps<T> {
  data: T
  groupData?: GroupedTableData<T>
  options: TableColumnRenderOptions<T>
  isFirstIndex?: boolean
}

export const TableColumnRender = <T,>({
  data,
  options,
  groupData,
  isFirstIndex = false,
}: TableColumnRenderProps<T>) => {
  const {className, render, cellStyle: style} = options
  const columnStyle = useColumnStyle(style)

  let Render = render

  return (
    <td
      className={clsx(
        isFirstIndex && 'ps-5',
        className,
        options.cellStyle?.align && `text-${options.cellStyle.align}`,
        options.classes?.row
      )}
      style={columnStyle}
    >
      <Render data={data} groupData={groupData} />
    </td>
  )
}
