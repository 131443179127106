import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {AdminUserModel} from '../../../models/admin/AuthModel'
import {PostOrganization, AddSharewareToOrganization, PostOrganizationRole, PostOrganizationUser, PostSystemUser, PutOrganization, PutOrganizationApplication, PutOrganizationRole, PutOrganizationUser, PutSystemUser, SearchSharewareList, SearchOrganizationList, SearchOrganizationRoleList, SearchOrganizationUserList, SearchSystemUserList, VerifyAdminAuthToken, PostSharewarePackage, PutSharewarePackage, SearchSharewarePackageList, GetSystemLogs} from './AdminCrud'
import { GlobalSearchModel } from '../../../models/GlobalSearchModel'
import { CreateSystemUserParams, SystemUserModel } from '../../../models/admin/SystemUserModel'
import { FilterModel } from '../../../models/FilterModel'
import { OrganizationModel, OrganizationModelCreateFormData, OrganizationModelCreateParams } from '../../../models/admin/OrganizationModel'
import { CreateOrganizationRoleParams, RoleModel } from '../../../models/admin/RoleModel'
import { CreateOrganizationUserParams, OrganizationUserModel } from '../../../models/admin/OrganizationUserModel'
import { AddOrganizationApplicationParams, ApplicationModel,  UpdateOrganizationApplicationParams } from '../../../models/admin/ApplicationModel'
import { CreateSharewarePackageParams, SharewarePackageModel } from '../../../models/admin/SharewarePackageModel'
import { UserLogModel } from '../../../models/admin/UserLogModel'

interface ActionWithPayload<T> extends Action {
  payload?: T
  organizationCode?: string
}


export interface AdminAuthState {
  user?: AdminUserModel
  token?: string
}

export interface IAdminState {
  auth?: AdminAuthState
  systemUsers?: GlobalSearchModel<SystemUserModel>
  userLogs?: GlobalSearchModel<UserLogModel>
  systemSharewarePackages?: GlobalSearchModel<SharewarePackageModel>
  organizations?: GlobalSearchModel<OrganizationModel>
  organizationRoles?: GlobalSearchModel<RoleModel>
  organizationUsers?: GlobalSearchModel<OrganizationUserModel>
  organizationApplications?: GlobalSearchModel<ApplicationModel>
}

const initialAuthState: IAdminState = {
  userLogs: undefined,
}

// ACTIONS TYPES
export const actionTypes = {
  auth: {
    login: '[Admin Portal] LOGIN',
    logout: '[Admin Portal] LOGOUT',
    requestAuth: '[Admin Portal] REQUEST AUTH',
    fulfillAuth: '[Admin Portal] FULFILL AUTH',
  },
  systemUsers: { 
    search: {
      execute: '[Admin Portal] SEARCH USER',
      success: '[Admin Portal] SEARCH USER SUCCESS',
      failed: '[Admin Portal] SEARCH USER FAILED',
    },
    create: {
      execute: '[Admin Portal] CREATE USER',
      success: '[Admin Portal] CREATE USER SUCCESS',
      failed: '[Admin Portal] CREATE USER FAILED',
    },
    update: {
      execute: '[Admin Portal] UPDATE USER',
      success: '[Admin Portal] UPDATE USER SUCCESS',
      failed: '[Admin Portal] UPDATE USER FAILED',
    },
    delete: {
      execute: '[Admin Portal] DELETE USER',
      success: '[Admin Portal] DELETE USER SUCCESS',
      failed: '[Admin Portal] DELETE USER FAILED',
    },
  },
  userLogs: { 
    search: {
      execute: '[Admin Portal] SEARCH USER LOGS',
      success: '[Admin Portal] SEARCH USER LOGS SUCCESS',
      failed: '[Admin Portal] SEARCH USER LOGS FAILED',
    },
  },
  systemSharewarePackages: { 
    search: {
      execute: '[Admin Portal] SEARCH SHAREWARE PACKAGES',
      success: '[Admin Portal] SEARCH SHAREWARE PACKAGES SUCCESS',
      failed: '[Admin Portal] SEARCH SHAREWARE PACKAGES FAILED',
    },
    create: {
      execute: '[Admin Portal] CREATE SHAREWARE PACKAGES',
      success: '[Admin Portal] CREATE SHAREWARE PACKAGES SUCCESS',
      failed: '[Admin Portal] CREATE SHAREWARE PACKAGES FAILED',
    },
    update: {
      execute: '[Admin Portal] UPDATE SHAREWARE PACKAGES',
      success: '[Admin Portal] UPDATE SHAREWARE PACKAGES SUCCESS',
      failed: '[Admin Portal] UPDATE SHAREWARE PACKAGES FAILED',
    },
    delete: {
      execute: '[Admin Portal] DELETE SHAREWARE PACKAGES',
      success: '[Admin Portal] DELETE SHAREWARE PACKAGES SUCCESS',
      failed: '[Admin Portal] DELETE SHAREWARE PACKAGES FAILED',
    },
  },
  organizations: { 
    search: {
      execute: '[Admin Portal] SEARCH ORGANIZATION',
      success: '[Admin Portal] SEARCH ORGANIZATION SUCCESS',
      failed: '[Admin Portal] SEARCH ORGANIZATION FAILED',
    },
    create: {
      execute: '[Admin Portal] CREATE ORGANIZATION',
      success: '[Admin Portal] CREATE ORGANIZATION SUCCESS',
      failed: '[Admin Portal] CREATE ORGANIZATION FAILED',
    },
    update: {
      execute: '[Admin Portal] UPDATE ORGANIZATION',
      success: '[Admin Portal] UPDATE ORGANIZATION SUCCESS',
      failed: '[Admin Portal] UPDATE ORGANIZATION FAILED',
    },
    delete: {
      execute: '[Admin Portal] DELETE ORGANIZATION',
      success: '[Admin Portal] DELETE ORGANIZATION SUCCESS',
      failed: '[Admin Portal] DELETE ORGANIZATION FAILED',
    },
  },
  organizationRoles: { 
    search: {
      execute: '[Admin Portal] SEARCH ORGANIZATION ROLE',
      success: '[Admin Portal] SEARCH ORGANIZATION ROLE SUCCESS',
      failed: '[Admin Portal] SEARCH ORGANIZATION ROLE FAILED',
    },
    create: {
      execute: '[Admin Portal] CREATE ORGANIZATION ROLE',
      success: '[Admin Portal] CREATE ORGANIZATION ROLE SUCCESS',
      failed: '[Admin Portal] CREATE ORGANIZATION ROLE FAILED',
    },
    update: {
      execute: '[Admin Portal] UPDATE ORGANIZATION ROLE',
      success: '[Admin Portal] UPDATE ORGANIZATION ROLE SUCCESS',
      failed: '[Admin Portal] UPDATE ORGANIZATION ROLE FAILED',
    },
    delete: {
      execute: '[Admin Portal] DELETE ORGANIZATION ROLE',
      success: '[Admin Portal] DELETE ORGANIZATION ROLE SUCCESS',
      failed: '[Admin Portal] DELETE ORGANIZATION ROLE FAILED',
    },
  },
  organizationUsers: { 
    search: {
      execute: '[Admin Portal] SEARCH ORGANIZATION USER',
      success: '[Admin Portal] SEARCH ORGANIZATION USER SUCCESS',
      failed: '[Admin Portal] SEARCH ORGANIZATION USER FAILED',
    },
    create: {
      execute: '[Admin Portal] CREATE ORGANIZATION USER',
      success: '[Admin Portal] CREATE ORGANIZATION USER SUCCESS',
      failed: '[Admin Portal] CREATE ORGANIZATION USER FAILED',
    },
    update: {
      execute: '[Admin Portal] UPDATE ORGANIZATION USER',
      success: '[Admin Portal] UPDATE ORGANIZATION USER SUCCESS',
      failed: '[Admin Portal] UPDATE ORGANIZATION USER FAILED',
    },
    delete: {
      execute: '[Admin Portal] DELETE ORGANIZATION USER',
      success: '[Admin Portal] DELETE ORGANIZATION USER SUCCESS',
      failed: '[Admin Portal] DELETE ORGANIZATION USER FAILED',
    },
  },
  organizationApplications: { 
    search: {
      execute: '[Admin Portal] SEARCH ORGANIZATION APPLICATION',
      success: '[Admin Portal] SEARCH ORGANIZATION APPLICATION SUCCESS',
      failed: '[Admin Portal] SEARCH ORGANIZATION APPLICATION FAILED',
    },
    create: {
      execute: '[Admin Portal] CREATE ORGANIZATION APPLICATION',
      success: '[Admin Portal] CREATE ORGANIZATION APPLICATION SUCCESS',
      failed: '[Admin Portal] CREATE ORGANIZATION APPLICATION FAILED',
    },
    update: {
      execute: '[Admin Portal] UPDATE ORGANIZATION APPLICATION',
      success: '[Admin Portal] UPDATE ORGANIZATION APPLICATION SUCCESS',
      failed: '[Admin Portal] UPDATE ORGANIZATION APPLICATION FAILED',
    },
    delete: {
      execute: '[Admin Portal] DELETE ORGANIZATION APPLICATION',
      success: '[Admin Portal] DELETE ORGANIZATION APPLICATION SUCCESS',
      failed: '[Admin Portal] DELETE ORGANIZATION APPLICATION FAILED',
    },
  },
}

export const reducer = persistReducer(
  {storage, key: 'outlet', whitelist: ['auth']},
  (state: IAdminState = initialAuthState, action: ActionWithPayload<IAdminState>) => {
    switch (action.type) {
      case actionTypes.auth.login: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.requestAuth: {
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.logout: {
        return {
          ...state,
          auth: undefined,
        }
      }
      case actionTypes.auth.fulfillAuth: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: auth?.user,
          },
        }
      }

      case actionTypes.systemUsers.search.success: {
        const systemUsers = action.payload?.systemUsers
        return {...state, systemUsers}
      }
      case actionTypes.systemSharewarePackages.search.success: {
        const systemSharewarePackages = action.payload?.systemSharewarePackages
        return {...state, systemSharewarePackages}
      }
      case actionTypes.organizations.search.success: {
        const organizations = action.payload?.organizations
        return {...state, organizations}
      }
      case actionTypes.organizationRoles.search.success: {
        const organizationRoles = action.payload?.organizationRoles
        return {...state, organizationRoles}
      }
      case actionTypes.organizationUsers.search.success: {
        const organizationUsers = action.payload?.organizationUsers
        return {...state, organizationUsers}
      }
      case actionTypes.organizationApplications.search.success: {
        const organizationApplications = action.payload?.organizationApplications
        return {...state, organizationApplications}
      }
      case actionTypes.userLogs.search.success: {
        const userLogs = action.payload?.userLogs
        return {...state, userLogs}
      }

      default:
        return state
    }
  }
)

// AFTER EFFECT
export function* saga() {
  yield takeLatest(actionTypes.auth.login, function* () {
    yield put(actions.auth.requestAuth())
  })

  yield takeLatest(actionTypes.auth.requestAuth, function* () {
    const { data } = yield VerifyAdminAuthToken()
    yield put(actions.auth.fulfillUser(data.user))
  })

  //system user
  yield takeLatest(
    actionTypes.systemUsers.create.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<CreateSystemUserParams>>) {
      try {
        yield PostSystemUser(action.payload)
        yield put(actions.systemUsers.createSuccess())
      } catch (e) {
        yield put(actions.systemUsers.createFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.systemUsers.update.execute,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{user: CreateSystemUserParams; code: string}>>
    ) {
      try {
        yield PutSystemUser(action.payload.user, action.payload.code)
        yield put(actions.systemUsers.updateSuccess())
      } catch (e) {
        yield put(actions.systemUsers.updateFailed())
      }
    }
  )

  yield takeLatest([actionTypes.systemUsers.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['system-user']
      )
      const {data} = yield SearchSystemUserList(filter)
      yield put(actions.systemUsers.searchSuccess(data))
    } catch (e) {
      yield put(actions.systemUsers.searchFailed())
    }
  })

  //user logs
  yield takeLatest([actionTypes.userLogs.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['user-logs']
      )
      const {data} = yield GetSystemLogs(filter)
      yield put(actions.userLogs.searchSuccess(data))
    } catch (e) {
      yield put(actions.userLogs.searchFailed())
    }
  })

//shareware packages
yield takeLatest(
  actionTypes.systemSharewarePackages.create.execute,
  function* afterEffectSaga(action: Required<ActionWithPayload<CreateSharewarePackageParams>>) {
    try {
      yield PostSharewarePackage(action.payload)
      yield put(actions.systemSharewarePackages.createSuccess())
    } catch (e) {
      yield put(actions.systemSharewarePackages.createFailed())
    }
  }
)

yield takeLatest(
  actionTypes.systemSharewarePackages.update.execute,
  function* afterEffectSaga(
    action: Required<ActionWithPayload<{user: CreateSharewarePackageParams; code: string}>>
  ) {
    try {
      yield PutSharewarePackage(action.payload.user, action.payload.code)
      yield put(actions.systemSharewarePackages.updateSuccess())
    } catch (e) {
      yield put(actions.systemSharewarePackages.updateFailed())
    }
  }
)

yield takeLatest([actionTypes.systemSharewarePackages.search.execute], function* refresh() {
  try {
    const filter: FilterModel = yield select(
      (state) => state.system.filters['system-shareware-packages']
    )
    const {data} = yield SearchSharewarePackageList(filter)
    yield put(actions.systemSharewarePackages.searchSuccess(data))
  } catch (e) {
    yield put(actions.systemSharewarePackages.searchFailed())
  }
})

//organization
  yield takeLatest(
    actionTypes.organizations.create.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<OrganizationModelCreateFormData>>) {
      try {
        yield PostOrganization(action.payload)
        yield put(actions.organizations.createSuccess())
      } catch (e) {
        yield put(actions.organizations.createFailed())
      }
    }
  )


  yield takeLatest(
    actionTypes.organizations.update.execute,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{org: OrganizationModelCreateFormData; code: string}>>
    ) {
      try {
        yield PutOrganization(action.payload.org, action.payload.code)
        yield put(actions.organizations.updateSuccess())
      } catch (e) {
        yield put(actions.organizations.updateFailed())
      }
    }
  )

  yield takeLatest([actionTypes.organizations.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['organization']
      )
      const {data} = yield SearchOrganizationList(filter)
      yield put(actions.organizations.searchSuccess(data))
    } catch (e) {
      yield put(actions.organizations.searchFailed())
    }
  })

//organization role
  yield takeLatest(
    actionTypes.organizationRoles.create.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<CreateOrganizationRoleParams>>) {
      try {
        yield PostOrganizationRole(action.payload)
        yield put(actions.organizationRoles.createSuccess())
      } catch (e) {
        yield put(actions.organizationRoles.createFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.organizationRoles.update.execute,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{user: CreateOrganizationRoleParams; code: string}>>
    ) {
      try {
        yield PutOrganizationRole(action.payload.user, action.payload.code)
        yield put(actions.organizationRoles.updateSuccess())
      } catch (e) {
        yield put(actions.organizationRoles.updateFailed())
      }
    }
  )

  yield takeLatest([actionTypes.organizationRoles.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['organization-role']
      )
      const {data} = yield SearchOrganizationRoleList(filter)
      yield put(actions.organizationRoles.searchSuccess(data))
    } catch (e) {
      yield put(actions.organizationRoles.searchFailed())
    }
  })

  //organization user
  yield takeLatest(
    actionTypes.organizationUsers.create.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<CreateOrganizationUserParams>>) {
      try {
        yield PostOrganizationUser(action.payload)
        yield put(actions.organizationUsers.createSuccess())
      } catch (e) {
        yield put(actions.organizationUsers.createFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.organizationUsers.update.execute,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{user: CreateOrganizationUserParams; code: string}>>
    ) {
      try {
        yield PutOrganizationUser(action.payload.user, action.payload.code)
        yield put(actions.organizationUsers.updateSuccess())
      } catch (e) {
        yield put(actions.organizationUsers.updateFailed())
      }
    }
  )

  yield takeLatest([actionTypes.organizationUsers.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['organization-user']
      )
      const {data} = yield SearchOrganizationUserList(filter)
      yield put(actions.organizationUsers.searchSuccess(data))
    } catch (e) {
      yield put(actions.organizationUsers.searchFailed())
    }
  })

  //organization application
  yield takeLatest(
    actionTypes.organizationApplications.create.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<AddOrganizationApplicationParams>>) {
      try {
        yield AddSharewareToOrganization(action.payload, action.organizationCode)
        yield put(actions.organizationApplications.createSuccess())
      } catch (e) {
        yield put(actions.organizationApplications.createFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.organizationApplications.update.execute,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{user: UpdateOrganizationApplicationParams; code: string}>>
    ) {
      try {
        yield PutOrganizationApplication(action.payload.user, action.payload.code)
        yield put(actions.organizationApplications.updateSuccess())
      } catch (e) {
        yield put(actions.organizationApplications.updateFailed())
      }
    }
  )

  yield takeLatest([actionTypes.organizationApplications.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['organization-application']
      )
      const {data} = yield SearchSharewareList(filter)
      yield put(actions.organizationApplications.searchSuccess(data))
    } catch (e) {
      yield put(actions.organizationApplications.searchFailed())
    }
  })
}

// ACTIONS
export const actions = {
  auth: {
    login: (token: string) => ({type: actionTypes.auth.login, payload: {auth: {token}}}),
    logout: () => ({type: actionTypes.auth.logout}),
    requestAuth: () => ({type: actionTypes.auth.requestAuth}),
    fulfillUser: (user: AdminUserModel) => ({
      type: actionTypes.auth.fulfillAuth,
      payload: {
        auth: {
          user,
        },
      },
    }),
  },
  systemUsers: {
    search: () => ({type: actionTypes.systemUsers.search.execute}),
    searchSuccess: (data: GlobalSearchModel<SystemUserModel>) => ({
      type: actionTypes.systemUsers.search.success,
      payload: {systemUsers: data},
    }),
    searchFailed: () => ({type: actionTypes.systemUsers.search.failed}),
    create: (payload: CreateSystemUserParams) => ({
      type: actionTypes.systemUsers.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.systemUsers.create.success}),
    createFailed: () => ({type: actionTypes.systemUsers.create.failed}),

    update: (payload: CreateSystemUserParams) => ({
      type: actionTypes.systemUsers.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.systemUsers.update.success}),
    updateFailed: () => ({type: actionTypes.systemUsers.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.systemUsers.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.systemUsers.delete.success}),
    deleteFailed: () => ({type: actionTypes.systemUsers.delete.failed}),
  },
  userLogs: {
    search: () => ({type: actionTypes.userLogs.search.execute}),
    searchSuccess: (data: GlobalSearchModel<UserLogModel>) => ({
      type: actionTypes.userLogs.search.success,
      payload: {userLogs: data},
    }),
    searchFailed: () => ({type: actionTypes.userLogs.search.failed}),
   
  },

  systemSharewarePackages: {
    search: () => ({type: actionTypes.systemSharewarePackages.search.execute}),
    searchSuccess: (data: GlobalSearchModel<SharewarePackageModel>) => ({
      type: actionTypes.systemSharewarePackages.search.success,
      payload: {systemSharewarePackages: data},
    }),
    searchFailed: () => ({type: actionTypes.systemSharewarePackages.search.failed}),
    create: (payload: CreateSharewarePackageParams) => ({
      type: actionTypes.systemSharewarePackages.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.systemSharewarePackages.create.success}),
    createFailed: () => ({type: actionTypes.systemSharewarePackages.create.failed}),

    update: (payload: CreateSharewarePackageParams) => ({
      type: actionTypes.systemSharewarePackages.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.systemSharewarePackages.update.success}),
    updateFailed: () => ({type: actionTypes.systemSharewarePackages.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.systemSharewarePackages.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.systemSharewarePackages.delete.success}),
    deleteFailed: () => ({type: actionTypes.systemSharewarePackages.delete.failed}),
  },

  organizations: {
    search: () => ({type: actionTypes.organizations.search.execute}),
    searchSuccess: (data: GlobalSearchModel<OrganizationModel>) => ({
      type: actionTypes.organizations.search.success,
      payload: {organizations: data},   
    }),
    searchFailed: () => ({type: actionTypes.organizations.search.failed}),
    create: (payload: OrganizationModelCreateParams) => ({
      type: actionTypes.organizations.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.organizations.create.success}),
    createFailed: () => ({type: actionTypes.organizations.create.failed}),

    update: (payload: OrganizationModelCreateParams) => ({
      type: actionTypes.organizations.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.organizations.update.success}),
    updateFailed: () => ({type: actionTypes.organizations.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.organizations.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.organizations.delete.success}),
    deleteFailed: () => ({type: actionTypes.organizations.delete.failed}),
  },
  
  organizationRoles: {
    search: () => ({type: actionTypes.organizationRoles.search.execute}),
    searchSuccess: (data: GlobalSearchModel<RoleModel>) => ({
      type: actionTypes.organizationRoles.search.success,
      payload: {organizationRoles: data},
    }),
    searchFailed: () => ({type: actionTypes.organizationRoles.search.failed}),
    create: (payload: CreateOrganizationRoleParams) => ({
      type: actionTypes.organizationRoles.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.organizationRoles.create.success}),
    createFailed: () => ({type: actionTypes.organizationRoles.create.failed}),

    update: (payload: CreateOrganizationRoleParams) => ({
      type: actionTypes.organizationRoles.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.organizationRoles.update.success}),
    updateFailed: () => ({type: actionTypes.organizationRoles.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.organizationRoles.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.organizationRoles.delete.success}),
    deleteFailed: () => ({type: actionTypes.organizationRoles.delete.failed}),
  },

  organizationUsers: {
    search: () => ({type: actionTypes.organizationUsers.search.execute}),
    searchSuccess: (data: GlobalSearchModel<OrganizationUserModel>) => ({
      type: actionTypes.organizationUsers.search.success,
      payload: {organizationUsers: data},
    }),
    searchFailed: () => ({type: actionTypes.organizationUsers.search.failed}),
    create: (payload: CreateOrganizationUserParams) => ({
      type: actionTypes.organizationUsers.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.organizationUsers.create.success}),
    createFailed: () => ({type: actionTypes.organizationUsers.create.failed}),

    update: (payload: CreateOrganizationUserParams) => ({
      type: actionTypes.organizationUsers.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.organizationUsers.update.success}),
    updateFailed: () => ({type: actionTypes.organizationUsers.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.organizationUsers.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.organizationUsers.delete.success}),
    deleteFailed: () => ({type: actionTypes.organizationUsers.delete.failed}),
  },

  organizationApplications: {
    search: () => ({type: actionTypes.organizationApplications.search.execute}),
    searchSuccess: (data: GlobalSearchModel<ApplicationModel>) => ({
      type: actionTypes.organizationApplications.search.success,
      payload: {organizationApplications: data},
    }),
    searchFailed: () => ({type: actionTypes.organizationApplications.search.failed}),
    create: (payload: AddOrganizationApplicationParams) => ({
      type: actionTypes.organizationApplications.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.organizationApplications.create.success}),
    createFailed: () => ({type: actionTypes.organizationApplications.create.failed}),

    update: (payload: UpdateOrganizationApplicationParams) => ({
      type: actionTypes.organizationApplications.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.organizationApplications.update.success}),
    updateFailed: () => ({type: actionTypes.organizationApplications.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.organizationApplications.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.organizationApplications.delete.success}),
    deleteFailed: () => ({type: actionTypes.organizationApplications.delete.failed}),
  },

}
