import clsx from 'clsx'
import {FormikContextType} from 'formik'
import {useMemo} from 'react'
import {PasswordInput} from '../../../../components/inputs/PasswordInput'
import { FormikProps } from '../auth/Login'


interface LoginFieldsProps {
  formik: FormikContextType<FormikProps>
}
export const LoginFields = ({formik}: LoginFieldsProps) => {
  return useMemo(() => {
    return (
      <>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
          <input
            placeholder='Email or Mobile'
            {...formik.getFieldProps('username')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.username && formik.errors.username},
              {
                'is-valid': formik.touched.username && !formik.errors.username,
              }
            )}
            type='text'
            name='username'
            autoComplete='off'
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
        <PasswordInput
          label='Password'
          forgotPasswordLink='/auth/forgot-password'
          placeholder='Password'
          errorMessage={formik.errors.password}
          isTouched={formik.touched.password}
          {...formik.getFieldProps('password')}
        />
      </>
    )
  }, [formik])
}
