import {useCallback, useMemo, useState} from 'react'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {Button} from '../../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../../components/inputs/MetronicIcon'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {HasCode, idExtractor} from '../../../../../utils/idExtractor'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {SwalUtils} from '../../../../../utils/SwalUtils'
import {PortalSearchModel} from '../../../../../models/customer-portal/PortalSearchModel'
import {UserGroupModel, UserModel} from '../../../../../models/customer-portal/UserModel'
import {UserTableRowActions} from './UserTableRowActions'
import {ActivateUsers, DeleteUser, PostUserSmartPin, ResetUserMFA} from '../../../redux/CustomerPortalCRUD'
import {UserSelectionAction} from './UserSelectionAction'
import {UserStatusColumn} from './UserStatusColumn'
import {Plural} from '../../../../../utils/Plural'
import { useLoadingState } from '../../../../../components/hooks/useLoadingState'
import { SetSmartPinModal } from '../../../../default/svc/components/modals/SetSmartPinModal'
import { useTableActionModal } from '../../../../../components/hooks/useTableActionModal'

export interface UserTableProps {
  onFilter: (filter: FilterModel) => void
  onDelete?: () => void
  onResetMFA?: () => void
  onActivate?: () => void
  onEdit?: (data: UserModel) => void
  data?: GlobalSearchModel<UserModel> | PortalSearchModel<UserGroupModel>
  onNewUserClick?: () => void
}
export const UserTable = ({
  onFilter,
  onDelete,
  onResetMFA,
  onActivate,
  onEdit,
  data,
  onNewUserClick,
}: UserTableProps) => {
  const {pushError, push} = useAlerts()
  const [selected, setSelected] = useState<TableRowId[]>([])
  const {hiddenColumns, setHiddenColumns} = useTableOptions({tableName: 'user'})
  const {setIsLoading: setIsTableLoading, isLoading: isTableLoading} = useLoadingState()
  const {clearModalData, modalData, setModalData, getModalProps} = useTableActionModal<
  ModalType,
  UserModel
>()


  const tableItems = useMemo((): UserModel[] => {
    if (data) {
      if (Array.isArray(data.data)) {
        return data.data
      } else {
        return Object.entries(data.data).reduce<UserModel[]>((acc, [, users]) => {
          users.forEach((user) => {
            acc.push(user)
          })
          return acc
        }, [])
      }
    }
    return []
  }, [data])

  const activateUsersByCodes = useCallback(
    async (codes: string[]) => {
      await SwalUtils.asyncConfirm(
        async () => {
          try {
            await ActivateUsers(codes)
            push({
              message: `Successfully activated ${Plural.pluralize(codes.length, 'user', 'users')}.`,
              timeout: 5000,
              variant: 'success',
            })
            onActivate && onActivate()
          } catch (e: any) {
            pushError(e)
          }
        },
        {
          text: `Activate ${Plural.pluralize(codes.length, 'user', 'users')}?`,
        }
      )
    },
    [onActivate, push, pushError]
  )

  const activateUser = useCallback(
    (data: UserModel | UserModel[]) => {
      const codes = Array.isArray(data) ? data.map((item) => item.code) : [data.code]
      activateUsersByCodes(codes)
    },
    [activateUsersByCodes]
  )

  const resetMfaUsers = useCallback(
    async (userCodes: string[]) => {
      SwalUtils.asyncConfirm(
        async () => {
          const doneLoading = setIsTableLoading(userCodes)
          try {
            await ResetUserMFA(userCodes)
          } catch (e: any) {
            pushError(e)
          } finally {
            doneLoading()
          }
        },
        {
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          confirmButtonText: 'Yes, reset it!',
        }
      )
    },
    [pushError, setIsTableLoading]
  )

  const handleSendSmartPin = useCallback(
    async (pin: string) => {
      if (modalData.data) {
        const codes = Array.isArray(modalData.data)
          ? modalData.data.map((g: {code: string}) => g.code)
          : [modalData.data.code]
        const doneLoading = setIsTableLoading(codes)
        try {
          await PostUserSmartPin(pin, codes)

          push({
            message: 'Smart Pin has been set',
            timeout: 5000,
            variant: 'success',
          })

          clearModalData()
        } catch (e: any) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [clearModalData, modalData.data, push, pushError, setIsTableLoading]
  )

  const deleteUser = useCallback(
    async (codes: string[]) => {
      const response = await SwalUtils.deleteItem()
      if (response.isConfirmed) {
        try {
          await DeleteUser(codes)
          push({
            message: `User successfully cancelled.`,
            timeout: 5000,
            variant: 'success',
          })
          onDelete && onDelete()
        } catch (e: any) {
          pushError(e)
        }
      }
    },
    [onDelete, push, pushError]
  )

  const handleDeleteBulk = useCallback(
    (data: HasCode[]) => {
      deleteUser(data.map((user) => user.code))
    },
    [deleteUser]
  )

  const handleDeleteSingle = useCallback(
    (data: HasCode) => {
      deleteUser([data.code])
    },
    [deleteUser]
  )

  const handleResetSingle = useCallback(
    (user: UserModel) => {
      resetMfaUsers([user.code])
    },
    [resetMfaUsers]
  )

  const handleResetBulk = useCallback(
    (users: UserModel[]) => {
      resetMfaUsers(users.map((user) => user.code))
    },
    [resetMfaUsers]
  )

  const getTableActionHandler = useCallback(
    (modalType: ModalType) => (data: UserModel | UserModel[]) => {
      setModalData({
        modalType,
        data,
      })
    },
    [setModalData]
  )

  const rowActions = useCallback(
    (data: UserModel) => (
      <UserTableRowActions
        data={data}
        onDelete={handleDeleteSingle}
        onEdit={onEdit}
        onActivate={activateUser}
        isLoading={isTableLoading}
        onReset={handleResetSingle}
        onSetSmartPin={getTableActionHandler('send-smart-pin')}
      />
    ),
    [activateUser, getTableActionHandler, handleDeleteSingle, handleResetSingle, isTableLoading, onEdit]
  )

  return (
    <>
    <FilterTable
      onFilter={onFilter}
      idExtractor={idExtractor}
      hiddenColumns={hiddenColumns}
      onHiddenColumnsChange={setHiddenColumns}
      actions={rowActions}
      initialFilters={{
        filters: {
          status: ['active', 'pending'],
        },
      }}
      selectionAction={
        <UserSelectionAction
          items={tableItems}
          selected={selected}
          onDelete={handleDeleteBulk}
          onActivate={activateUser}
          onResetMFA={handleResetBulk}
          onSetSmartPin={getTableActionHandler('send-smart-pin')}
        />
      }
      rightToolbar={
        <Button variant='primary' onClick={onNewUserClick}>
          <MetronicIcon iconType='Navigation' iconName='Plus' /> New User
        </Button>
      }
      data={tableItems}
      currentPageNumber={data?.page}
      columns={columns}
      totalItems={data?.total || 0}
      selection={selected}
      onSelectionChange={setSelected}
    />
    <SetSmartPinModal {...getModalProps('send-smart-pin')} onSubmit={handleSendSmartPin} />
    </>
  )
}

const columns: TableColumnOptions<UserModel>[] = [
  {
    field: 'code',
    label: 'Code',
    sortable: true,
    hideable: true,
    className: 'min-w-80px',
  },
  {
    field: 'firstName',
    label: 'First Name',
    sortable: true,
    hideable: true,
  },
  {
    field: 'lastName',
    label: 'Last Name',
    sortable: true,
    hideable: true,
  },
  {
    field: 'email',
    label: 'Email',
    sortable: true,
    hideable: true,
  },

  {
    field: 'mobile',
    label: 'mobile',
    sortable: true,
    hideable: true,
  },
  {
    field: 'status',
    label: 'Status',
    sortable: true,
    hideable: true,
    render: ({data}) => <UserStatusColumn data={data} />,
  },
]


type ModalType = 'send-smart-pin'