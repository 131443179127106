import { useMemo } from 'react'
import { FilterTable } from '../../../../../components/tables/FilterTable'
import { TableColumnOptions } from '../../../../../components/tables/TableColumn'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import { UserLogModel } from '../../../../../models/UserLogModel'
import moment from 'moment-timezone'


export interface ExtUserLogModel extends UserLogModel {
    code: string
  }
  
  export interface LogTableProps {
    onFilter: (filter: FilterModel) => void
    data?: GlobalSearchModel<UserLogModel>
  }
  
  export const LogTable = ({ onFilter, data }: LogTableProps) => {

    const generateUniqueId = () => {
        return `id-${Math.random().toString(36).substr(2, 9)}`
      }
  
    const tableItems = useMemo((): ExtUserLogModel[] => {
      if (data) {
        const addUniqueCode = (log: UserLogModel): ExtUserLogModel => ({
          ...log,
          code: generateUniqueId(),
        })
  
        if (Array.isArray(data.data)) {
          return data.data.map(addUniqueCode)
        } else {
          return Object.entries(data?.data).reduce<ExtUserLogModel[]>(
            (acc, [group, logs]) => {
              (logs as UserLogModel[]).forEach((log) => {
                acc.push(addUniqueCode(log))
              })
              return acc
            },
            []
          )
        }
      }
      return []
    }, [data])
  
    const tableColumns: TableColumnOptions<ExtUserLogModel>[] = [
      {
        field: 'customer',
        label: 'user',
        sortable: true,
        render: ({data}) => <>{data.customer?.name}</>,
      
        className: 'min-w-80px',
      },
      {
        field: 'description',
        label: 'description',
        sortable: true,
      },
      {
        field: 'moduleName',
        label: 'module',
        sortable: true,
      },
      {
        field: 'type',
        label: 'type',
        sortable: true,
      },
      {
        field: 'createdAt',
        label: 'created',
        sortable: true,
        className: 'min-w-150px mw-200px',
        render: ({ data }) => (
            <>
              {moment(data.createdAt).format('MMM DD, YYYY hh:mm A').replace(/^[a-zA-Z]{3}/, (month) => month.toUpperCase())}
            </>
          ),
      },
    ]
  
    const idExtractor = (data: ExtUserLogModel) => data.code
  
    return (
      <>
        <FilterTable
          onFilter={onFilter}
          idExtractor={idExtractor}
          data={tableItems}
          currentPageNumber={data?.page}
          columns={tableColumns}
          totalItems={data?.total || 0}
        />
      </>
    )
  }