import {ReactNode, useCallback, useMemo, useState} from 'react'
import {TicketSeatModalForm, TicketSeatModalFormValues} from '../../modals/TicketSeatModalForm'
import {useModalState} from '../../../../../components/modals/useModalState'

import {FilterTable} from '../../../../../components/tables/FilterTable'
import {Table} from '../../../../../components/tables/Table'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {idExtractor} from '../../../../../utils/idExtractor'

import {useTicketTableActions} from '../../../hooks/useTicketTableActions'
import {TicketTableRowActions} from './TicketTableRowActions'
import {TicketTableSelectionActions} from './TicketTableSelectionActions'
import {EmailModal, InitialValuesProps} from '../../modals/EmailModal'
import {EventModel} from '../../../../../models/ems/EventModel'
import {ShareEmailTicketProps} from '../../../redux/CustomerPortalCRUD'

export interface TicketTableFlatProps {
  data?: TicketPortalModel[]
  onFilter?: (filter: FilterModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  className?: string
  initialFilters?: FilterModel
  total?: number
  advancedFilters?: ReactNode
  columns: TableColumnOptions<TicketPortalModel>[]
  event?: EventModel
}

export const TicketTableFlat = ({
  data,
  onFilter,
  onRefresh,
  onRefreshCallback,
  initialFilters,
  className,
  total,
  advancedFilters,
  columns,
  event,
}: TicketTableFlatProps) => {
  const [selected, setSelected] = useState<TableRowId[]>([])
  const [activeTicket, setActiveTicket] = useState<TicketPortalModel | null>(null)
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState<TicketPortalModel[]>()
  const {
    isOpen: isSelectionModalOpen,
    hide: hideSelectionModal,
    open: openSelectionModal,
  } = useModalState()
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer-portal-ticket-flat',
    defaults: {
      hiddenColumns: ['event'],
    },
  })
  const tableData = useMemo((): TicketPortalModel[] => {
    if (data) {
      return data
    }
    return []
  }, [data])
  const {
    isKeyLoading,
    handleCancelBulk,
    cancelSingle,
    reassignSeat,
    handleDownloadBulk,
    isLoading,
    shareByEmail,
    loader,
  } = useTicketTableActions(onRefresh, onRefreshCallback)

  const handleReassignSeat = useCallback(
    async (data: TicketPortalModel) => {
      setActiveTicket(data)
      openSelectionModal()
    },
    [openSelectionModal]
  )

  const handleSelectionModalClose = useCallback(() => {
    hideSelectionModal()
    setActiveTicket(null)
  }, [hideSelectionModal])

  const handleTicketReselectionModalFormSubmit = useCallback(
    async (values: Required<TicketSeatModalFormValues>) => {
      await reassignSeat(values)
      hideSelectionModal()
    },
    [hideSelectionModal, reassignSeat]
  )

  const handleEmailModal = useCallback(
    async (data: TicketPortalModel | TicketPortalModel[]) => {
      if (!Array.isArray(data)) setSelectedTickets([data])
      else setSelectedTickets(data)
      setOpenEmailModal(true)
    },
    [hideSelectionModal]
  )

  const handleShareViaEmail = useCallback(
    async (values: InitialValuesProps) => {
      if (values) {
        if (selectedTickets && event) {
          values.eventCode = event.code
          const response = await shareByEmail(
            values,
            selectedTickets.map((item) => item.code)
          )
          if (response) {
            setOpenEmailModal(false)
            return response
          }
        }
      }
      onRefresh && onRefresh()
    },
    [onRefresh, selectedTickets]
  )

  const rowActions = useCallback(
    (data: TicketPortalModel) => {
      return (
        <TicketTableRowActions
          loading={isKeyLoading(data.code)}
          data={data}
          onReassignSeat={handleReassignSeat}
          onCancel={cancelSingle}
          // onDownload={downloadSingle}
          onShareViaEmail={handleEmailModal}
        />
      )
    },
    [isKeyLoading, handleReassignSeat, cancelSingle]
  )

  // const isSelectDisabled = useCallback(
  //   (rowData: TicketPortalModel) => {
  //     const firstSelection = selected[0]
  //     const foundData = data?.find((ticket) => {
  //       return ticket.code === firstSelection
  //     })
  //     if (foundData) {
  //       return foundData?.customerCode !== rowData.customerCode
  //     }
  //     return false
  //   },
  //   [data, selected]
  // )

  return (
    <>
      {onFilter ? (
        <FilterTable
          hideSelectAll
          advancedFilters={advancedFilters}
          // isSelectDisabled={isSelectDisabled}
          className={className}
          actions={rowActions}
          selectionAction={
            <TicketTableSelectionActions
              items={tableData}
              selected={selected}
              onCancel={handleCancelBulk}
              disabled={isLoading}
              onDownload={handleDownloadBulk}
              onShareViaEmail={handleEmailModal}
            />
          }
          onHiddenColumnsChange={setHiddenColumns}
          onSelectionChange={setSelected}
          hiddenColumns={hiddenColumns}
          columns={columns}
          data={tableData}
          selection={selected}
          totalItems={total}
          idExtractor={idExtractor}
          onFilter={onFilter}
          initialFilters={initialFilters}
        />
      ) : (
        <Table
          className={className}
          actions={rowActions}
          body={
            <>
              <TicketTableSelectionActions
                items={tableData}
                selected={selected}
                onCancel={handleCancelBulk}
                disabled={isLoading}
                onDownload={handleDownloadBulk}
              />
              {advancedFilters}
            </>
          }
          onSelectionChange={setSelected}
          columns={columns}
          data={tableData}
          selection={selected}
          idExtractor={idExtractor}
        />
      )}
      <TicketSeatModalForm
        ticket={activeTicket}
        onClose={handleSelectionModalClose}
        open={isSelectionModalOpen}
        onSubmit={handleTicketReselectionModalFormSubmit}
      />
      {selectedTickets && (
        <EmailModal
          isLoading={loader}
          data={selectedTickets}
          setOpen={setOpenEmailModal}
          open={openEmailModal}
          onSubmit={handleShareViaEmail}
        />
      )}
    </>
  )
}
