import {useCallback, useMemo} from 'react'
import {UserModel, UserModelCreateFormDataParams, UserModelCreateParams} from '../../../models/customer-portal/UserModel'
import {UserFormValues} from '../components/DrawerForm/UserFormDrawer/UserForm'
import { FileInputValueCollection } from '../../../components/inputs/FileInput/FileInputValueCollection'
import { ImageInputValue } from '../../../components/inputs/FileInput/ImageInputValue'
import { ApiFormData } from '../../../utils/ApiFormData'
import { useAppConfig } from '../../app-config/hooks/useAppConfig'

export const initialValues: UserFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  photo: null,
}

export const useFormDrawerUserData = () => {
  const {staticUrls} = useAppConfig()
  
  const mapUserDataToFormValues = useCallback((user: UserModel): UserFormValues => {
    return {
      email: user.email,
      mobile: user.mobile || '',
      firstName: user.firstName,
      lastName: user.lastName,
      photo: user.photo ? new ImageInputValue(staticUrls.public, user.photo) : null,
    }
  }, [staticUrls.public])

  return useMemo(
    () => ({
      mapUserDataToFormValues,
    }),
    [mapUserDataToFormValues]
  )
}

interface getUserPayloadProps {
  values: UserFormValues
  editing?: boolean
}

export const getUserPayload = ({values}: getUserPayloadProps) => {
  const photo = new FileInputValueCollection<ImageInputValue>()
  if (values.photo) {
    photo.add(values.photo)
  }
  
  const payload: UserModelCreateParams = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    mobile: values.mobile,
  }

  return new ApiFormData<UserModelCreateFormDataParams>({
    photo: photo,
    data: JSON.stringify(payload),
  })
}
