import moment from 'moment'
import {useMemo} from 'react'
import {useRootStateSelector} from '../../hooks/useRootStateSelector'
import {DateUtil} from '../../../utils/DateUtil'

export interface DateTimeFormatterProps {
  className?: string
  children: Date | string | null
  ampm?: boolean
  timezone?: string
  isConvert?: boolean
}

export const DateTimeFormatter = ({
  children,
  className,
  ampm,
  timezone,
  isConvert = false,
}: DateTimeFormatterProps) => {
  const dateString = useMemo(() => {
    if (!children) {
      return ''
    }
    if (isConvert) {
      return children
    }
    const momentDate = moment(children)
    if (timezone) {
      return DateUtil.convertDateTimeToVenueTimezone(
        `${children}`,
        timezone,
        ampm ? 'MMM DD, YYYY h:mm A' : 'MMM DD, YYYY HH:mm'
      )
    }
    if (ampm) {
      return momentDate.format('MMM DD, YYYY h:mm A')
    }
    return momentDate.format('MMM DD, YYYY HH:mm')
  }, [ampm, timezone, children, isConvert])

  return <time className={className}>{dateString}</time>
}
