import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {useCallback, useMemo, useState} from 'react'
import {GetCustomerByCode, GetEventByCode} from '../../../../redux/CustomerPortalCRUD'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {BookingModel} from '../../../../../../models/ems/BookingModel'
import {BookingFormValues} from '../../../../../../models/booking-wizard/BookingWizard'
import { EventModel } from '../../../../../../models/ems/EventModel'
import { useBookingState } from '../../../../pages/booking/BookingStateContext'

export const useBookingWiard = () => {
  const [customer, setCustomer] = useState<CustomerModel>()
  const [eventDetail, setEventDetail] = useState<EventModel>()
  const {setEventData} = useBookingState()
  const {pushError} = useAlerts()
  const getCustomer = useCallback(
    async (customerCode) => {
      if (customerCode) {
        try {
          const data = await GetCustomerByCode(customerCode)
          setCustomer(data.data)
        } catch (err: any) {
          pushError(err)
        }
      }
    },
    [pushError]
  )

  const getEvent = useCallback(
    async (eventCode) => {
      if (eventCode) {
        try {
          const data = await GetEventByCode(eventCode)
          setEventDetail(data.data)
          setEventData(data.data)
        } catch (err: any) {
          pushError(err)
        }
      }
    },
    [pushError]
  )

  return useMemo(
    () => ({
      getCustomer,
      customer,
      getEvent,
      eventDetail,
    }),
    [customer, getCustomer]
  )
}

export const mapBookingDataToFormValues = (booking: BookingModel): BookingFormValues => {
  if (!booking.customer) {
    throw new Error('Booking has no customer data.')
  }
  if (!booking.event) {
    throw new Error('Booking has no event data.')
  }
  if (!booking.products) {
    throw new Error('Booking has no product data.')
  }
  const formValues: BookingFormValues = {
    customer: booking.customer,
    eventCode: booking.event.code,
    products: [],
    vouchers: [],
    customersSeats: [],
  }
  if (booking.products) {
    booking.products.forEach((product) => {
      formValues.products.push({
        count: product.qty,
        data: product,
        id: product.code,
      })
    })
  }
  if (booking.vouchers) {
    booking.vouchers.forEach((voucher) => {
      formValues.vouchers.push({
        count: voucher.qty,
        data: voucher,
        id: voucher.code,
      })
    })
  }

  return formValues
}
