import clsx from 'clsx'
import {useMemo, useState} from 'react'
// import {HeaderUserMenu} from '../../../../_metronic/partials'
import {AvatarInitials} from '../../../components/utils/AvatarInitials'
import {useCustomerAuth} from '../hooks/useCustomerAuth'
import * as CustomerPortalRedux from '../redux/CustomerPortalRedux'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'

export const AuthPortalBookingHeaderThumbnail = () => {
const auth = useCustomerAuth()
  const user = useMemo(() => auth?.getUser(), [auth])

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible)

  const handleSignOut = () => {
    history.push('/auth/retail')
    dispatch(CustomerPortalRedux.actions.auth.logout())
    document.location.reload()
  }

  if (!user) {
    return null
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0 h-100'>
      <div
        className={clsx('d-flex align-items-center ms-1 ms-lg-3')}
        id='kt_header_user_menu_toggle'
        onClick={toggleDropdown}
      >
        <div
          className={clsx('cursor-pointer symbol bg-opacity-10 symbol-30px symbol-md-40px')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <AvatarInitials className='fs-2'>{user.avatarCode}</AvatarInitials>
        </div>
        {/* <HeaderUserMenu email={user.email} name={user.name} isAdmin={user.isAdmin} /> */}
        {isDropdownVisible && (
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              backgroundColor: 'black',
              color:'white',
              border: '1px solid #000',
              borderRadius: '4px',
            }}
          >
            <ul style={{listStyle: 'none', margin: 0, padding: '5px'}}>
              <li style={{padding: '10px', cursor: 'pointer'}} onClick={handleSignOut}>
                Sign Out
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}
