import {Route, Switch} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {
  OneBillionBackground,
  OneBillionMenuHeaderLogo,
  PoweredBySolaasLab,
} from '../../../../config/logos'
import {Registration} from '../components/registration/Registration'

export const RegistrationPage = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${OneBillionBackground.src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%',
      }}
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'
    >
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Helmet>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
        <img
          alt={OneBillionMenuHeaderLogo.alt}
          src={OneBillionMenuHeaderLogo.src}
          className='img-fluid w-150px w-100 mb-8'
        />
        <div className='w-sm-500px w-300px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/register'>
              <Registration />
            </Route>
          </Switch>
        </div>
        <img
          alt={PoweredBySolaasLab.alt}
          src={PoweredBySolaasLab.src}
          className='h-70px mt-12'
        />
      </div>
    </div>
  )
}
