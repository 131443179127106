import {useMemo, useState, useCallback, useEffect} from 'react'
import clsx from 'clsx'
import {TreeNode} from './TreeNode'
import {TreeSelectNode} from './TreeSelectNode'
import {TreeSelectNodeCollection} from './TreeSelectNodeCollection'

export interface TreeNodeItem {
  label: string
  value: string
  items?: TreeNodeItem[]
}

export interface TreeNodeProps {
  className?: string
  items: TreeNodeItem[]
  values: string[]
  onChange: (newValues: string[]) => void
  radioName?: string
  label?: string
  isRequired?: boolean
  hasNone?: boolean
  onBlur?: () => void
  onScrollBottom?: () => void
  noDataPlaceholder?: string
}

export const TreeSelect = ({
  label,
  items,
  values,
  onChange,
  radioName,
  hasNone,
  className,
  onBlur,
  onScrollBottom,
  noDataPlaceholder,
  isRequired,
}: TreeNodeProps) => {
  const [itemTree, setItemTree] = useState<TreeSelectNodeCollection | null>(null)
  const [valueTree, setValueTree] = useState<TreeSelectNodeCollection | null>(null)

  const handleChange = useCallback(
    (tree: TreeSelectNodeCollection, node: TreeSelectNode) => {
      const values = tree.getApiValues()
      if (radioName) {
        onChange([node.id])
      } else {
        onChange(values)
      }
    },
    [onChange, radioName]
  )

  useEffect(() => {
    if (itemTree) {
      if (radioName) {
        const tree = itemTree.clone()
        tree.setValues(values, true)
        setValueTree(tree)
      } else {
        const tree = itemTree.clone()
        tree.setValuesRecursively(values, true)
        setValueTree(tree)
      }
    }
  }, [itemTree, values, radioName])

  useEffect(() => {
    const itemsCopy = [...items]
    if (radioName && hasNone) {
      itemsCopy.unshift({value: '', label: 'None'})
    }
    const tree = new TreeSelectNodeCollection()
    tree.addItemsRecursively(itemsCopy)
    setItemTree(tree)
  }, [items, hasNone, radioName])

  const rootNodes = useMemo(() => {
    if (valueTree) {
      return valueTree.nodes.map((node) => {
        return (
          <TreeNode
            level={0}
            tree={valueTree}
            onChange={handleChange}
            key={node.id}
            node={node}
            radioName={radioName}
            onBlur={onBlur}
          />
        )
      })
    }
    return null
  }, [valueTree, handleChange, radioName, onBlur])

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const {scrollTop, clientHeight, scrollHeight} = e.currentTarget
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        onScrollBottom && onScrollBottom()
      }
    },
    [onScrollBottom]
  )

  return (
    <div className={clsx('mb-5 text-start', className)}>
      {label && (
        <label className={isRequired ? 'form-label required' : 'form-label'}>{label}</label>
      )}
      <div
        className='form-control form-control-solid'
        style={{
          overflowX: 'auto',
          minHeight: '5rem',
          maxHeight: '20rem',
        }}
        onScroll={handleScroll}
      >
        {items && items.length ? rootNodes : noDataPlaceholder ? noDataPlaceholder : ''}
      </div>
    </div>
  )
}
