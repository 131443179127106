import {EnhancedStore} from '@reduxjs/toolkit'
import {AxiosRequestConfig, AxiosStatic} from 'axios'
import {API_URL, getUrlData, LAYOUT_TYPE} from '../../config/env'
import {RootState} from '../redux/RootReducer'

export default function setupAxios(axios: AxiosStatic, store: EnhancedStore<RootState>) {
  axios.interceptors.request.use(
    (config) => {
      const _exception = config.url?.split(':')[0]
      const isToken = _exception === 'https' ? false : true
      if (isToken) {
        setBaseUrl(config)
        setAuthorizationHeader(config, store)
        setClientHeader(config)
      }

      return config
    },
    (err) => Promise.reject(err)
  )
}

const setBaseUrl = (config: AxiosRequestConfig) => {
  config.baseURL = API_URL
}

const setAuthorizationHeader = (config: AxiosRequestConfig, store: EnhancedStore<RootState>) => {
  const {auth, outlet, customerPortal, poken, adminPortal} = store.getState()

  switch (LAYOUT_TYPE) {
    case 'admin': {
      if (adminPortal.auth?.token) {
        config.headers.Authorization = `Bearer ${adminPortal.auth.token}`
      }
      break
    }
    case 'outlet': {
      if (outlet.auth?.token) {
        config.headers.Authorization = `Bearer ${outlet.auth.token}`
      }
      break
    }

    case 'customer-delegate':
    case 'customer-booking':
    case 'customer-portal': {
      if (customerPortal.auth?.token) {
        config.headers.Authorization = `Bearer ${customerPortal.auth.token}`
      }
      break
    }

    case 'poken': {
      if (poken.auth?.token) {
        config.headers.Authorization = `Bearer ${poken.auth.token}`
      }
      break
    }
    default: {
      if (auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }
    }
  }
}

const setClientHeader = (config: AxiosRequestConfig) => {
  const {organizationCode, outletCode, deviceId} = getUrlData()
  if (organizationCode) {
    config.headers['x-organization'] = organizationCode
  }
  if (outletCode) {
    config.headers['x-outlet'] = outletCode
  }
  if (deviceId) {
    config.headers['x-device-id'] = deviceId
  }
}
