import {lazy, useMemo} from 'react'
import {useAdminAuth} from '../../modules/admin/hooks/useAdminAuth'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ForgotPassword} from '../../modules/admin/components/forms/ForgotPassword'
import { DashboardWrapper } from '../../modules/admin/pages/dashboard/DashboardWrapper'

export const PrivateRoutes = () => {
  const auth = useAdminAuth()
  const OrganizationPage = lazy(() => import('../../modules/admin/pages/organization/OrganizationPage'))
  const SystemPage = lazy(() => import('../../modules/admin/pages/system/SystemPage')) 

  const redirectRoutes = useMemo(() => {
    let link = '/error/404'
    return (
      <>
        <Redirect from='/auth' to={link} />
        <Redirect exact from='/' to={link} />
      </>
    )
  }, [])

  if (!auth) {
    return <Redirect to='/auth/login' />
  } else if (auth.isFirstLogin()) {
    return <Redirect to='/auth/password-reset' />
  }

  return (
    <Switch>
      <Route path='/organization' component={OrganizationPage} />
      <Route path='/system' component={SystemPage} />
      <Route path='/dashboard' component={DashboardWrapper} />
      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Redirect to='/dashboard' />
      {redirectRoutes}
    </Switch>
  )
}
