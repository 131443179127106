import {useMemo} from 'react'
import {DetailHeaderContent} from '../../../../components/DetailHeader/DetailHeaderContent'
import {CustomerModel} from '../../../../models/CustomerModel'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {Button} from '../../../../components/inputs/Button'

export interface CustomerReservationDetailHeaderProps {
  customer?: CustomerModel | null
}

export const CustomerCreateBookingHeader = ({customer}: CustomerReservationDetailHeaderProps) => {
  const {staticUrls} = useAppConfig()

  const avatar = useMemo(() => {
    if (customer?.photo) {
      const imageValue = new ImageInputValue(staticUrls.public, customer.photo)
      return imageValue.url
    }
  }, [customer?.photo, staticUrls.public])

  return (
    <DetailHeaderContent
      left={
        <div className='d-flex flex-row my-10 px-11'>
          {avatar && (
            <div className='d-flex drag-drop-file-input-drop-area-thumbnail'>
              <img src={avatar} alt='' className=' h-100' />
            </div>
          )}
          <div className='d-flex flex-column px-2'>
            <div className='d-flex align-items-center'>
              <p className='text-gray-800 fs-2 fw-bolder mb-1'>{customer?.name}</p>
            </div>
            <div className='d-flex flex-wrap'>
              <div className='d-flex flex-column me-3 mt-3'>
                {customer?.mobile && customer.mobile.length > 0 && (
                  <div className='d-flex flex-wrap'>
                    <span className='d-flex align-items-center'>
                      <MetronicIcon iconType='Devices' iconName='Phone' size='svg-icon-4' />
                      {customer.mobile}
                    </span>
                  </div>
                )}
                {customer?.email && customer.email.length > 0 && (
                  <div className='d-flex flex-wrap'>
                    <span className='d-flex align-items-center text-muted'>
                      <MetronicIcon iconType='Communication' iconName='Mail' size='svg-icon-4' />
                      {customer.email}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}
