import {FC, Suspense, useCallback, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {RootState} from '../setup'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {AlertContainer} from './components/alerts/AlertContainer'
import {WebSocketProvider} from './components/utils/WebSocketProvider'
import AuthInit from './modules/default/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {SwalUtils} from './utils/SwalUtils'
import {LAYOUT_TYPE} from '../config/env'

type Props = {
  basename: string
}

const App: FC<Props> = ({basename}) => {
  // CHECK WORKER STATUS
  const isServiceWorkerUpdated: Boolean = useSelector<RootState>(
    ({worker}) => worker.serviceWorkerUpdated,
    shallowEqual
  ) as Boolean

  // WORKER REGISTRATION
  const serviceWorkerRegistration: any = useSelector<RootState>(
    ({worker}) => worker.serviceWorkerRegistration,
    shallowEqual
  ) as any

  //SET indexhtml attributes for Admin Layout
  const layoutType = LAYOUT_TYPE

  const setTitleAndFavicon = useCallback((layoutType?: string) => {
    const title = 'SolaasLab | SolaaS Group an Ecosystem'
    const favicon = '/adminfavicon.ico'
    const splashImage = '/media/saas/solaaslablogo.svg'

    document.title = title

    let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = favicon
    const splashImageElement = document.querySelector('#splash-screen img') as HTMLImageElement
    if (splashImageElement) {
      splashImageElement.src = splashImage
    }
  }, [])

  useEffect(() => {
    if (SolaasLabLayouts.includes(layoutType)) setTitleAndFavicon(layoutType)
  }, [layoutType, setTitleAndFavicon])

  useEffect(() => {
    async function swUpdate() {
      if (isServiceWorkerUpdated) {
        const response = await SwalUtils.applicationUpdate()
        if (response.isConfirmed) {
          if (serviceWorkerRegistration !== null) {
            const registrationWaiting = serviceWorkerRegistration.waiting
            if (registrationWaiting) {
              registrationWaiting.postMessage({type: 'SKIP_WAITING'})
              registrationWaiting.addEventListener('statechange', (e: any) => {
                if (e.target.state === 'activated') {
                  window.location.reload(false)
                }
              })
            }
          }
        }
      }
    }
    swUpdate()
  }, [isServiceWorkerUpdated, serviceWorkerRegistration])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <WebSocketProvider>
                <Routes />
                <AlertContainer />
              </WebSocketProvider>
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}

const SolaasLabLayouts = [
  'admin',
  'customer-registration'
]