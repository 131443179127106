import axios from 'axios'
import {FilterModel} from '../../../../models/FilterModel'
import type {RoleModel, RoleModelCreateParams} from '../../../../models/RoleModel'
import {UserModel, UserModelCreateParams} from '../../../../models/UserModel'
import {ConfigGroupCreateParams, ConfigGroupModel} from '../../../../models/system/ConfigGroupModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ConfigTypeModel} from '../../../../models/system/ConfigTypeModel'
import {
  AssignCustomFieldParams,
  CustomFieldCreateParams,
  CustomFieldModel,
  CustomFieldTableModel,
  CustomFieldTypeModel,
} from '../../../../models/system/CustomFieldModel'
import {EventModel, EventScannerModel} from '../../../../models/ems/EventModel'
import {FileModel} from '../../../../models/FileModel'
import {
  LinkEventProductToSalesChannelCreateParams,
  LinkEventToSalesChannelCreateParams,
  LinkRetailProductToSalesChannelCreateParams,
  SalesChannelAccountCreateParams,
  SalesChannelAccountModel,
  SalesChannelCreateParams,
  SalesChannelModel,
  SalesChannelType,
} from '../../../../models/system/SalesChannelModel'
import {ProductModel} from '../../../../models/retail/ProductModel'

const CRUD_ROLE = '/role'
const CRUD_USER = '/user'
const SYSTEM_ALL_ROLES = '/role/all'
const SYSTEM_ORGANIZATION = '/system/organization'
const SYSTEM_ORGANIZATION_LOG = '/system/log'
const SYSTEM_ROLES = '/role'
const SYSTEM_SEARCH_ROLES = '/role/list'
const SYSTEM_SEARCH_USERS = '/system/user/list'
const SYSTEM_SALES_CHANNEL_ACCOUNT = '/sales-channel-account'
const SYSTEM_SALES_CHANNEL = '/sales-channel'
const SYSTEM_SALES_CHANNEL_TYPES = '/sales-channel-type/list'
const SALES_CHANNEL_EVENT = '/sales-channel/event'
const EMS_SEARCH_EVENT = '/event/list'
const SALES_CHANNEL_EVENT_PRODUCT = '/sales-channel/product'
const EMS_SEARCH_PRODUCT = '/product/list'
const RETAIL_SEARCH_PRODUCT = '/retail-product/list'
const SALES_CHANNEL_RETAIL_PRODUCT = '/sales-channel/retail-product'

export const DeleteOrganizationUser = (codes: string[]) => axios.delete(CRUD_USER, {data: {codes}})
export const DeleteOrganizationRole = (codes: string[]) =>
  axios.delete(SYSTEM_ROLES, {data: {codes}})
export const GetOrganizationAllRoles = () => axios.get<RoleModel[]>(SYSTEM_ALL_ROLES)
export const GetOrganizationLogs = (filter?: FilterModel) =>
  axios.post(SYSTEM_ORGANIZATION_LOG, filter)
export const GetOrganizationProfile = () => axios.get(SYSTEM_ORGANIZATION)
export const GetOrganizationRole = (filter?: FilterModel) => axios.post(SYSTEM_SEARCH_ROLES, filter)
export const GetOrganizationRoleByCode = (code: string) => axios.get(`${SYSTEM_ROLES}/${code}`)
export const GetOrganizationUsers = (filter?: FilterModel) =>
  axios.post(SYSTEM_SEARCH_USERS, filter)

export const PostRole = (data: RoleModelCreateParams) => axios.post(CRUD_ROLE, data)
export const PutRole = (data: RoleModelCreateParams, code: string) =>
  axios.put(`${CRUD_ROLE}/${code}`, data)

export const PostUser = (data: any) => axios.post(CRUD_USER, data)
export const GetUserByCode = (userCode: string) => axios.get<UserModel>(`${CRUD_USER}/${userCode}`)
export const PutUser = (data: UserModelCreateParams, code: string) =>
  axios.put(`${CRUD_USER}/${code}`, data)
export const ResetUserMFA = (userCodes: string[]) =>
  axios.post(`/user/reset-mfa`, {
    codes: userCodes,
  })
export const SetUserSmartPin = (smartPin: string, codes: string[]) =>
  axios.post(`/user/smart-pin`, {
    smartPin,
    codes,
  })

export const GetConfigGroupByCode = (code: string) =>
  axios.get<ConfigGroupModel>(`/config-group/${code}`)
export const SearchConfigGroups = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<ConfigGroupModel>>(`/config-group/list`, filters)
export const CreateConfigGroup = (params: ConfigGroupCreateParams) =>
  axios.post<ConfigGroupModel>(`/config-group`, params)
export const UploadConfigFile = (fontFile: Blob | File) => {
  const formData = new FormData()
  formData.append('configFiles', fontFile)
  return axios.post<FileModel[]>(`/config-file/upload`, formData)
}
export const GetConfigFileByCode = (fileCode: string) => {
  return axios.get<FileModel>(`/config-file/${fileCode}`)
}
export const RemoveConfigFile = (codes: string[]) =>
  axios.delete(`/config-file`, {
    data: {
      codes,
    },
  })
export const UpdateConfigGroup = (code: string, params: ConfigGroupCreateParams) =>
  axios.put<ConfigGroupModel>(`/config-group/${code}`, params)
export const DeleteConfigGroup = (codes: string[]) =>
  axios.delete(`/config-group`, {
    data: {
      codes,
    },
  })
export const ActivateConfigGroup = (code: string) => axios.post(`/config-group/activate/${code}`)

export const GetAllConfigTypes = () => axios.get<ConfigTypeModel[]>(`/type`)
export const GetConfigTypeByCode = (code: string) => axios.get<ConfigTypeModel>(`/type/${code}`)

export const GetCustomFieldByCode = (table: string, code: string) =>
  axios.get<CustomFieldModel>(`/${table}/${code}`)
export const SearchCustomFields = async (table: string, filters?: FilterModel) => {
  const response = await axios.post<GlobalSearchModel<CustomFieldModel>>(`/${table}/list`, filters)
  return response
}
export const CreateCustomField = (table: string, params: CustomFieldCreateParams) =>
  axios.post<CustomFieldModel>(`/${table}`, params)
export const UpdateCustomField = (table: string, code: string, params: CustomFieldCreateParams) =>
  axios.put<CustomFieldModel>(`/${table}/${code}`, params)
export const DeleteCustomField = (table: string, codes: string[]) =>
  axios.delete(`/${table}`, {
    data: {
      codes,
    },
  })

export const AssignCustomFields = (table: string, params: AssignCustomFieldParams) => {
  axios.post<AssignCustomFieldParams>(`/${table}/global-to-event`, params)
}

export const GetCustomFieldTables = (eventCode: string) =>
  axios.post<CustomFieldTableModel[]>(`/extras-custom-field/tables`, {eventCode})

export const GetCustomFieldTypes = () =>
  axios.get<CustomFieldTypeModel[]>(`/extras-custom-field/types`)

export const GetTableCustomFields = (dbRoute: string, tableCode: string) =>
  axios.get<CustomFieldModel[]>(`/${dbRoute}/all/${tableCode}`)

export const GetTableCustomFieldsByEvent = (
  dbRoute: string,
  tableCode: string,
  eventCode: string
) => axios.post<CustomFieldModel[]>(`/${dbRoute}/all/${tableCode}`, {eventCode: eventCode})

export const getPosScannerDetails = () => axios.get<EventScannerModel>(`/outlet/pos-config`)

export const SearchSalesChannelAccounts = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<SalesChannelAccountModel>>(
    `${SYSTEM_SALES_CHANNEL_ACCOUNT}/list`,
    filters
  )
export const DeleteSalesChannelAccounts = (codes: string[]) =>
  axios.delete(`${SYSTEM_SALES_CHANNEL_ACCOUNT}`, {
    data: {
      codes,
    },
  })
export const GetSalesChannelAccountByCode = (code: string) =>
  axios.get<SalesChannelAccountModel>(`${SYSTEM_SALES_CHANNEL_ACCOUNT}/${code}`)
export const CreateSalesChannelAccount = (data: SalesChannelAccountCreateParams) =>
  axios.post(SYSTEM_SALES_CHANNEL_ACCOUNT, data)
export const UpdateSalesChannelAccount = (code: string, params: SalesChannelAccountCreateParams) =>
  axios.put<ConfigGroupModel>(`${SYSTEM_SALES_CHANNEL_ACCOUNT}/${code}`, params)

export const SearchSalesChannels = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<SalesChannelModel>>(`${SYSTEM_SALES_CHANNEL}/list`, filters)

export const DeleteSalesChannels = (codes: string[]) =>
  axios.delete(`${SYSTEM_SALES_CHANNEL}`, {
    data: {
      codes,
    },
  })
export const GetSalesChannelByCode = (code: string) =>
  axios.get<SalesChannelModel>(`${SYSTEM_SALES_CHANNEL}/${code}`)
export const GetSalesChannelTypes = () =>
  axios.post<GlobalSearchModel<SalesChannelType>>(`${SYSTEM_SALES_CHANNEL_TYPES}`)
export const CreateSalesChannel = (data: SalesChannelCreateParams) =>
  axios.post(SYSTEM_SALES_CHANNEL, data)
export const UpdateSalesChannel = (code: string, data: SalesChannelCreateParams) =>
  axios.put(`${SYSTEM_SALES_CHANNEL}/${code}`, data)
export const LinkedEventToSalesChannel = (data: LinkEventToSalesChannelCreateParams) =>
  axios.post(SALES_CHANNEL_EVENT, data)
export const UnlinkedEventToSalesChannel = (data: LinkEventToSalesChannelCreateParams) =>
  axios.delete(SALES_CHANNEL_EVENT, {data})
export const GetEvents = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(EMS_SEARCH_EVENT, filter)
export const GetProductsByEvent = (eventCode: string, filter?: FilterModel) =>
  axios.post<ProductModel[]>(`/event/${eventCode}/product`, filter)
export const LinkedEventProductToSalesChannel = (
  channelCode: string,
  data: LinkEventProductToSalesChannelCreateParams
) => axios.post(`${SALES_CHANNEL_EVENT_PRODUCT}/${channelCode}`, data)
export const UnlinkedEventProductFromSalesChannel = (
  channelCode: string,
  data: LinkEventProductToSalesChannelCreateParams
) => axios.delete(`${SALES_CHANNEL_EVENT_PRODUCT}/${channelCode}`, {data})
export const GetProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(EMS_SEARCH_PRODUCT, filter)
export const GetRetailProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(RETAIL_SEARCH_PRODUCT, filter)
export const LinkedRetailProductToSalesChannel = (
  channelCode: string,
  data: LinkRetailProductToSalesChannelCreateParams
) => axios.post(`${SALES_CHANNEL_RETAIL_PRODUCT}/${channelCode}`, data)
export const UnlinkedRetailProductFromSalesChannel = (
  channelCode: string,
  data: LinkRetailProductToSalesChannelCreateParams
) => axios.delete(`${SALES_CHANNEL_RETAIL_PRODUCT}/${channelCode}`, {data})
