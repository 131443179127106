import {SelectInputItem} from '../components/inputs/SelectInput'

export interface HasCode {
  code: string
}

export interface NamedApiItem extends HasCode {
  name: string
  title?: string
}

export const idExtractor = (data: HasCode) => data.code

export const selectItemMapper = (data: NamedApiItem): SelectInputItem => ({
  label: data.name ?? data.title,
  value: data.code,
})

export interface FirstNamedApiItem extends HasCode {
  firstName: string
}

export const selectItemMapperWithFN = (data: FirstNamedApiItem): SelectInputItem => ({
  label: data.firstName,
  value: data.code,
})
