import { ReactNode, useMemo } from "react"
import { NavigationSectionMenuItem } from "../../../config/drawer"
import { Redirect, Route, Switch } from "react-router-dom"
import { Logout } from "../common/Logout"
import { FullPage404 } from "../common/FullPage404"
import { FullPage403 } from "../common/FullPage403"
import { useAdminAuth } from "../../modules/admin/hooks/useAdminAuth"
import { HeaderControls } from "../../components/layouts/DefaultLayout/Header/HeaderControls"
import { AuthAdminHeaderThumbnail } from "../../modules/admin/components/headers/AuthAdminHeaderThumbnail"
import { PrivateRoutes } from "./PrivateRoutes"
import { AuthPage } from "../../modules/admin/pages/AuthPage"
import { AdminLayout } from "../../components/layouts/AdminLayout/AdminLayout"

const MENU: NavigationSectionMenuItem[] = [
  {
    title: 'Dashboard',
    to: '/dashboard',
  },
  {
    title: 'Organization',
    to: '/organization',
  },
  {
    title: 'System',
    direction: 'bottom',
    subMenus: [
      {
        to: '/system/user',
        title: 'Users',
      },
      {
        to: '/system/log',
        title: 'Logs',
      },
      {
        to: '/system/package',
        title: 'Packages',
      },
    ]
  },
]

export const Routes = () => {
  const auth = useAdminAuth()

  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to='/auth/login' />
    } else if (auth.isFirstLogin()) {
      return <Redirect to='/auth/password-reset' />
    }
    return (
      <AdminLayout menus={MENU}>
        <HeaderControls>
          <AuthAdminHeaderThumbnail />
        </HeaderControls>
        <PrivateRoutes />
      </AdminLayout>
    )
  }, [auth])

  const unauthenticatedRoutes = useMemo(() => {
    let redirectFirstLogin: ReactNode
    if (auth && auth.isFirstLogin()) {
      redirectFirstLogin = <Redirect to='/auth/password-reset' />
    }

    if (!auth || auth?.isFirstLogin()) {
      return (
        <Route>
          {redirectFirstLogin}
          <AuthPage />
        </Route>
      )
    }
    return <Redirect from='/auth' to='/' />
  }, [auth])

  return (
    <Switch>
      <Route path='/logout' component={Logout} />
      <Route path='/auth/login/:hash' component={AuthPage} />
      <Route path='/auth/login' component={AuthPage} />
      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      <Route path='/error/403'>
        <FullPage403 link='https://solaax.com' />
      </Route>
      {unauthenticatedRoutes}
      {authenticatedRoutes}
    </Switch>
  )
}
