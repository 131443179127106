import {ChangeEvent, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'
import {FilterSearchableSelectInput} from '../SearchableSelect/FilterSearchableSelectInput'
import {SelectInputItem} from '../SelectInput'
import {MetronicIconButton} from '../MetronicIconButton'
import {NonSeatedProductTimeSlot} from '../../../models/ems/BookingModel'
import {ProductModel} from '../../../models/ems/ProductModel'
import {Badge} from '../../badge/Badge'
import {ColorVariant} from '../Button'

export interface ListCountInputItemValue<T> {
  data: T | null
  count: number
  minCount?: number
  id: number | string
  isNew?: boolean
  type?: 'product' | 'voucher' | 'bundle'
  startDate?: string
  endDate?: string
  timeslots?: NonSeatedProductTimeSlot[]
  bundleProducts?: ProductModel[]
  bundleCode?: string
  bookingUID?: string
}

export interface ListCountInputItemProps<T> {
  className?: string
  value: ListCountInputItemValue<T>
  onChange: (value: ListCountInputItemValue<T>) => void
  onSearch: (filter: FilterModel) => void
  searchResult?: GlobalSearchModel<T>
  selectedItems: ListCountInputItemValue<T>[]
  placeholder: string
  label: string
  onRemove: (value: ListCountInputItemValue<T>) => void
  itemMapper: (data: T) => SelectInputItem
  disabled?: boolean
  isMinCount?: boolean
  isPortal?: boolean
}

export const ListCountInputItem = <T,>({
  searchResult,
  onChange,
  onSearch,
  className,
  value,
  placeholder,
  label,
  onRemove,
  selectedItems,
  itemMapper,
  disabled,
  isMinCount,
  isPortal = false,
}: ListCountInputItemProps<T>) => {
  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      const newValue: ListCountInputItemValue<T> = {...value, count: Number(count)}
      onChange(newValue)
    },
    [onChange, value]
  )

  const handleSelectionChange = useCallback(
    (item: T | null) => {
      if (item) {
        onChange({...value, data: item})
      }
    },
    [value, onChange]
  )

  const handleRemove = useCallback(() => {
    onRemove(value)
  }, [value, onRemove])

  const filteredSearchResult = useMemo(() => {
    if (searchResult) {
      return {
        ...searchResult,
        data: searchResult.data.filter(
          (item) =>
            !selectedItems.some(
              (selectedItem) =>
                selectedItem.data && itemMapper(item).value === itemMapper(selectedItem.data).value
            )
        ),
      }
    }
  }, [itemMapper, searchResult, selectedItems])

  const renderItems = useCallback(
    (item: T | null) => {
      const label = item ? itemMapper(item).label : 'No Name'
      const value = item ? itemMapper(item).value : 'No Name'
      let type = null
      let badge: ColorVariant = 'warning'
      switch (value.substring(0, 3)) {
        case 'PRD':
          type = 'product'
          badge = 'info'
          break
        case 'VCH':
          type = 'voucher'
          badge = 'primary'
          break
        default:
          type = 'bundle'
      }
      return (
        <div
          className='align-items-center mb-1'
          role='button'
          onMouseDown={() => handleSelectionChange(item)}
        >
          <div className='d-flex justify-content-between'>
            <strong>{label}</strong>
            {type !== 'voucher' ? (
              <Badge uppercase variant={`${badge}`}>
                {type}
              </Badge>
            ) : null}
          </div>
          {type !== 'voucher' ? (
            <div className='w-100 searchable-input-item-botom-border my-2' />
          ) : null}
        </div>
      )
    },
    [handleSelectionChange, itemMapper]
  )

  return (
    <div className={clsx('product-input-item d-flex flex-column gap-5', className)}>
      <label className='form-label'>{label}</label>
      <div className='product-input-item-input-container'>
        <div className='flex-grow-1  d-flex flex-column gap-5'>
          <div className='w-100' style={{minWidth: 0}}>
            <FilterSearchableSelectInput
              disabled={disabled}
              value={value.data}
              itemMapper={itemMapper}
              searchResult={filteredSearchResult}
              placeholder={placeholder}
              onChange={handleSelectionChange}
              onFilter={onSearch}
              noMargin
              renderItem={isPortal ? undefined : renderItems}
            />
          </div>
        </div>
        <MetronicIconButton
          type='button'
          className='btn btn-icon btn-active-color-danger'
          iconType='General'
          iconName='Trash'
          variant='text'
          size='sm'
          onClick={handleRemove}
          tooltip='Delete'
        />
      </div>
      <div className='d-flex gap-5'>
        <div>
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            disabled={disabled || !value.data}
            value={value.count}
            min={0}
          />
        </div>
        {isMinCount && (
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            disabled={disabled || !value.data || value.count === value.minCount}
            value={value.minCount}
            min={0}
          />
        )}
      </div>
    </div>
  )
}
