import axios from 'axios'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {
  ProductCategoryModel,
  ProductCategoryModelCreateParams,
} from '../../../../models/retail/ProductCategoryModel'
import {VenueModel} from '../../../../models/acs/VenueModel'
import {ProductModel} from '../../../../models/retail/ProductModel'

import {SalesChannelType} from '../../../../models/system/SalesChannelModel'
import {TimeZoneModel} from '../../../../models/retail/TimezoneModel'
import {
  ProductDateRangeModel,
  ProductDateRangeUnavailableDaysParams,
  ProoductDateRangeCreateParams,
} from '../../../../models/retail/ProductDateRangeModel'
import {
  ProductTimeslotCreateParams,
  ProductTimeslotModel,
} from '../../../../models/retail/ProductTimeslotsModel'

const SEARCH_PRODUCT_CATEGORY = '/retail-product-category/list'
const SEARCH_PRODUCT_CATEGORY_TREE = '/retail-product-category/tree'
const RETAIL_SEARCH_PRODUCT = '/retail-product/list'
const ACS_SEARCH_VENUE = '/venue/list'
const CRUD_PRODUCT_CATEGORY = '/retail-product-category'
const CRUD_PRODUCT = '/retail-product'
const SYSTEM_SALES_CHANNEL_TYPES = '/sales-channel-type/list'
const ACS_PARENT_LOCATION_VENUE = '/location/venue'
const SEARCH_TIMEZONES = '/timezone/list'
const RETAIL_SEARCH_PRODUCT_DATE_RANGE = '/retail-product-date-range/list'
const CRUD_PRODUCT_DATERANGE = '/retail-product-date-range'
const RETAIL_SEARCH_PRODUCT_TIMESLOTS = '/retail-product-timeslot/list'
const CRUD_PRODUCT_TIMESLOT = '/retail-product-timeslot'
const RETAIL_SEARCH_BOOKING = '/retail-booking/list'

export const GetRetailProductCategories = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductCategoryModel>>(SEARCH_PRODUCT_CATEGORY, filter)
export const GetVenues = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<VenueModel>>(ACS_SEARCH_VENUE, filter)
export const GetProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(RETAIL_SEARCH_PRODUCT, filter)
export const DeleteProducts = (codes: string[]) => axios.delete(CRUD_PRODUCT, {data: {codes}})
export const PostProductCategory = (data: ProductCategoryModelCreateParams) =>
  axios.post(`${CRUD_PRODUCT_CATEGORY}`, data)
export const DeleteProductCategories = (codes: string[]) =>
  axios.delete(CRUD_PRODUCT_CATEGORY, {data: {codes}})
export const GetProductCategoryByCode = (code: string, lang?: string) =>
  axios.get<ProductCategoryModel>(`${CRUD_PRODUCT_CATEGORY}/${code} ${lang ? `/${lang}` : ''}`)
export const PutProductCategory = (data: ProductCategoryModelCreateParams, code: string) =>
  axios.put(`${CRUD_PRODUCT_CATEGORY}/${code}`, data)
export const GetSalesChannelTypes = () =>
  axios.post<GlobalSearchModel<SalesChannelType>>(`${SYSTEM_SALES_CHANNEL_TYPES}`)
export const GetParentLocationByVenue = (filter?: FilterModel) =>
  axios.post<VenueModel[]>(ACS_PARENT_LOCATION_VENUE, filter)
export const PostRetailProduct = (data: FormData) => axios.post<ProductModel>(CRUD_PRODUCT, data)
export const GetTimezones = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TimeZoneModel>>(SEARCH_TIMEZONES, filter)
export const GetProductByCode = (code: string, lang?: string) =>
  axios.get(`${CRUD_PRODUCT}/${code}${lang ? `/${lang}` : ''}`)
export const UpdateRetailProduct = (data: FormData, code: string) =>
  axios.put<ProductModel>(`${CRUD_PRODUCT}/${code}`, data)
export const GetRetailProductCategoriesByVenue = (filter?: FilterModel) =>
  axios.post<ProductCategoryModel[]>(SEARCH_PRODUCT_CATEGORY_TREE, filter)
export const GetRetailProductsByCategory = (code: String) =>
  axios.get<ProductModel[]>(`${CRUD_PRODUCT_CATEGORY}/${code}/retail-product`)
export const GetProductDateRange = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductDateRangeModel>>(RETAIL_SEARCH_PRODUCT_DATE_RANGE, filter)
export const PostProductDateRange = (data: ProoductDateRangeCreateParams) =>
  axios.post(`${CRUD_PRODUCT_DATERANGE}`, data)
export const DeleteProductDateRange = (codes: string[]) =>
  axios.delete(CRUD_PRODUCT_DATERANGE, {data: {codes}})
export const PutProductDateRange = (data: ProoductDateRangeCreateParams, code: string) =>
  axios.put(`${CRUD_PRODUCT_DATERANGE}/${code}`, data)
export const GetProductDateRangeByCode = (code: string) =>
  axios.get<ProductDateRangeModel>(`${CRUD_PRODUCT_DATERANGE}/${code}`)
export const GetProductTimeslots = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductTimeslotModel>>(RETAIL_SEARCH_PRODUCT_TIMESLOTS, filter)
export const PostProductTimeslot = (data: ProductTimeslotCreateParams) =>
  axios.post(`${CRUD_PRODUCT_TIMESLOT}`, data)
export const DeleteProductTimeslot = (codes: string[]) =>
  axios.delete(CRUD_PRODUCT_TIMESLOT, {data: {codes}})
export const PutProductTimeslot = (data: ProductTimeslotCreateParams, code: string) =>
  axios.put(`${CRUD_PRODUCT_TIMESLOT}/${code}`, data)
export const GetUnavailableDays = (data: ProductDateRangeUnavailableDaysParams, code: string) =>
  axios.post(`${CRUD_PRODUCT_DATERANGE}/${code}/unavailable`, data)
export const GetRetailBookings = (filter?: FilterModel) => axios.post(RETAIL_SEARCH_BOOKING, filter)
