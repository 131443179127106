import clsx from 'clsx'
import {ChangeEvent, useCallback, useMemo} from 'react'
import {Time} from '../../utils/Time'

export interface TimePickerInputProps {
  value: Time | null
  onChange: (value: Time) => void
  label?: string
  className?: string
  minutesStep?: number
  disabled?: boolean
  isModal?: boolean
}

export const TimePickerInput = ({
  onChange,
  value,
  className,
  label,
  minutesStep = 5,
  disabled,
  isModal = false,
}: TimePickerInputProps) => {
  const handleTimeChange = useCallback(
    (type: 'h' | 'm') => (e: ChangeEvent<HTMLSelectElement>) => {
      const clockValue = Number(e.target.value)
      let newValue: Time
      if (value) {
        newValue = value.clone()
      } else {
        newValue = new Time(0, 0, 0)
      }
      switch (type) {
        case 'h':
          newValue.setHours(clockValue)
          break
        case 'm':
          newValue.setMinutes(clockValue)
          break
      }
      onChange(newValue)
    },
    [onChange, value]
  )

  const minutes = useMemo(() => {
    return generateNumbers(60, minutesStep)
  }, [minutesStep])

  const minutesOptions = useMemo(() => {
    return generateNumberOptions(minutes)
  }, [minutes])

  return (
    <div className={clsx('mb-5', className)}>
      {label && <label className='form-label'>{label}</label>}
      <div className='d-flex'>
        {isModal ? (
          <div className='text-muted' style={{width: '70px'}}>
            HH
          </div>
        ) : (
          <label className='align-self-center form-label-sm text-muted me-1 '>HH</label>
        )}

        <select
          value={value?.getHours() || 0}
          onChange={handleTimeChange('h')}
          className='form-select form-select-solid me-1'
          disabled={disabled}
        >
          {HOURS_OPTIONS}
        </select>
        {isModal ? (
          <div className='text-muted' style={{width: '77px'}}>
            MM
          </div>
        ) : (
          <label className='align-self-center form-label-sm text-muted me-1'>MM</label>
        )}

        <select
          value={getNearestNumber(value?.getMinutes() || 0, minutes)}
          onChange={handleTimeChange('m')}
          className={clsx('form-select form-select-solid')}
          disabled={disabled}
        >
          {minutesOptions}
        </select>
      </div>
    </div>
  )
}

const getNearestNumber = (value: number, numbers: number[]) => {
  const output = numbers.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  )
  return output
}

const generateNumbers = (number: number, steps: number = 1) => {
  const numbers: number[] = []
  for (let i = 0; i < number; i++) {
    if (i % steps === 0) {
      numbers.push(i)
    }
  }
  return numbers
}

const hours24: number[] = generateNumbers(24)

const generateNumberOptions = (numbers: number[]) => {
  return numbers.map((number) => {
    return (
      <option key={number} value={number.toString()}>
        {String(number).padStart(2, '0')}
      </option>
    )
  })
}

const HOURS_OPTIONS = generateNumberOptions(hours24)
