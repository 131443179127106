import {useMemo} from 'react'
import {DateFormatter} from '../../../../../../components/utils/formatter/DateFormatter'
import {DateTimeFormatter} from '../../../../../../components/utils/formatter/DateTimeFormatter'
import {DateUtil} from '../../../../../../utils/DateUtil'

export interface TimeSlotFormatterProps {
  isTimeslot?: boolean
  children: Date | string
  className?: string
  timezone?: string
}

export const TimeSlotFormatter = ({
  isTimeslot,
  children,
  className,
  timezone,
}: TimeSlotFormatterProps) => {
  const parsedDate = useMemo(() => {
    if (!children) {
      return null
    }
    if (timezone) {
      return DateUtil.convertDateTimeToVenueTimezone(
        `${children}`,
        timezone || '',
        isTimeslot ? 'MMM DD, YYYY HH:mm' : 'MMM DD, YYYY'
      )
    }
    if (children instanceof Date) {
      return children
    }

    return DateUtil.getDateFromApiString(children)
  }, [children, timezone, isTimeslot])

  if (isTimeslot) {
    return (
      <DateTimeFormatter className={className} isConvert={timezone ? true : false}>
        {parsedDate}
      </DateTimeFormatter>
    )
  }
  return (
    <DateFormatter className={className} isConvert={timezone ? true : false}>
      {parsedDate}
    </DateFormatter>
  )
}
