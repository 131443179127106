import {RowAction, RowActionProps} from './RowAction'

export interface EditRowActionProps<T>
  extends Omit<RowActionProps<T>, 'iconType' | 'iconName' | 'variant' | 'tooltip'> {}

export const PriceRowAction = <T,>(props: EditRowActionProps<T>) => {
  return (
    <RowAction iconType='Shopping' iconName='Price1' variant='warning' tooltip='Price' {...props} />
  )
}
