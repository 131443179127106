import {useCallback, useMemo} from 'react'
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import {
  LinkBreadCrumb,
  LinkBreadcrumbsToolbar,
} from '../../../../components/layouts/DefaultLayout/LinkBreadcrumbsToolbar'
import {Paper} from '../../../../components/utils/Paper'
import {ActivityModel} from '../../../../models/ems/ActivityModel'
import {EventModel} from '../../../../models/ems/EventModel'
// import {BookingCreateBulkWizard} from '../../components/wizards/BookingWizard/BookingCreateBulkWizard'
import {BookingBulkConsolidatedCreateWizard} from '../../components/wizards/BookingWizard/BookingBulkConsolidatedCreateWizard'

interface RouteProps {
  params: {
    customerCode?: string
  }
}
interface PortalEventDetailsBulkBookingCreateProps {
  event?: EventModel | ActivityModel | null
}
export const PortalEventDetailsBulkBookingCreate = ({
  event,
}: PortalEventDetailsBulkBookingCreateProps) => {
  const location = useLocation<{breadcrumbs?: LinkBreadCrumb[]; from?: string}>()
  const history = useHistory()
  const {
    params: {customerCode},
  }: RouteProps = useRouteMatch()

  const breadcrumbs = useMemo(() => {
    const breadcrumbs: LinkBreadCrumb[] = location.state?.breadcrumbs
      ? [...location.state.breadcrumbs]
      : [
          {
            label: 'Bookings',
            link: customerCode
              ? `/customer/${customerCode}/booking`
              : `/event/${event?.code}/booking`,
          },
        ]
    breadcrumbs.push({
      label: 'Create Bulk Booking',
    })
    return breadcrumbs
  }, [customerCode, event?.code, location?.state?.breadcrumbs])

  const handleSuccess = useCallback(() => {
    if (event) history.replace(`/event/${event.code}/booking`)
    if (customerCode) history.replace(`/customer/${customerCode}/booking`)
  }, [customerCode, event, history])

  return (
    <>
      <LinkBreadcrumbsToolbar title='Create Bulk Booking' breadcrumbs={breadcrumbs} />
      <div className='container'>
        <Paper rounded className='py-10'>
          {/* <BookingCreateBulkWizard onSuccess={handleSuccess} event={event} /> */}
          <BookingBulkConsolidatedCreateWizard onSuccess={handleSuccess} event={event} />
        </Paper>
      </div>
    </>
  )
}
