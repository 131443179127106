import {Redirect, Route, Switch} from 'react-router-dom'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import {BookingWizard} from '../../modules/customer-booking/components/booking-wizard/BookingWizard'
import {RetailProductsPage} from '../../modules/customer-booking/pages/RetailProductsPage'
import clsx from 'clsx'
import {Helmet} from 'react-helmet'
import {Link} from '../../components/utils/Link'
import {MainDrawerMenuHeaderLogo} from '../../../config/logos'
import {Login} from '../../modules/customer-portal/components/Login'
import {ForgotPassword} from '../../modules/customer-booking/components/forms/ForgotPassword'
import {AuthPortalBookingHeaderThumbnail} from '../../modules/customer-portal/components/AuthPortalBookingHeaderThumbnail'
import FirstLoginPasswordResetForm from '../../modules/customer-portal/components/FirstLoginPasswordReset'
import { StepperStateProvider } from '../../modules/customer-booking/pages/StepperStateContext'
import { EventListPage } from '../../modules/customer-booking/pages/EventListPage'

export const Routes = () => {
  return (
    <>
      <StepperStateProvider>
        <div
          id='kt_header'
          className={clsx('header', 'align-items-stretch', 'fixed-top')}
          style={{zIndex: 1020}}
        >
          <Helmet>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
            />
          </Helmet>
          <div className='d-flex px-3 w-100 align-items-stretch justify-content-between'>
            <div className='d-flex align-items-center'>
              <Link to='/'>
                <img
                  alt='Home'
                  src={MainDrawerMenuHeaderLogo.src}
                  className='w-100 p-3'
                  style={{maxWidth: '15rem'}}
                />
              </Link>
            </div>
          </div>
          <div className='me-5'>
            <AuthPortalBookingHeaderThumbnail />
          </div>
        </div>
        <div style={{paddingTop: '60px'}}>
          <Switch>
            <Route exact path='/auth/events' component={EventListPage} />
            <Route exact path='/auth/retail/:eventCode' component={RetailProductsPage} />
            {/* <Route exact path='/auth/book/login' component={Login} /> */}
            <Route exact path='/auth/book/login/:hash' component={Login} />
            <Route path='/auth/book/login/password-reset' component={FirstLoginPasswordResetForm} />

            <Route exact path='/auth/forgot-password' component={ForgotPassword} />
            <Route path='/auth/book/:productCode/:eventCode' component={BookingWizard} />
            <Route path='/error/404' component={FullPage404} />
            <Route path='/error/403' component={FullPage403} />
            <Redirect to='/auth/events' />
          </Switch>
        </div>
      </StepperStateProvider>
    </>
  )
}
