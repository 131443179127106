import {useMemo} from 'react'
import { HorizontalDivider } from '../../../components/utils/HorizontalDivider'

interface LoginHeaderProps {
  authToken: string | undefined
  mfaLink: string | undefined
}

export const LoginHeader = ({authToken, mfaLink}: LoginHeaderProps) => {
  const heading = useMemo(() => {
    if (mfaLink && authToken) {
      return (
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Scan QR Code</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            <p>Scan the QR code with your preferred 2FA app.</p>
            <p>
              If you are on your mobile phone, tap <a href={mfaLink}>here</a>.
            </p>
          </div>
        </div>
      )
    } else if (!mfaLink && authToken) {
      return (
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Enter OTP</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            Use your preferred 2FA app to get your OTP
          </div>
        </div>
      )
    } else {
      return (
        <div className='text-left mb-5'>
          <h1 className='text-dark mb-3'>Sign In</h1>
          {/* <div className='text-gray-400 fw-bold fs-4'>Enter details to login</div> */}
          <HorizontalDivider/>
        </div>
      )
    }
  }, [authToken, mfaLink])

  return heading
}
