import Swal, {SweetAlertOptions} from 'sweetalert2'
import {SwalMixins} from './SwalMixins'

export type AsyncCallback = (value: string) => Promise<unknown>

export abstract class SwalUtils {
  public static fire = (mixin: SweetAlertOptions = {}) => {
    Swal.mixin(SwalMixins.baseMixin).fire(mixin)
  }

  public static fireAsync = (callback: AsyncCallback, mixin: SweetAlertOptions = {}) => {
    Swal.mixin({
      ...SwalMixins.baseMixin,
      preConfirm: callback,
      showLoaderOnConfirm: true,
    }).fire(mixin)
  }

  public static failed = (mixin: SweetAlertOptions = {}) => {
    Swal.mixin(SwalMixins.failedMixin).mixin(mixin).fire()
  }

  public static asyncConfirm = (callback: AsyncCallback, mixin: SweetAlertOptions = {}) =>
    Swal.mixin(SwalMixins.confirmMixin).mixin(mixin).fire({
      showLoaderOnConfirm: true,
      preConfirm: callback,
    })

  public static confirm = (mixin: SweetAlertOptions = {}) =>
    Swal.mixin(SwalMixins.confirmMixin).mixin(mixin).fire()

  public static asyncSpecifyReason = (callback: AsyncCallback, mixin: SweetAlertOptions = {}) =>
    Swal.mixin(SwalMixins.specifyReasonMixin).mixin(mixin).fire({
      showLoaderOnConfirm: true,
      preConfirm: callback,
    })

  public static specifyReason = () => Swal.fire(SwalMixins.specifyReasonMixin)

  public static askEmail = () => Swal.fire(SwalMixins.askEmail)

  public static askOrderNotes = (mixin: SweetAlertOptions = {}) =>
    Swal.mixin(SwalMixins.askOrderNotes).fire(mixin)

  public static asyncAskEmail = (callback: AsyncCallback, mixin: SweetAlertOptions = {}) =>
    Swal.mixin(SwalMixins.askEmail).mixin(mixin).fire({
      showLoaderOnConfirm: true,
      preConfirm: callback,
    })

  public static askApprove = (mixin: SweetAlertOptions = {}) =>
    Swal.mixin(SwalMixins.askApprove).fire(mixin)
  public static cancelItem = () => Swal.fire(SwalMixins.cancelItemMixin)

  public static cancelItemAsync = (callback: AsyncCallback) =>
    Swal.fire({
      ...SwalMixins.cancelItemMixin,
      showLoaderOnConfirm: true,
      preConfirm: callback,
    })

  public static deleteItem = () => Swal.fire(SwalMixins.deleteItemMixin)

  public static deleteItemAsync = (callback: AsyncCallback, mixin: SweetAlertOptions = {}) =>
    Swal.mixin({
      ...SwalMixins.deleteItemMixin,
      showLoaderOnConfirm: true,
      preConfirm: callback,
    }).fire(mixin)

  public static unlinkItemAsync = (callback: AsyncCallback, mixin: SweetAlertOptions = {}) =>
    Swal.mixin({
      ...SwalMixins.unlinkItemMixin,
      showLoaderOnConfirm: true,
      preConfirm: callback,
    }).fire(mixin)

  public static applicationUpdate = () => Swal.fire(SwalMixins.confirmReloadMixin)
}
