import axios from 'axios'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import { BookingModelCreateParams } from '../booking-wizard/BookingWizard'
import { EventModel } from '../../../../models/ems/EventModel'

export const CreateRetailBooking = (data: BookingModelCreateParams) => {
  axios.post('bookings/hub', data)
}

export const GetProductList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(`product/customer/list`, filter)

export const GetProductByCode = (code: string) => axios.get<ProductModel>(`/product/customer/${code}`)

export const GetEventList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(`/event/app-list`, filter)