import React, {useCallback, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import clsx from 'clsx'
import style from './RetailProductsStyle.module.scss'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'
import {GetEventList} from '../components/redux/RetailBookingCrud'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {EVENT_CODE} from '../../../../config/env'
import {GetAppConfig} from '../../app-config/redux/AppConfigCRUD'
import {AppConfigModel} from '../../../models/app-config/AppConfigModel'
import {EventCard} from '../components/event-card/EventCard'
import {EventModel} from '../../../models/ems/EventModel'
import {Button} from '../../../components/inputs/Button'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'

export const EventListPage = () => {
  const history = useHistory()
  const {pushError} = useAlerts()
  const [events, setEvents] = useState<GlobalSearchModel<EventModel>>()
  const [appconfigContent, setAppconfigContent] = useState<AppConfigModel[]>()

  const handleBookNowClick = (code: string) => {
    history.push(`/auth/retail/${code}`)
  }

  const getProducts = useCallback(
    async (filters?: FilterModel) => {
      try {
        const {data} = await GetEventList({
          ...filters,
          filters: {
            isMainEvent: true,
          },
        })
        setEvents(data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [pushError]
  )

  const GetConfigGroup = useCallback(async () => {
    try {
      const {data} = await GetAppConfig()
      setAppconfigContent(data)
    } catch (e: any) {
      pushError(e)
    }
  }, [pushError])

  useOnMount(() => {
    if (EVENT_CODE) {
      getProducts()
      GetConfigGroup()
    }
  })

  const appConfig = useMemo(() => {
    if (appconfigContent) {
      const siteObj = appconfigContent?.filter((item) => item.code === 'SITEURL')
      return siteObj[0]?.data
    }
  }, [appconfigContent])

  return (
    <>
      <h2 className={clsx('d-flex', 'justify-content-center', style.retailPageContainer)}>
        EVENT LIST
      </h2>
      <div className={style.cardContainer}>
        {events &&
          events.data &&
          events.data.map((event) => (
            <EventCard
              key={event.code}
              event={event}
              onBookNow={() => handleBookNowClick(event.code)}
              config={appConfig}
            />
          ))}
      </div>
      <div className={clsx('d-flex', 'justify-content-center', style.retailPageContainer)}>
        {events && events.total > 10 && events.total !== events.data.length && (
          <Button
            uppercase={false}
            variant='primary'
            onClick={() =>
              getProducts({
                page: events.page ? events.page + 1 : 2,
                limit: events.limit + events.limit,
              })
            }
          >
            <MetronicIcon iconType='Code' iconName='Loading' />
            Load More
          </Button>
        )}
      </div>
    </>
  )
}
