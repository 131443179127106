import {useCallback, useEffect, useMemo, useState} from 'react'
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {PaneContainer} from '../../../../components/layouts/resizeable-panes/PaneContainer/PaneContainer'
import {Pane} from '../../../../components/layouts/resizeable-panes/Pane/Pane'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {BookingTable} from '../../components/tables/BookingTable/BookingTable'
import {BookingCardGrid} from '../../components/BookingCard/BookingCardGrid'
import {
  BookingPortalFormCreate,
  BookingPortalFormEdit,
} from '../../components/DrawerForm/BookingPortalFormDrawer'
import {FilterModel} from '../../../../models/FilterModel'
import {actions} from '../../redux/CustomerPortalRedux'
import {GetBookingByCode, GetEventByCode} from '../../redux/CustomerPortalCRUD'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useDrawerRef} from '../../../../components/Drawer/useDrawerRef'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useDispatch} from 'react-redux'
import {BookingModel} from '../../../../models/ems/BookingModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {BookingDetailModel} from '../../../../models/customer-portal/BookingDetailModel'
import PortalHeader from '../../components/Headers/PortalHeader'
import {LinksTypes} from './EventPortalDetailTickets'
import {isTicketShow, isVoucherShow, isSeatShow} from './CustomerPortalDetailInnerRoutes'
import {CustomerModel} from '../../../../models/CustomerModel'
import {CustomerPortalDetailInnerRoutes} from './CustomerPortalDetailInnerRoutes'
import {CustomerBookingDetailsHeader} from '../../components/CustomerBookingDetailsHeader'
import {usePortaDataBookingDetail} from '../../hooks/usePortaDataBookingDetail'
import { EventModel } from '../../../../models/ems/EventModel'

export interface CustomerPortalDetailBookingProps {
  customer?: CustomerModel
}

interface RouteMatch {
  bookingCode?: string
  customerCode?: string
  eventCode?: string
  ticketCode?: string
  bookingProductCode?: string
  path: string
}

const CustomerPortalDetailBooking = ({customer}: CustomerPortalDetailBookingProps) => {
  const match = useRouteMatch<RouteMatch>()

  useState<GlobalSearchModel<BookingDetailModel>>()
  const {bookingCode, customerCode, eventCode, ticketCode, bookingProductCode} = match.params
  const history = useHistory()
  const breakpoints = useBreakpoint()
  const isMobile = useMemo(() => breakpoints.down('md'), [breakpoints])
  const bookings = useRootStateSelector((state) => state.customerPortal.bookings)

  const [drawerCreate, setDrawerCreate] = useDrawerRef()

  const [drawerEdit, setDrawerEdit] = useDrawerRef()
  const [bookingToEdit, setBookingToEdit] = useState<BookingModel>()
  const {setFilter} = useEntityFilter('customer-portal-booking')
  const {setFilter: setBookingProductFilter} = useEntityFilter('customer-portal-booking-product')
  const [currentBookingEvent, setCurrentBookingEvent] = useState<EventModel>()

  const dispatch = useDispatch()
  const {
    currentTicket,
    currentBookingsDetails,
    bookingInitialFilters,
    getTicketByCode,
    refreshCurrentBookingDetails,
    refreshBookingTable,
    currentBooking,
  } = usePortaDataBookingDetail({eventCode, ticketCode, bookingCode})

  useEffect(() => {
    const fetchEvent = async () => {
      if (currentBookingsDetails) {
        const currentEventCode = currentBookingsDetails.data[0].eventCode
        const { data } = await GetEventByCode(currentEventCode)
        setCurrentBookingEvent(data)
      }
    }
    fetchEvent()
  }, [currentBookingsDetails])


  const links: LinksTypes = useMemo(() => {
    let bookings = [
      {
        title: 'Booking details',
        to: `/customer/${customerCode}/booking/${bookingCode}/booking-detail`,
      },
    ]

    if (isVoucherShow(match)) {
      bookings.push({
        title: 'Vouchers',
        to: `/customer/${customerCode}/booking/${bookingCode}/booking-product/${bookingProductCode}/voucher`,
      })
    }

    if (isTicketShow(match)) {
      bookings.push({
        title: 'Tickets',
        to: `/customer/${customerCode}/booking/${bookingCode}/booking-product/${bookingProductCode}/ticket`,
      })
    }

    if (isSeatShow(match)) {
      bookings.push({
        title: 'Ticket Seats',
        to: `/customer/${customerCode}/booking/${bookingCode}/booking-product/${bookingProductCode}/ticket/${ticketCode}/seat`,
      })
    }

    return {
      bookings,
    }
  }, [bookingCode, bookingProductCode, customerCode, match, ticketCode])

  useOnChange(ticketCode, async () => {
    if (ticketCode) {
      getTicketByCode()
    }
  })

  useOnChange(bookingCode, async () => {
    if (bookingCode) {
      refreshCurrentBookingDetails()
    }
  })

  const onFilterProductHandler = useCallback(
    (filter: FilterModel) => {
      setBookingProductFilter({
        ...filter,
        filters: {...filter.filters, booking: bookingCode},
      })
      dispatch(actions.bookingProducts.search())
    },
    [setBookingProductFilter, bookingCode, dispatch]
  )

  const handleFilterBookingTable = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refreshBookingTable()
    },
    [setFilter, refreshBookingTable]
  )

  const handleEditBookingTable = useCallback(
    async (booking) => {
      const response = await GetBookingByCode(booking.code)
      if (drawerEdit) {
        drawerEdit.show()
      }
      setBookingToEdit(response.data)
      refreshCurrentBookingDetails()
    },
    [drawerEdit, refreshCurrentBookingDetails]
  )


  const handleSplitPaneClose = useCallback(() => {
    if (customer) history.push(`/customer/${customer.code}/booking`)
  }, [customer, history])

  const bookingInnerPages = useMemo(() => {
    return currentBookingsDetails ? (
      <>
        <CustomerBookingDetailsHeader
          booking={currentBooking}
          onRefresh={refreshCurrentBookingDetails}
          onRefreshCallback={refreshBookingTable}
        />
        <PortalHeader links={links.bookings} />
        <CustomerPortalDetailInnerRoutes
          match={match}
          customer={customer}
          currentTicket={currentTicket}
          bookingCode={bookingCode}
          bookingDetails={currentBookingsDetails}
          bookingProductCode={bookingProductCode}
          locationCode={currentTicket?.location?.code}
          productCode={currentTicket?.product?.code}
          onFilterProductHandler={onFilterProductHandler}
          refreshCurrentDetails={refreshCurrentBookingDetails}
          refreshTable={refreshBookingTable}
          customerCode={customerCode}
          currentEvent={currentBookingEvent}
        />
      </>
    ) : null
  }, [
    bookingCode,
    bookingProductCode,
    currentBooking,
    currentBookingsDetails,
    currentTicket,
    customer,
    customerCode,
    links.bookings,
    match,
    onFilterProductHandler,
    refreshBookingTable,
    refreshCurrentBookingDetails,
  ])

  const handleNewBookingClick = useCallback(() => {
    if (customer) history.push(`/customer/${customer.code}/booking/new`)
  }, [customer, history])

  const bookingsTable = useMemo(() => {
    return (
      <>
        <BookingTable
          initialFilters={bookingInitialFilters}
          onRefresh={refreshBookingTable}
          onRefreshCallback={refreshCurrentBookingDetails}
          onEdit={handleEditBookingTable}
          onNewBookingClick={handleNewBookingClick}
          className='d-none d-md-block'
          data={bookings}
          onFilter={handleFilterBookingTable}
          customer={customer}
        />
        <BookingCardGrid
          initialFilters={bookingInitialFilters}
          className='d-md-none'
          data={bookings}
          onFilter={handleFilterBookingTable}
        />
      </>
    )
  }, [
    bookingInitialFilters,
    refreshBookingTable,
    refreshCurrentBookingDetails,
    handleEditBookingTable,
    handleNewBookingClick,
    bookings,
    handleFilterBookingTable,
    customer,
  ])

  return (
    <>
      <BookingPortalFormEdit
        bookingToEdit={bookingToEdit}
        drawerRef={setDrawerEdit}
        onChange={refreshBookingTable}
        onRefreshCallback={refreshCurrentBookingDetails}
        customer={customer}
      />

      <BookingPortalFormCreate
        customer={customer}
        drawerRef={setDrawerCreate}
        onChange={refreshBookingTable}
      />

      <Switch>
        <Route path={`${match.path}`}>
          <PaneContainer className='flex-grow-1' direction='horizontal'>
            {/* <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '0%' : '35%'}>
              {bookingsTable}
            </Pane> */}
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '100%' : '65%'}>
              <MetronicIconButton
                size='sm'
                iconType='Navigation'
                iconName='Arrow-from-left'
                onClick={handleSplitPaneClose}
                variant='primary'
                tooltip='Close Pane'
                className='mb-1'
              />
              {bookingInnerPages}
            </Pane>
          </PaneContainer>
        </Route>
        <Redirect to={match.path} />
      </Switch>
    </>
  )
}

export default CustomerPortalDetailBooking
