import {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  PasswordResetForm,
  PasswordResetFormValues,
} from '../../../../components/forms/PasswordResetForm'

import {actions} from '../../redux/AdminRedux'
import {ResetPassword} from '../../redux/AdminCrud'
import {useAdminAuth} from '../../hooks/useAdminAuth'

const FirstLoginPasswordResetForm = () => {
  const _auth = useAdminAuth()
  const history = useHistory()
  useEffect(() => {
    if (!_auth) {
      history.replace('/auth/login')
    }
  }, [_auth, history])

  const dispatch = useDispatch()
  const handleOnSubmit = useCallback(
    async (formValues: PasswordResetFormValues) => {
      const response = await ResetPassword(formValues.newPassword)
      dispatch(actions.auth.login(response.data.token))
    },
    [dispatch]
  )

  return <PasswordResetForm onSubmit={handleOnSubmit} />
}

export default FirstLoginPasswordResetForm
