import {FormikContextType} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {FilterModel} from '../../../models/FilterModel'
import {TableRowId} from '../../tables/TableRow'
import {CustomerWizardTable} from '../BookingWizardTables/CustomerWizardTable'
import {useOnChange} from '../../hooks/useOnChange'
import {Button} from '../../inputs/Button'
import {
  BookingWizardBulkCustomerStepFormValues,
  BulkConsolidatedFormValues,
} from '../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {CustomerModel} from '../../../models/CustomerModel'
import {MetronicIcon} from '../../inputs/MetronicIcon'
import {CustomerMappingTableModal} from '../BookingWizardTables/CustomerMappingTableModal'
import {CsvModalSubmitFunction} from '../../forms/CsvMapModal'
import {useAlerts} from '../../alerts/useAlerts'
import {useModalState} from '../../modals/useModalState'
import {
  CreateCustomerImport,
  GetCustomerByCode,
} from '../../../modules/customer-portal/redux/CustomerPortalCRUD'
import {LoadingSpinner} from '../../utils/LoadingSpinner'
import {SweetAlert} from '../../modals/SweetAlert'
import {SwalUtils} from '../../../utils/SwalUtils'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useDebounce} from '../../hooks/useDebounce'

export interface BookingWizardSharedConsolidatedCustomerStepProps<
  T extends BookingWizardBulkCustomerStepFormValues
> {
  formik: FormikContextType<T>
  disabledFields?: Partial<Record<keyof BookingWizardBulkCustomerStepFormValues, boolean>>
  isEdit?: boolean
  onNoCustonmers?: (is: boolean) => void
  onStepChange?: (step: number) => void
  bookingBulkForm?: BulkConsolidatedFormValues
  customers?: GlobalSearchModel<CustomerModel>
  searchCustomers: (filter?: FilterModel) => void
  isPortal?: boolean
}

export const BookingWizardSharedConsolidatedCustomerStep = <
  T extends BookingWizardBulkCustomerStepFormValues
>({
  formik,
  isEdit,
  onNoCustonmers,
  onStepChange,
  bookingBulkForm,
  customers,
  searchCustomers,
  isPortal,
}: BookingWizardSharedConsolidatedCustomerStepProps<T>) => {
  const [isStart, setIsStart] = useState<boolean>(false)
  const [isLoad, setIsload] = useState<boolean>(false)
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [existCodes, setExistCodes] = useState<string[]>([])
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])
  const {push, pushError} = useAlerts()
  const modalState = useModalState()
  const debounce = useDebounce(500)

  const selectedItems = useMemo(() => {
    return formik.values?.customers ? formik.values.customers.map((item) => item.code) : []
  }, [formik.values.customers, bookingBulkForm])

  const handleOnRowSelectionChange = useCallback(
    async (rows: TableRowId[]) => {
      if (isStart) {
        const foundData = customers?.data.filter((customer) => {
          return rows.find((item) => customer.code === item)
        })
        if (foundData) {
          if (foundData === formik.values.customers) {
            formik.setFieldValue('customers', '')
          } else {
            if (formik.values.customers?.length) {
              const selectedCustomerItems = formik.values.customers
              let listCustomer: CustomerModel[] = []
              selectedCustomerItems.map((customer) => {
                listCustomer.push({...customer})
                return null
              })
              foundData.map((customer) => {
                const findIndx = listCustomer.findIndex((i) => i.code === customer.code)
                if (findIndx === -1) {
                  listCustomer.push({...customer})
                }
                return null
              })
              const filterCustomer = listCustomer.filter((customer) => {
                return rows.find((item) => customer.code === item)
              })
              formik.setFieldValue('customers', filterCustomer)
            } else {
              formik.setFieldValue('customers', foundData)
            }
          }
        }
      }
    },
    [customers?.data, formik, isStart]
  )

  const onFilterHandler = useCallback(
    (filter: FilterModel) => {
      searchCustomers(filter)
    },
    [searchCustomers]
  )

  const isCustomer = useMemo(() => {
    const noCustomer = customers?.data && customers.data.length > 0

    return noCustomer
  }, [customers?.data])

  const handleImport: CsvModalSubmitFunction = useCallback(
    async (mapping, csv, file) => {
      let listCustomer: CustomerModel[] = []
      try {
        setIsload(true)
        const csvFile = file.getFirst()?.getFile()
        if (!csvFile) {
          throw new Error('CSV file is not uploaded.')
        }
        const {data} = await CreateCustomerImport(mapping.toArray(), csvFile, csv.hasHeaders)

        if (data && data.length) {
          push({
            timeout: 10000,
            variant: 'success',
            message: `Customers has been uploaded.`,
          })

          for (const customer of data) {
            const {data: customerDetail} = await GetCustomerByCode(customer)
            if (customerDetail) {
              listCustomer.push(customerDetail)
            }
          }
          formik.setFieldValue('customers', listCustomer)
          onStepChange?.(2)
        } else {
          if (data?.codes?.length) {
            setExistCodes(data.codes)
          }
          if (data?.invalid?.length) {
            setInvalidEmails(data.invalid)
          }
          debounce(() => {
            setIsOpen(true)
            modalState.hide()
          })
        }
      } catch (e: any) {
        pushError(e)
      } finally {
        modalState.hide()
        setIsload(false)
      }
    },
    [setIsload, push, pushError, modalState]
  )

  const handleOnclose = useCallback(() => {
    setIsOpen(false)
  }, [])

  useOnChange(isCustomer, () => {
    if (!isCustomer) onNoCustonmers && onNoCustonmers(true)
    else if (isCustomer) onNoCustonmers && onNoCustonmers(false)
  })

  useOnChange(formik.values.customers, () => {
    if (formik.values?.customers?.length) {
      const customerList = formik.values?.customers.map((customer) => {
        return {
          customer: customer,
          products: [],
          isSkip: false,
          isFulfill: false,
          isSelected: false,
        }
      })
      if (customerList && customerList.length) {
        customerList[0].isSelected = true
        formik.setFieldValue('bookingResults', customerList)
      }
    }
    if (!isStart) {
      if (formik.values.customers) {
        setTimeout(() => {
          setIsStart(true)
          formik.setFieldValue('customers', formik.values?.customers)
        }, 1000)
      } else {
        setTimeout(() => {
          setIsStart(true)
        }, 1000)
      }
    }
  })

  useEffect(() => {
    if (bookingBulkForm?.bookingResults?.length) {
      let listCustomer: CustomerModel[] = []
      for (const customer of bookingBulkForm.bookingResults) {
        if (customer.customer) {
          listCustomer.push(customer.customer)
        }
      }
      if (listCustomer.length) {
        formik.setFieldValue('customers', listCustomer)
      }
    }
  }, [bookingBulkForm])

  const customersTable = useMemo(() => {
    return (
      <div>
        <CustomerWizardTable
          onFilter={onFilterHandler}
          data={customers}
          hideSelectAll={true}
          selectedItems={selectedItems}
          onRowSelectionChange={handleOnRowSelectionChange}
          isEdit={isEdit}
          isBulk={true}
          rightToolbar={
            isPortal ? (
              <Button className='btn btn-primary' onClick={modalState.open} disabled={isLoad}>
                <LoadingSpinner spinnerOnly loading={isLoad}>
                  <MetronicIcon iconType='Navigation' iconName='Arrow-from-top' />
                </LoadingSpinner>
                IMPORT CUSTOMERS
              </Button>
            ) : undefined
          }
        />
        {!isCustomer && (
          <div className='text-center'>
            <Button
              variant='primary'
              className='btn'
              onClick={() => onStepChange && onStepChange(1)}
            >
              Create new customer
            </Button>
          </div>
        )}
      </div>
    )
  }, [
    customers,
    handleOnRowSelectionChange,
    isCustomer,
    isEdit,
    onFilterHandler,
    onStepChange,
    selectedItems,
  ])

  const handleSubmit = useCallback(async () => {
    try {
      setIsloading(true)
      if (existCodes.length) {
        let listCustomer: CustomerModel[] = []
        for (const customer of existCodes) {
          const {data: customerDetail} = await GetCustomerByCode(customer)
          if (customerDetail) {
            listCustomer.push(customerDetail)
          }
        }
        formik.setFieldValue('customers', listCustomer)
        onStepChange?.(2)
      }
    } catch (e: any) {
      pushError(e)
    } finally {
      setIsloading(false)
      setIsOpen(false)
    }
  }, [existCodes])

  return (
    <>
      <div className='container'>
        <div className='row g-5'>
          <h5 className='mt-15 mb-0 px-8'>Select a customer</h5>
          {customersTable}
        </div>
      </div>
      <CustomerMappingTableModal
        onSubmit={handleImport}
        onClose={modalState.hide}
        open={modalState.isOpen}
        isLoading={isLoad}
      />
      <SweetAlert onClose={handleOnclose} open={isOpen} showConfirmButton={false}>
        <div className='swal2-icon swal2-warning swal2-icon-show'>
          <div className='swal2-icon-content ms-9'>!</div>
        </div>
        <div className='d-flex flex-column'>
          <h3>Something went wrong with the following data.</h3>
        </div>
        <div className='d-flex my-4'>
          <div className='w-25'></div>
          <div className='w-50'>
            <ul>
              {invalidEmails.length
                ? invalidEmails.map((item, id) => (
                    <li key={id} className='text-start'>
                      {item}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
        <div className='w-25'></div>
        <h6>Do you wish to continue?</h6>
        <div className='d-flex justify-content-center gap-2 mt-5'>
          <Button variant='primary' onClick={handleSubmit} disabled={isLoading}>
            <LoadingSpinner spinnerOnly loading={isLoading}></LoadingSpinner>
            OK
          </Button>
          <Button onClick={handleOnclose}>CANCEL</Button>
        </div>
      </SweetAlert>
    </>
  )
}
