import {CUSTOMER_TICKET_MAX_SHARE} from '../../../../../../config/env'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {isTicketCancel, isTicketDownloadable} from '../../../hooks/useTicketTableActions'

export interface TicketTableSelectionActionsProps {
  items: TicketPortalModel[]
  onCancel?: (data: TicketPortalModel[]) => void
  onDownload?: (data: TicketPortalModel[]) => void
  onShareViaEmail?: (data: TicketPortalModel[]) => void
  selected: TableRowId[]
  disabled?: boolean
}

export const TicketTableSelectionActions = ({
  items,
  onCancel,
  selected,
  disabled,
  onDownload,
  onShareViaEmail,
}: TicketTableSelectionActionsProps) => {
  return (
    <>
      <TableSelectionAction
        idExtractor={idExtractor}
        onClick={onCancel}
        selected={selected}
        items={items}
        variant='primary'
        filter={isTicketCancel}
        limitCount={CUSTOMER_TICKET_MAX_SHARE}
        disabled={disabled}
      >
        Cancel Tickets
      </TableSelectionAction>
      <TableSelectionAction
        idExtractor={idExtractor}
        onClick={onDownload}
        selected={selected}
        items={items}
        variant='warning'
        filter={isTicketDownloadable}
        limitCount={CUSTOMER_TICKET_MAX_SHARE}
        disabled={disabled}
      >
        Download Tickets
      </TableSelectionAction>
      <TableSelectionAction
        idExtractor={idExtractor}
        onClick={onShareViaEmail}
        selected={selected}
        items={items}
        variant='info'
        filter={isTicketDownloadable}
        limitCount={CUSTOMER_TICKET_MAX_SHARE}
        disabled={disabled}
      >
        Share By Email
      </TableSelectionAction>
    </>
  )
}
