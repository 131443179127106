import clsx from 'clsx'
import {forwardRef, ReactNode, useMemo} from 'react'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export interface TextInputProps extends InputProps {
  label?: ReactNode
  errorMessage?: string
  isTouched?: boolean
  noMargin?: boolean
  fullWidth?: boolean
  isRequired?: boolean
  inputClassName?: string
  startAdornment?: ReactNode
  endAdornment?: ReactNode
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      label,
      id,
      errorMessage,
      isTouched,
      noMargin,
      fullWidth,
      inputClassName,
      startAdornment,
      endAdornment,
      isRequired,
      ...inputProps
    },
    ref
  ) => {
    const validationClassName = useMemo(() => {
      if (!isTouched) {
        return null
      }
      return errorMessage ? 'is-invalid' : 'is-valid'
    }, [errorMessage, isTouched])

    const endAdornmentNode = useMemo(() => {
      if (typeof endAdornment === 'string') {
        return (
          <div className='input-group-append'>
            <span className='input-group-text' id='basic-addon2'>
              {endAdornment}
            </span>
          </div>
        )
      }
      return <div className='input-group-append'>{endAdornment}</div>
    }, [endAdornment])

    const startAdornmentNode = useMemo(() => {
      if (typeof startAdornment === 'string') {
        return (
          <div className='input-group-append'>
            <span className='input-group-text' id='basic-addon2'>
              {startAdornment}
            </span>
          </div>
        )
      }
      return <div className='input-group-append'>{startAdornment}</div>
    }, [startAdornment])

    return (
      <div
        className={clsx(
          {
            'mb-5': !noMargin,
            'w-100': fullWidth,
          },
          className
        )}
      >
        <div>
          {label && (
            <label
              className={isRequired ? 'form-label w-100 required' : 'form-label w-100'}
              htmlFor={id}
            >
              {label}
            </label>
          )}
        </div>
        <div
          className={clsx({
            'input-group input-group-solid': Boolean(startAdornment || endAdornment),
          })}
        >
          {startAdornmentNode}
          <input
            className={clsx('form-control form-control-solid', validationClassName, inputClassName)}
            id={id}
            ref={ref}
            {...inputProps}
          />
          {endAdornmentNode}
        </div>
        {isTouched && errorMessage && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger'>
                {errorMessage}
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
)
