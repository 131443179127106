import {ChangeEvent, useCallback, useMemo} from 'react'
import {SelectInput, SelectInputItem} from './SelectInput'

export interface MappingTableInputProps {
  mappableKeys: SelectInputItem[]
  value: Record<string, number>
  headers: string[]
  onChange: (value: Record<string, number>) => void
  body: string[][]
}

export const MappingTableInput = ({
  body,
  value,
  onChange,
  mappableKeys,
  headers,
}: MappingTableInputProps) => {
  const getAvailableMappableKeys = useCallback(
    (columnValue: string) => {
      const availableKeys: SelectInputItem[] = [
        {
          label: 'None',
          value: '',
        },
      ]
      mappableKeys.forEach((key) => {
        if (columnValue === key.value || value[key.value] === undefined) {
          availableKeys.push(key)
        }
      })
      return availableKeys
    },
    [mappableKeys, value]
  )

  const getValueByColumnIndex = useCallback(
    (index: number) => {
      const existingKey = Object.entries(value).find(([key, value]) => {
        return value === index
      })
      if (existingKey) {
        return existingKey[0]
      }
    },
    [value]
  )

  const getSelectInputChangeHandler = useCallback(
    (columnIndex: number) => (e: ChangeEvent<HTMLSelectElement>) => {
      const keyValue = e.target.value
      const newValues = {...value}

      const existingKey = getValueByColumnIndex(columnIndex)
      if (existingKey) {
        delete newValues[existingKey]
      }

      if (keyValue) {
        newValues[keyValue] = columnIndex
      }

      onChange(newValues)
    },
    [value, onChange, getValueByColumnIndex]
  )

  const headerNodes = useMemo(() => {
    return (
      <thead>
        <tr>
          {headers.map((label, index) => {
            const value = getValueByColumnIndex(index) || ''
            return (
              <th key={`${label}${index}`} scope='col'>
                <SelectInput
                  noMargin
                  className='min-w-100px'
                  label={<span className='text-nowrap'>{label}</span>}
                  items={getAvailableMappableKeys(value)}
                  onChange={getSelectInputChangeHandler(index)}
                  value={value}
                />
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }, [getAvailableMappableKeys, getSelectInputChangeHandler, getValueByColumnIndex, headers])

  const bodyNodes = useMemo(() => {
    return body.map((row, index) => {
      return (
        <tr key={`${row.join()}${index}`}>
          {row.map((col, index) => {
            return <td key={`${col}${index}`}>{col}</td>
          })}
        </tr>
      )
    })
  }, [body])

  return (
    <table className='table'>
      {headerNodes}
      <tbody>{bodyNodes}</tbody>
    </table>
  )
}
