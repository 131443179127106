import {useFormik} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {LocationModel} from '../../../../models/acs/LocationModel'
import {
  TicketModelFulfillBulkParams,
  TicketModelFulfillParams,
} from '../../../../models/ems/TicketModel'
import {
  GetLocationByCode,
  GetLocationsByProductCode,
} from '../../../../modules/default/acs/redux/AcsCRUD'
import {ApiTree} from '../../../../utils/Tree/ApiTree'
import {useAlerts} from '../../../alerts/useAlerts'
import {useOnChange} from '../../../hooks/useOnChange'
import {usePrevious} from '../../../hooks/usePrevious'
import {useSafeStateUpdate} from '../../../hooks/useSafeStateUpdate'
import {useSeatMapState} from '../../../hooks/useSeatMapState'
import {SeatMapSelectionModalInput} from '../../../inputs/SeatMapInput/SeatMapSelectionModalInput'
import {SeatMapValue} from '../../../inputs/SeatMapInput/SeatMapValue'
import {TreeNodeItem} from '../../../inputs/TreeSelect/TreeSelect'
import {CustomersProductProps} from '../../../../models/booking-wizard/BulkBookingWizard'
import {GetAvailableSeatMaps} from '../../../../modules/customer-portal/redux/CustomerPortalCRUD'
import {
  ACTIVE_COLOR,
  DANGER_COLOR,
  DEFAULT_COLOR,
  PURPLE_COLOR,
} from '../../../inputs/SeatMapInput/hooks/useSeatHelper'
import {
  SeatMapBoxLegends,
  SeatMapBoxLegendsProps,
} from '../../../inputs/SeatMapInput/SeatMapBoxLegends'
import {DateRange} from '../../../../utils/DateRange'
import moment from 'moment'
import {DateUtil} from '../../../../utils/DateUtil'
import {useDebounce} from '../../../hooks/useDebounce'
import {EventModel} from '../../../../models/ems/EventModel'
import {ActivityModel} from '../../../../models/ems/ActivityModel'

export interface BookingSeatMapValues {
  selected: SeatMapValue
  locationCode: string
  dateRange?: DateRange
}

export interface BookingSeatMapProps {
  initialValues?: BookingSeatMapValues
  onSubmit: (values: BookingSeatMapValues) => void | Promise<void>
  open: boolean
  onHide: () => void
  product?: CustomersProductProps | null
  eventCode: string
  locations?: LocationModel[]
  customerCode?: string
  selectedSeat?: TicketModelFulfillBulkParams
  otherSeats?: TicketModelFulfillBulkParams[] | null
  getLocationCodeCallBack?: (location: string) => void
  event?: EventModel | ActivityModel | null
  minimumDate?: Date
  maximumDate?: Date
}

export const BookingSeatMap = ({
  onSubmit,
  initialValues = EMPTY_FORM_VALUES,
  onHide,
  open,
  product,
  eventCode,
  customerCode,
  selectedSeat,
  otherSeats,
  getLocationCodeCallBack,
  event,
  minimumDate,
  maximumDate,
}: BookingSeatMapProps) => {
  const [count, setCount] = useState(0)
  const {
    resetOccupiedData,
    seatMapSpacingX,
    occupied,
    extra,
    disabled,
    columns,
    rows,
    hidden,
    isRightToLeft,
    isBottomToTop,
    resetState: resetSeatMapState,
    isLoading,
    setIsLoading,
    setAvailableSeates,
    active,
    sharedDisabled,
    setSeatsDays,
    seatsDays,
    setOccupied,
  } = useSeatMapState()
  const safeUpdate = useSafeStateUpdate()
  const previousInitialValues = usePrevious(initialValues)
  const previousOpen = usePrevious(open)
  const {push, pushError} = useAlerts()
  const [locations, setLocations] = useState<LocationModel[]>([])
  const searchDebounce = useDebounce(100)

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await onSubmit(values)
    },
  })

  useOnChange(open, () => {
    if (!open) {
      formik.setFieldValue('locationCode', '')
      formik.setFieldValue('selected', new SeatMapValue())
    }
  })

  const handleSeatMapChange = useCallback(
    (value: SeatMapValue) => {
      formik.setFieldValue('selected', value)
    },
    [formik]
  )

  const minDate = useMemo(() => {
    const today = moment().startOf('day')
    if (minimumDate) {
      const startedAt = DateUtil.getDateFromApiString(`${minimumDate}`)
      return moment.max(moment(startedAt), today).toDate()
    } else if (product) {
      const startedAt = DateUtil.getDateFromApiString(product.startedAt)
      return moment.max(moment(startedAt), today).toDate()
    }
  }, [product, minimumDate])

  const maxDate = useMemo(() => {
    if (maximumDate) {
      return DateUtil.getDateFromApiString(`${maximumDate}`)
    } else if (product) {
      return DateUtil.getDateFromApiString(product.endedAt)
    }
  }, [product, maximumDate])

  const inputValues = useMemo(() => {
    return {locationCode: formik.values.locationCode, dateRange: formik.values.dateRange}
  }, [formik.values.dateRange, formik.values.locationCode])

  const resetLocationByCode = useCallback(
    async (locationCode: string, eventCode: string, dateRange?: DateRange) => {
      const doneLoading = setIsLoading(locationCode)

      try {
        const {data} = await GetLocationByCode(locationCode)
        if (!data.seatMap) {
          push({
            message: `No seat maps available for ${data.name}`,
            variant: 'danger',
            timeout: 5000,
          })
        } else {
          safeUpdate(() => resetSeatMapState(data.seatMap))
        }
      } catch (e: any) {
        pushError(e)
      } finally {
        let startDate,
          endDate = ''
        if (product && product?.productCode && !customerCode) {
          if (product.isTimeslot && dateRange?.hasValues() && event) {
            const _startDate = moment(moment(dateRange.getStartOrFail()).toDate()).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            startDate = DateUtil.convertDateTimeToVenueTimezoneToUTC(
              _startDate,
              `${event.venue?.timezone?.name}`
            )
            const _endDate = moment(moment(dateRange.getEndOrFail()).toDate()).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            endDate = DateUtil.convertDateTimeToVenueTimezoneToUTC(
              _endDate,
              `${event.venue?.timezone?.name}`
            )
            resetOccupiedData(locationCode, eventCode, product?.productCode, startDate, endDate)
          } else {
            resetOccupiedData(locationCode, eventCode, product?.productCode)
          }
        }

        if (product && customerCode) {
          try {
            if (product.isTimeslot && dateRange?.hasValues() && event) {
              const _startDate = moment(moment(dateRange.getStartOrFail()).toDate()).format(
                'YYYY-MM-DD HH:mm:ss'
              )
              startDate = DateUtil.convertDateTimeToVenueTimezoneToUTC(
                _startDate,
                `${event.venue?.timezone?.name}`
              )
              const _endDate = moment(moment(dateRange.getEndOrFail()).toDate()).format(
                'YYYY-MM-DD HH:mm:ss'
              )
              endDate = DateUtil.convertDateTimeToVenueTimezoneToUTC(
                _endDate,
                `${event.venue?.timezone?.name}`
              )
            }

            const {
              data: {availableSeats, days, usedSeats},
            } = await GetAvailableSeatMaps(
              locationCode,
              product.productCode,
              eventCode,
              customerCode,
              startDate,
              endDate
            )

            setOccupied(new SeatMapValue(usedSeats))

            if (days && Object.keys(days).length > 0) {
              Object.keys(days).forEach((item) => {
                days[item] = new SeatMapValue(days[item])
              })

              setSeatsDays(days)
            } else setSeatsDays(null)
            setAvailableSeates(new SeatMapValue(availableSeats))
          } catch (err) {
            pushError(err)
          }
        }
        doneLoading()
      }
    },
    [
      setIsLoading,
      push,
      safeUpdate,
      resetSeatMapState,
      pushError,
      product,
      customerCode,
      resetOccupiedData,
      setOccupied,
      setSeatsDays,
      setAvailableSeates,
      event,
    ]
  )

  useOnChange(open, () => {
    if (open && selectedSeat) {
      formik.setFieldValue('locationCode', selectedSeat.locationCode)
      resetLocationByCode(selectedSeat.locationCode, eventCode)
    }
  })

  const locationItems = useMemo((): TreeNodeItem[] => {
    const locationTree = new ApiTree(locations)
    return locationTree.getTreeSelectItems()
  }, [locations])

  const handleLocationChange = useCallback(
    (locationCode) => {
      formik.setFieldValue('locationCode', locationCode)
      getLocationCodeCallBack && getLocationCodeCallBack(locationCode)
      formik.setFieldValue('selected', new SeatMapValue())
      resetSeatMapState()
      if (locationCode && product) {
        resetLocationByCode(locationCode, eventCode)
      }
    },
    [eventCode, formik, getLocationCodeCallBack, product, resetLocationByCode, resetSeatMapState]
  )

  const resetLocationList = useCallback(async () => {
    setLocations([])
    if (product && product?.productCode) {
      try {
        const {data} = await GetLocationsByProductCode(product.productCode)
        if (data) setLocations(data)
      } catch (err) {
        pushError(err)
      }
    }
  }, [product, pushError])

  const occupiedSeats = useMemo(() => {
    return occupied
  }, [occupied])

  const disabledSeats = useMemo(() => {
    if (customerCode) {
      let activeSeats = new SeatMapValue()
      if (extra) {
        activeSeats = activeSeats.union(extra)
      }
      if (active) {
        activeSeats = activeSeats.union(active)
      }
      if (occupied) {
        activeSeats = activeSeats.union(occupied)
      }
      return new SeatMapValue(rows, columns).difference(activeSeats)
    } else {
      return disabled
    }
  }, [active, columns, customerCode, disabled, extra, occupied, rows])

  const sharedSeats = useMemo(() => {
    if (otherSeats) {
      const newOtherSeats = otherSeats.map((item) => {
        return item.seats
      })
      let final = sharedDisabled
      newOtherSeats.forEach((item) => {
        final = final.union(item)
      })
      return final
    }

    return sharedDisabled
  }, [sharedDisabled, otherSeats])

  useOnChange(product, () => {
    if (product) {
      setCount(product.productQty || 0)
      resetLocationList()
    }
  })

  useEffect(() => {
    if (
      previousInitialValues?.locationCode !== initialValues.locationCode &&
      previousInitialValues?.selected.getSeatMapObject() !==
        initialValues.selected.getSeatMapObject()
    ) {
      formik.resetForm()
    }
  }, [formik, initialValues, previousInitialValues])

  useEffect(() => {
    if (previousOpen !== open) {
      if (open) {
        formik.setValues(initialValues)
      } else formik.setValues(EMPTY_FORM_VALUES)

      resetSeatMapState()
    }
  }, [formik, initialValues, open, previousOpen, resetSeatMapState, selectedSeat])

  useOnChange(inputValues, () => {
    if (inputValues.locationCode && inputValues.dateRange?.hasValues()) {
      searchDebounce(() => {
        formik.setFieldValue('selected', new SeatMapValue())
        resetLocationByCode(inputValues.locationCode, eventCode, inputValues.dateRange)
      })
    }
  })

  const getModalTitle = useCallback(() => {
    return product?.name || ''
  }, [product?.name])

  const handleDateRangeChange = useCallback(
    (value: DateRange) => {
      formik.setFieldValue('dateRange', value)
    },
    [formik]
  )

  return (
    <SeatMapSelectionModalInput
      modalTitle={getModalTitle}
      spacingX={seatMapSpacingX}
      occupied={occupiedSeats}
      // extra={extra}
      locationItems={locationItems}
      locationCode={formik.values.locationCode}
      onLocationChange={handleLocationChange}
      onSubmit={formik.handleSubmit}
      count={count}
      disabled={disabledSeats}
      sharedDisabled={sharedSeats}
      columns={columns}
      rows={rows}
      loading={isLoading}
      disableSubmit={formik.isSubmitting || !formik.isValid}
      disableSelection={formik.isSubmitting}
      onChange={handleSeatMapChange}
      value={formik.values.selected}
      open={open}
      hidden={hidden}
      onHide={onHide}
      isRightToLeft={isRightToLeft}
      isBottomToTop={isBottomToTop}
      legends={<SeatMapBoxLegends data={LEGENDS} />}
      seatsDays={seatsDays}
      isCircledDaysBox={seatsDays ? true : false}
      isShowNoneFilledDays={seatsDays ? false : false}
      dateRange={product?.isTimeslot ? formik.values.dateRange : undefined}
      onDateRangeChange={handleDateRangeChange}
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}

const EMPTY_FORM_VALUES: BookingSeatMapValues = {
  selected: new SeatMapValue(),
  locationCode: '',
  dateRange: new DateRange(),
}

export const getPayload = (
  values: BookingSeatMapValues,
  productCode: string
): TicketModelFulfillParams => {
  const payload: TicketModelFulfillParams = {
    locationCode: values.locationCode,
    seats: values.selected.getSeatMapObject(),
    productCode,
  }

  return payload
}

const LEGENDS: SeatMapBoxLegendsProps[] = [
  {text: 'Available', color: DEFAULT_COLOR, width: 20, height: 20},
  {text: 'Selected', color: ACTIVE_COLOR, width: 20, height: 20},
  {text: 'Booked', color: DANGER_COLOR, width: 20, height: 20},
  {text: 'Reserved', color: PURPLE_COLOR, width: 20, height: 20},
]
