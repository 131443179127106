import {FormikContextType} from 'formik'
import {ChangeEvent, useCallback, useEffect, useMemo} from 'react'
import {useBookingFormData} from '../hook/useBookingFormData'

import {
  SelectTreeOptionGroup,
  SelectTreeOptionGroupItem,
} from '../../../../../../components/inputs/SelectTreeNative/SelectTreeOptionGroup'
import {SelectInputItem} from '../../../../../../components/inputs/SelectInput'
import {BookingWizardEventStepFormValues} from '../../../../../../models/booking-wizard/BookingWizard'
import {useBookingWiard} from '../hook/useBookingWizard'

export interface BookingWizardEventStepProps<T extends BookingWizardEventStepFormValues> {
  formik: FormikContextType<T>
  disabledFields?: Partial<Record<keyof BookingWizardEventStepFormValues, boolean>>
}

export const BookingWizardEventStep = <T extends BookingWizardEventStepFormValues>({
  formik,
}: BookingWizardEventStepProps<T>) => {
  const {events, resetEventFieldItems} = useBookingFormData({})

  useEffect(() => {
    resetEventFieldItems()
  }, [resetEventFieldItems])
  const {getEvent} = useBookingWiard()


  const eventInputItems = useMemo((): SelectInputItem[] => {
    return events
      .filter((item) => !item.isClosed)
      .map((event) => {
        return {
          label: event.name,
          value: event.code,
        }
      })
  }, [events])

  const activityAndEventItems = useMemo((): SelectTreeOptionGroupItem[] => {
    return [{label: 'Events', items: eventInputItems}]
  }, [eventInputItems])

  const handleEventCodeOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value
      formik.setFieldValue('eventCode', value)
      getEvent(value)
    },
    [formik]
  )

  const selectEvent = useMemo(() => {
    return (
      <div>
        <SelectTreeOptionGroup
          label={'Activity or Event'}
          placeholder={`Select an Activity or an Event`}
          items={activityAndEventItems}
          {...formik.getFieldProps('eventCode')}
          onChange={handleEventCodeOnChange}
        />
      </div>
    )
  }, [activityAndEventItems, formik, handleEventCodeOnChange])

  return (
    <>
      <div className='container'>
        <div className='row g-5'>
          <div className='col-12'>
            <h5 className='mt-7 mb-4'>Select an event</h5>
          </div>
          {selectEvent}
        </div>
      </div>
    </>
  )
}
