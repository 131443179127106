import {CustomersProductProps} from '../../../models/booking-wizard/BulkBookingWizard'
import {TicketModelFulfillBulkParams} from '../../../models/ems/TicketModel'

interface SeatTableColumnProps {
  customersSeats: TicketModelFulfillBulkParams[] | null
  data: CustomersProductProps
}
export const SeatTableColumn = ({customersSeats, data}: SeatTableColumnProps) => {
  return (
    <div className='d-flex gap-1 flex-wrap w-200px'>
      {customersSeats
        ?.filter(
          (item) =>
            data.bundleCode
              ? (item.customerCode === data.code && item.bundleCode === data.bundleCode) ||
                (item.customerCode === data.customerCode &&
                  item.bundleCode === data.bundleCode &&
                  item.productCode === data.productCode)
              : (item.customerCode === data.code && item.bundleCode === undefined) ||
                (item.customerCode === data.customerCode &&
                  item.bundleCode === undefined &&
                  item.productCode === data.productCode)
          // item.customerCode === data.code ||
          // (data.bundleCode && item.bundleCode === data.bundleCode)
        )
        .map((item) =>
          Object.keys(item.seats.getSeatMapObject()).map((seat, index, seatsArray) => {
            const isFirstLast = index === seatsArray.length - 1
            return item.seats.getSeatMapObject()[seat].map((s, idx, array) => {
              const isLast = idx === array.length - 1
              return (
                <div key={seat + idx}>{`${seat}${s}${
                  !isLast ? ', ' : !isFirstLast ? ', ' : ''
                }`}</div>
              )
            })
          })
        )}
    </div>
  )
}
