import type {DetailedHTMLProps, InputHTMLAttributes} from 'react'
import clsx from 'clsx'

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export interface CheckboxInputProps extends InputProps {
  label?: string
  fullWidth?: boolean
  noMargin?: boolean
}

export const CheckboxInput = ({
  label,
  fullWidth,
  className,
  id,
  noMargin,
  ...inputProps
}: CheckboxInputProps) => {
  return (
    <div
      className={clsx(
        `form-check form-switch form-check-solid `,
        {'mb-5': !noMargin, 'form-check-inline': !noMargin, 'w-100': fullWidth},
        className
      )}
    >
      <input id={id} className='form-check-input' type='checkbox' {...inputProps} />
      {label && (
        <label htmlFor={id} className='form-check-label text-muted mt-1'>
          {label}
        </label>
      )}
    </div>
  )
}
