import {useCallback, useMemo, useState} from 'react'
import {useModalState} from '../../modals/useModalState'
import {SeatMapValue} from '../SeatMapInput/SeatMapValue'
import {useAlerts} from '../../alerts/useAlerts'
import {useSeatMapState} from '../../hooks/useSeatMapState'
import {TreeSelectNode} from '../TreeSelect/TreeSelectNode'
import {KTSVG} from '../../../../_metronic/helpers'
import {MetronicIconButton} from '../MetronicIconButton'
import {TreeSelectLastLevel} from '../TreeSelectLastLevel/TreeSelectLastLevel'
import {Button} from '../Button'
import {SeatMapSelectionModalInput} from '../SeatMapInput/SeatMapSelectionModalInput'
import {SelectTreeOptionGroupItem} from '../SelectTreeNative/SelectTreeOptionGroup'
import clsx from 'clsx'
import {GetLocationByCode} from '../../../modules/default/acs/redux/AcsCRUD'
import {SeatMapBoxLegends, SeatMapBoxLegendsProps} from '../SeatMapInput/SeatMapBoxLegends'
import {
  ACTIVE_COLOR,
  DANGER_COLOR,
  DEFAULT_COLOR,
  PURPLE_COLOR,
} from '../SeatMapInput/hooks/useSeatHelper'
import {useReservationState} from '../../../modules/default/ems/components/wizards/ReservationWizard/ReservationStateProvider'

export interface ReservationSeatInputItemAttributes extends SelectTreeOptionGroupItem {
  items?: ReservationSeatInputItemAttributes[]
  label: string
  value: string
}

export interface ReservationSeatInputItemValue {
  value: string // Location Code
  label: string
  id: number | string
  seatMaps: SeatMapValue | null
  productCode: string
  qty: number
}

export interface ReservationSeatInputItemProps {
  className?: string
  value: ReservationSeatInputItemValue
  onChange: (value: ReservationSeatInputItemValue) => void
  items: ReservationSeatInputItemAttributes[]
  selectedItems: string[]
  placeholder: string
  label: string
  onRemove: (value: ReservationSeatInputItemValue) => void
  eventCode?: string | null
  isLocationDisabled?: (locationCode: any) => boolean
  productCode: string
  productName?: string
  qty: number
}

export const ReservationSeatInputItem = ({
  items,
  onChange,
  className,
  value,
  placeholder,
  label,
  onRemove,
  selectedItems,
  eventCode,
  isLocationDisabled,
  productCode,
  productName,
  qty,
}: ReservationSeatInputItemProps) => {
  const {totalCountSelected} = useReservationState()

  const {
    isOpen: isSelectionModalOpen,
    hide: hideSelectionModal,
    open: openSelectionModal,
  } = useModalState()
  const [locationCode, setLocationCode] = useState<string>()
  const [_productCode, setProductCode] = useState<string>(productCode)
  const {pushError} = useAlerts()
  const [selected, setSelected] = useState<SeatMapValue>()
  const [totalSelected, setTotalSelected] = useState(0)
  const [selectedCount, setSelectedCount] = useState(0)

  const {
    seatMapSpacingX,
    occupied,
    disabled,
    columns,
    rows,
    hidden,
    isBottomToTop,
    isRightToLeft,
    isLoading,
    resetState: resetSeatMapState,
    resetOccupiedByReservationData,
  } = useSeatMapState()

  const handleReserveSeat = useCallback(
    async (locationCode, eventCode) => {
      try {
        const {data} = await GetLocationByCode(locationCode)

        resetSeatMapState(data.seatMap)
        if (!data.seatMap) {
          throw new Error('Location has no seat map!')
        }
        resetOccupiedByReservationData(locationCode, eventCode)
      } catch (e: any) {
        pushError(e)
      }

      if (!value.seatMaps) {
        if (totalCountSelected === 0) {
          setSelectedCount(0)
        } else {
          const totalRemaining = totalCountSelected
          setSelectedCount(totalRemaining)
        }
      }

      openSelectionModal()
    },
    [
      openSelectionModal,
      pushError,
      resetOccupiedByReservationData,
      resetSeatMapState,
      qty,
      totalCountSelected,
      value,
    ]
  )

  const handleUpdateSeat = useCallback(
    async (locationCode, eventCode) => {
      try {
        setLocationCode(locationCode)

        if (value.seatMaps) {
          setSelected(value.seatMaps)
          let selectedQty = 0

          for (const seat of Object.values(value.seatMaps?.getSeatMapObject())) {
            selectedQty += seat.length
          }
          const totalRemaining = totalCountSelected - selectedQty
          setSelectedCount(totalRemaining)
        }
        handleReserveSeat(locationCode, eventCode)
        setProductCode(value.productCode)
      } catch (e: any) {
        pushError(e)
      }
    },
    [handleReserveSeat, pushError, value.productCode, value.seatMaps, totalCountSelected]
  )
  const handleSelectionModalClose = useCallback(() => {
    hideSelectionModal()
  }, [hideSelectionModal])

  const handleRemove = useCallback(() => {
    onRemove(value)
  }, [value, onRemove])

  const getTreeSelectItems = useCallback(
    (items: ReservationSeatInputItemAttributes[]) => {
      return items.map((item) => {
        const isSelected = selectedItems.includes(item.value)

        const treeItem: ReservationSeatInputItemAttributes = {
          label: item.label,
          value: item.value,
          disabled: isSelected || item.disabled || false,
        }
        if (item.items) {
          treeItem.items = getTreeSelectItems(item.items)
        }

        return treeItem
      })
    },
    [selectedItems]
  )

  const handleSelectionSubmit = useCallback(async () => {
    if (locationCode) {
      const {data} = await GetLocationByCode(locationCode)

      onChange({
        ...value,
        value: locationCode,
        seatMaps: selected || null,
        label: data.name,
        productCode: _productCode,
        qty: totalSelected || 0,
      })
      hideSelectionModal()
    }
  }, [_productCode, hideSelectionModal, locationCode, onChange, selected, totalSelected, value])

  const Locationvalue = useMemo(() => {
    if (locationCode) {
      return [locationCode]
    }
    return []
  }, [locationCode])

  const handleParentLocationChange = useCallback((newValues: string[]) => {
    setLocationCode(newValues[0])
  }, [])

  const treeSelectItems = useMemo((): ReservationSeatInputItemAttributes[] => {
    return getTreeSelectItems(items)
  }, [items, getTreeSelectItems])

  const isDisabled = useCallback(
    (item: TreeSelectNode) => {
      if (isLocationDisabled) {
        return isLocationDisabled(item.id)
      }
      return false
    },
    [isLocationDisabled]
  )

  const handleOnSelectChange = useCallback(
    (selected: SeatMapValue) => {
      setSelected(selected.difference(occupied))
      setTotalSelected(selected.getValueCount)
    },
    [occupied]
  )

  const getModalTitle = useCallback(() => {
    return productName || ''
  }, [productName])

  return (
    <div className={clsx('product-input-item', className)}>
      <div className='d-flex justify-content-between'>
        <label className='form-label mt-2'>{label}</label>
        {!value.label && (
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary'
            onClick={handleRemove}
          >
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </button>
        )}
      </div>

      <div className='d-flex'>
        <div className='flex-grow-1'>
          <label className='mt-3'>{value.label}</label>
        </div>
        <div className='flex-end me-1'>
          {value.label && (
            <MetronicIconButton
              iconType='Home'
              iconName='Armchair'
              type='button'
              tooltip='Update Seat'
              onClick={() => handleUpdateSeat(value.value, eventCode)}
              color='info'
            />
          )}
        </div>
        {value.label && (
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary'
            onClick={handleRemove}
          >
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </button>
        )}
      </div>

      {!value.seatMaps && (
        <div className='product-input-item-input-container__select-input'>
          <TreeSelectLastLevel
            label='Select Location'
            radioName='parentLocation'
            values={Locationvalue}
            items={treeSelectItems}
            onChange={handleParentLocationChange}
            isLastLevelSelect
            disabled={isDisabled}
          />
        </div>
      )}

      {locationCode && !value.value && (
        <div className='pt-2 flex-grow-1'>
          <Button
            type='button'
            variant='primary'
            size='sm'
            uppercase={false}
            onClick={() => handleReserveSeat(locationCode, eventCode)}
          >
            Reserve Seats
          </Button>
        </div>
      )}

      <SeatMapSelectionModalInput
        modalTitle={getModalTitle}
        spacingX={seatMapSpacingX}
        occupied={occupied}
        // extra={extra}
        // locationItems={locationItems}
        locationCode={locationCode}
        // onLocationChange={handleLocationChange}
        onSubmit={handleSelectionSubmit}
        count={qty - selectedCount}
        // count={qty}
        disabled={disabled}
        columns={columns}
        rows={rows}
        loading={isLoading}
        disableSubmit={false}
        onChange={handleOnSelectChange}
        value={selected}
        open={isSelectionModalOpen}
        hidden={hidden}
        onHide={handleSelectionModalClose}
        isRightToLeft={isRightToLeft}
        isBottomToTop={isBottomToTop}
        legends={<SeatMapBoxLegends data={LEGENDS} />}
        multipleLocation
      />
    </div>
  )
}

const LEGENDS: SeatMapBoxLegendsProps[] = [
  {text: 'Available', color: DEFAULT_COLOR, width: 20, height: 20},
  {text: 'Selected', color: ACTIVE_COLOR, width: 20, height: 20},
  {text: 'Reserved', color: DANGER_COLOR, width: 20, height: 20},
  {text: 'Allocated', color: PURPLE_COLOR, width: 20, height: 20},
]
