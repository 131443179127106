import {ManageRowAction} from '../../../../../../components/tables/actions/ManageRowAction'
import {RowAction} from '../../../../../../components/tables/actions/RowAction'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {useCustomerHelpers} from './useCustomerHelpers'

export interface CustomerTableRowActionsProps {
  data: CustomerModel
  onDelete?: (data: CustomerModel) => void
  onEdit?: (data: CustomerModel) => void
  onSendRegistration?: (data: CustomerModel) => void
  onActivate?: (data: CustomerModel) => void
  onCopyLink?: (data: CustomerModel) => void
  onSetSmartPin?: (data: CustomerModel) => void
  onSetAccountCode?: (data: CustomerModel) => void
  onResetMfa?: (data: CustomerModel) => void
  isLoading?: boolean
}

export const CustomerTableRowActions = ({
  data,
  onDelete,
  onEdit,
  onSendRegistration,
  onActivate,
  onCopyLink,
  onSetSmartPin,
  onSetAccountCode,
  onResetMfa,
  isLoading,
}: CustomerTableRowActionsProps) => {
  const {customerCanBeActivated, canSetAccountCode} = useCustomerHelpers()

  return (
    <>
      {/* {customerCanBeSentRegistration(data) && (
        <RowAction
          data={data}
          iconType='Communication'
          iconName='Sending mail'
          variant='info'
          tooltip='Send registration email'
          onClick={onSendRegistration}
          loading={isLoading}
        />
      )}
      {customerRegistrationLinkCanBeCopied(data) && (
        <RowAction
          data={data}
          iconType='General'
          iconName='Clipboard'
          variant='primary'
          tooltip='Copy registration link'
          onClick={onCopyLink}
          loading={isLoading}
        />
      )}
      {customerCanBeActivated(data) && (
        <RowAction
          data={data}
          iconType='Code'
          iconName='Thunder-circle'
          variant='success'
          tooltip={isSiteRegistrationEnabled ? 'Activate' : 'Send Credentials'}
          onClick={onActivate}
          loading={isLoading}
        />
      )} */}
      {canSetAccountCode(data) &&<RowAction
        data={data}
        iconType='Communication'
        iconName='Group'
        variant='warning'
        tooltip='Set Account Code'
        onClick={onSetAccountCode}
        loading={isLoading}
      />}
      <RowAction
        data={data}
        iconType='Devices'
        iconName='Keyboard'
        variant='success'
        tooltip='Set Smart Pin'
        onClick={onSetSmartPin}
        loading={isLoading}
      />
      <RowAction
        loading={isLoading}
        data={data}
        iconType='General'
        iconName='Unlock'
        variant='warning'
        tooltip='Reset Customer MFA'
        onClick={onResetMfa}
      />
      {customerCanBeActivated(data) && (
        <RowAction
          data={data}
          iconType='Communication'
          iconName='Sending mail'
          variant='success'
          tooltip='Send Credentials'
          onClick={onActivate}
          loading={isLoading}
        />
      )}
      <ManageRowAction data={data} onDelete={onDelete} onEdit={onEdit} loading={isLoading} />
    </>
  )
}
