import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import {EventModel, EventModelCreateParams} from '../../../../models/ems/EventModel'
import {ProductModel, ProductModelCreateParams} from '../../../../models/ems/ProductModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {BookingModel, BookingModelCreateParams} from '../../../../models/ems/BookingModel'
import {
  DeleteBundles,
  DeleteProductCategories,
  DeleteProducts,
  GetActivities,
  GetBookingBundles,
  GetBookingProducts,
  GetBookings,
  GetBundles,
  GetEventByCode,
  GetEvents,
  GetEventsTree,
  GetProductCategories,
  GetProducts,
  GetTickets,
  PostBooking,
  PostBundle,
  PostEvent,
  PostProduct,
  PostProductCategory,
  PutBooking,
  PutEvent,
  GetTicketLogs,
  PostActivity,
  PutActivity,
} from './EmsCRUD'
import {FilterModel} from '../../../../models/FilterModel'
import {
  ProductCategoryModel,
  ProductCategoryModelCreateParams,
} from '../../../../models/ems/ProductCategoryModel'
import {AccessLogModel} from '../../../../models/acs/AccessLogModel'
import {ActivityModel, ActivityModelCreateParams} from '../../../../models/ems/ActivityModel'
import {BundleModel, BundleModelCreateParams} from '../../../../models/ems/BundleModel'
import {TicketListModel} from '../../../../models/ems/TicketModel'
import {TicketLogModel} from '../../../../models/acs/TicketLogModel'
import {BookingFormValues} from '../../../../models/booking-wizard/BookingWizard'
import {ReservationFormValues} from '../components/wizards/ReservationWizard/ReservationWizard'
import {BulkBookingFormValues} from '../../../../models/booking-wizard/BulkBookingWizard'
import {TimeZoneModel} from '../../../../models/acs/TimezoneModel'
import {GetTimezoneByCode} from '../../acs/redux/AcsCRUD'
import {BulkConsolidatedFormValues} from '../../../../models/booking-wizard/BulkConsolidatedBookingWizard'

interface ActionWithPayload<T> extends Action {
  payload: T
}

interface IPagesState {
  event?: EventModel
  activities?: ActivityModel[]
  bookings?: GlobalSearchModel<BookingModel>
  bookingForm?: BookingFormValues
  bookingBulkForm?: BulkBookingFormValues
  bookingBulkConsolidatedForm?: BulkConsolidatedFormValues
  reservationForm?: ReservationFormValues
  events?: GlobalSearchModel<EventModel>
  products?: GlobalSearchModel<ProductModel>
  productCategories?: GlobalSearchModel<ProductCategoryModel>
  accessLogs?: GlobalSearchModel<AccessLogModel>
  eventTree?: EventModel[]
  bookingProducts?: GlobalSearchModel<ProductModel>
  bookingBundles?: GlobalSearchModel<BundleModel>
  bundles?: GlobalSearchModel<BundleModel>
  tickets?: GlobalSearchModel<TicketListModel>
  ticketLogs?: GlobalSearchModel<TicketLogModel>
  timezone?: TimeZoneModel
}

const initialAuthState: IPagesState = {
  event: undefined,
  eventTree: undefined,
  bookings: undefined,
  bookingForm: undefined,
  bookingBulkForm: undefined,
  reservationForm: undefined,
  events: undefined,
  products: undefined,
  productCategories: undefined,
  accessLogs: undefined,
  bookingProducts: undefined,
  tickets: undefined,
  ticketLogs: undefined,
  timezone: undefined,
}

// ACTION TYPES
const actionTypes = {
  // ********************************************************** //
  // CREATE TYPES
  // ********************************************************** //

  // FULFILL TICKET
  FULFILL_TICKET: '[EMS] FULFILL TICKET',
  FULFILL_TICKET_SUCCESS: '[EMS] FULFILL TICKET SUCCESS',
  FULFILL_TICKET_FAILED: '[EMS] FULFILL TICKET FAILED',

  // CREATE BOOKING
  CREATE_BOOKING: '[EMS] CREATE BOOKING',
  CREATE_BOOKING_SUCCESS: '[EMS] CREATE BOOKING SUCCESS',
  CREATE_BOOKING_FAILED: '[EMS] CREATE BOOKING FAILED',

  BOOKING_FORM: '[EMS] BOOKING FORM',
  BOOKING_BULK_FORM: '[EMS] BOOKING BULK FORM',
  RESERVATION_FORM: '[EMS] RESERVATION FORM',
  BOOKING_BULK_CONSOLIDATED_FORM: '[EMS] BOOKING BULK CONSOLIDATED FORM',

  // CREATE BUNDLES
  CREATE_BUNDLE: '[EMS] CREATE BUNDLE',
  CREATE_BUNDLE_SUCCESS: '[EMS] CREATE BUNDLE SUCCESS',
  CREATE_BUNDLE_FAILED: '[EMS] CREATE BUNDLE FAILED',

  // CREATE EVENT
  CREATE_EVENT: '[EMS] CREATE EVENT',
  CREATE_EVENT_SUCCESS: '[EMS] CREATE EVENT SUCCESS',
  CREATE_EVENT_FAILED: '[EMS] CREATE EVENT FAILED',

  // CREATE ACTIVITY
  CREATE_ACTIVITY: '[EMS] CREATE ACTIVITY',
  CREATE_ACTIVITY_SUCCESS: '[EMS] CREATE ACTIVITY SUCCESS',
  CREATE_ACTIVITY_FAILED: '[EMS] CREATE ACTIVITY FAILED',

  // CREATE PRODUCT
  CREATE_PRODUCT: '[EMS] CREATE PRODUCT',
  CREATE_PRODUCT_SUCCESS: '[EMS] CREATE PRODUCT SUCCESS',
  CREATE_PRODUCT_FAILED: '[EMS] CREATE PRODUCT FAILED',

  // CREATE PRODUCT CATEGORY
  CREATE_PRODUCT_CATEGORY: '[EMS] CREATE PRODUCT CATEGORY',
  CREATE_PRODUCT_CATEGORY_SUCCESS: '[EMS] CREATE PRODUCT CATEGORY SUCCESS',
  CREATE_PRODUCT_CATEGORY_FAILED: '[EMS] CREATE PRODUCT CATEGORY FAILED',

  // ********************************************************** //
  // UPDATE TYPES
  // ********************************************************** //

  // UPDATE BOOKING
  UPDATE_BOOKING: '[EMS] UPDATE BOOKING',
  UPDATE_BOOKING_SUCCESS: '[EMS] UPDATE BOOKING SUCCESS',
  UPDATE_BOOKING_FAILED: '[EMS] UPDATE BOOKING FAILED',

  // UPDATE BOOKING
  UPDATE_TICKET: '[EMS] UPDATE TICKET',
  UPDATE_TICKET_SUCCESS: '[EMS] UPDATE TICKET SUCCESS',
  UPDATE_TICKET_FAILED: '[EMS] UPDATE TICKET FAILED',

  // UPDATE BOOKING
  UPDATE_BUNDLE: '[EMS] UPDATE BUNDLE',
  UPDATE_BUNDLE_SUCCESS: '[EMS] UPDATE BUNDLE SUCCESS',
  UPDATE_BUNDLE_FAILED: '[EMS] UPDATE BUNDLE FAILED',

  // UPDATE EVENT
  UPDATE_EVENT: '[EMS] UPDATE EVENT',
  UPDATE_EVENT_SUCCESS: '[EMS] UPDATE EVENT SUCCESS',
  UPDATE_EVENT_FAILED: '[EMS] UPDATE EVENT FAILED',

  // UPDATE ACTIVITY
  UPDATE_ACTIVITY: '[EMS] UPDATE ACTIVITY',
  UPDATE_ACTIVITY_SUCCESS: '[EMS] UPDATE ACTIVITY SUCCESS',
  UPDATE_ACTIVITY_FAILED: '[EMS] UPDATE ACTIVITY FAILED',

  // UPDATE PRODUCT
  UPDATE_PRODUCT: '[EMS] UPDATE PRODUCT',
  UPDATE_PRODUCT_SUCCESS: '[EMS] UPDATE PRODUCT SUCCESS',
  UPDATE_PRODUCT_FAILED: '[EMS] UPDATE PRODUCT FAILED',

  // UPDATE PRODUCT CATEGORY
  UPDATE_PRODUCT_CATEGORY: '[EMS] UPDATE PRODUCT CATEGORY',
  UPDATE_PRODUCT_CATEGORY_SUCCESS: '[EMS] UPDATE PRODUCT CATEGORY SUCCESS',
  UPDATE_PRODUCT_CATEGORY_FAILED: '[EMS] UPDATE PRODUCT CATEGORY FAILED',

  // ********************************************************** //
  // DELETE TYPES
  // ********************************************************** //

  // DELETE BOOKING
  DELETE_BOOKING: '[EMS] DELETE BOOKING',
  DELETE_BOOKING_SUCCESS: '[EMS] DELETE BOOKING SUCCESS',
  DELETE_BOOKING_FAILED: '[EMS] DELETE BOOKING FAILED',

  // DELETE BOOKING
  DELETE_BUNDLE: '[EMS] DELETE BUNDLE',
  DELETE_BUNDLE_SUCCESS: '[EMS] DELETE BUNDLE SUCCESS',
  DELETE_BUNDLE_FAILED: '[EMS] DELETE BUNDLE FAILED',

  // DELETE EVENT
  DELETE_EVENT: '[EMS] DELETE EVENT',
  DELETE_EVENT_SUCCESS: '[EMS] DELETE EVENT SUCCESS',
  DELETE_EVENT_FAILED: '[EMS] DELETE EVENT FAILED',

  // DELETE ACTIVITY
  DELETE_ACTIVITY: '[EMS] DELETE ACTIVITY',
  DELETE_ACTIVITY_SUCCESS: '[EMS] DELETE ACTIVITY SUCCESS',
  DELETE_ACTIVITY_FAILED: '[EMS] DELETE ACTIVITY FAILED',

  // DELETE PRODUCT
  DELETE_PRODUCT: '[EMS] DELETE PRODUCT',
  DELETE_PRODUCT_SUCCESS: '[EMS] DELETE PRODUCT SUCCESS',
  DELETE_PRODUCT_FAILED: '[EMS] DELETE PRODUCT FAILED',

  // DELETE PRODUCT CATEGORY
  DELETE_PRODUCT_CATEGORY: '[EMS] DELETE PRODUCT CATEGORY',
  DELETE_PRODUCT_CATEGORY_SUCCESS: '[EMS] DELETE PRODUCT CATEGORY SUCCESS',
  DELETE_PRODUCT_CATEGORY_FAILED: '[EMS] DELETE PRODUCT CATEGORY FAILED',

  // ********************************************************** //
  // SEARCH TYPES
  // ********************************************************** //
  // SEARCH TICKET
  SEARCH_TICKET: '[EMS] SEARCH TICKET',
  SEARCH_TICKET_SUCCESS: '[EMS] SEARCH TICKET SUCCESS',
  SEARCH_TICKET_FAILED: '[EMS] SEARCH TICKET FAILED',

  // SEARCH BOOKING
  SEARCH_BOOKING: '[EMS] SEARCH BOOKING',
  SEARCH_BOOKING_SUCCESS: '[EMS] SEARCH BOOKING SUCCESS',
  SEARCH_BOOKING_FAILED: '[EMS] SEARCH BOOKING FAILED',

  // SEARCH BUNDLE
  SEARCH_BUNDLE: '[EMS] SEARCH BUNDLE',
  SEARCH_BUNDLE_SUCCESS: '[EMS] SEARCH BUNDLE SUCCESS',
  SEARCH_BUNDLE_FAILED: '[EMS] SEARCH BUNDLE FAILED',

  // SEARCH BOOKING PRODUCT
  SEARCH_BOOKING_PRODUCT: '[EMS] SEARCH BOOKING PRODUCT',
  SEARCH_BOOKING_PRODUCT_SUCCESS: '[EMS] SEARCH BOOKING PRODUCT SUCCESS',
  SEARCH_BOOKING_PRODUCT_FAILED: '[EMS] SEARCH BOOKING PRODUCT FAILED',

  // SEARCH BOOKING BUNDLE
  SEARCH_BOOKING_BUNDLE: '[EMS] SEARCH BOOKING BUNDLE',
  SEARCH_BOOKING_BUNDLE_SUCCESS: '[EMS] SEARCH BOOKING BUNDLE SUCCESS',
  SEARCH_BOOKING_BUNDLE_FAILED: '[EMS] SEARCH BOOKING BUNDLE FAILED',

  // SEARCH EVENT
  SEARCH_EVENT: '[EMS] SEARCH EVENT',
  SEARCH_EVENT_SUCCESS: '[EMS] SEARCH EVENT SUCCESS',
  SEARCH_EVENT_FAILED: '[EMS] SEARCH EVENT FAILED',

  // SEARCH EVENT TREE
  SEARCH_EVENT_TREE: '[EMS] SEARCH EVENT TREE',
  SEARCH_EVENT_TREE_SUCCESS: '[EMS] SEARCH EVENT TREE SUCCESS',
  SEARCH_EVENT_TREE_FAILED: '[EMS] SEARCH EVENT TREE FAILED',

  // SEARCH EVENT BY CODE
  SEARCH_EVENT_BY_CODE: '[EMS] SEARCH EVENT BY CODE',
  SEARCH_EVENT_BY_CODE_SUCCESS: '[EMS] SEARCH EVENT BY CODE SUCCESS',
  SEARCH_EVENT_BY_CODE_FAILED: '[EMS] SEARCH EVENT BY CODE FAILED',

  // SEARCH ACTIVITY
  SEARCH_ACTIVITY: '[EMS] SEARCH ACTIVITY',
  SEARCH_ACTIVITY_SUCCESS: '[EMS] SEARCH ACTIVITY SUCCESS',
  SEARCH_ACTIVITY_FAILED: '[EMS] SEARCH ACTIVITY FAILED',

  // SEARCH ACTIVITY TREE
  SEARCH_ACTIVITY_TREE: '[EMS] SEARCH ACTIVITY TREE',
  SEARCH_ACTIVITY_TREE_SUCCESS: '[EMS] SEARCH ACTIVITY TREE SUCCESS',
  SEARCH_ACTIVITY_TREE_FAILED: '[EMS] SEARCH ACTIVITY TREE FAILED',

  // SEARCH ACTIVITY BY CODE
  SEARCH_ACTIVITY_BY_CODE: '[EMS] SEARCH ACTIVITY BY CODE',
  SEARCH_ACTIVITY_BY_CODE_SUCCESS: '[EMS] SEARCH ACTIVITY BY CODE SUCCESS',
  SEARCH_ACTIVITY_BY_CODE_FAILED: '[EMS] SEARCH ACTIVITY BY CODE FAILED',

  // SEARCH PRODUCT
  SEARCH_PRODUCT: '[EMS] SEARCH PRODUCT',
  SEARCH_PRODUCT_SUCCESS: '[EMS] SEARCH PRODUCT SUCCESS',
  SEARCH_PRODUCT_FAILED: '[EMS] SEARCH PRODUCT FAILED',

  // SEARCH PRODUCT CATEGORY
  SEARCH_PRODUCT_CATEGORY: '[EMS] SEARCH PRODUCT CATEGORY',
  SEARCH_PRODUCT_CATEGORY_SUCCESS: '[EMS] SEARCH PRODUCT CATEGORY SUCCESS',
  SEARCH_PRODUCT_CATEGORY_FAILED: '[EMS] SEARCH PRODUCT CATEGORY FAILED',

  // SEARCH TICKET LOG
  SEARCH_TICKET_LOG: '[EMS] SEARCH TICKET LOG',
  SEARCH_TICKET_LOG_SUCCESS: '[EMS] SEARCH TICKET LOG SUCCESS',
  SEARCH_TICKET_LOG_FAILED: '[EMS] SEARCH TICKET LOG FAILED',

  // SEARCH TIMEZONE BY CODE
  SEARCH_TIMEZONE_BY_CODE: '[EMS] SEARCH TIMEZONE BY CODE',
  SEARCH_TIMEZONE_BY_CODE_SUCCESS: '[EMS] SEARCH TIMEZONE BY CODE SUCCESS',
  SEARCH_TIMEZONE_BY_CODE_FAILED: '[EMS] SEARCH TIMEZONE BY CODE FAILED',
}

// REDUCERS
export const reducer = persistReducer(
  {
    storage,
    key: 'webapp-ems',
    whitelist: ['bookingForm', 'bookingBulkForm', 'reservationForm', 'bookingBulkConsolidatedForm'],
  },
  (state: IPagesState = initialAuthState, action: Partial<ActionWithPayload<IPagesState>>) => {
    switch (action.type) {
      case actionTypes.SEARCH_BOOKING_SUCCESS: {
        const bookings = action.payload?.bookings
        return {...state, bookings}
      }
      case actionTypes.SEARCH_EVENT_SUCCESS: {
        const events = action.payload?.events
        return {...state, events}
      }
      case actionTypes.SEARCH_EVENT_TREE_SUCCESS: {
        const eventTree = action.payload?.eventTree
        return {...state, eventTree}
      }
      case actionTypes.SEARCH_EVENT_BY_CODE_SUCCESS: {
        const event = action.payload?.event
        return {...state, event}
      }
      case actionTypes.SEARCH_PRODUCT_SUCCESS: {
        const products = action.payload?.products
        return {...state, products}
      }
      case actionTypes.SEARCH_BOOKING_PRODUCT_SUCCESS: {
        const bookingProducts = action.payload?.products
        return {...state, bookingProducts}
      }
      case actionTypes.SEARCH_BOOKING_BUNDLE_SUCCESS: {
        const bookingBundles = action.payload?.bundles
        return {...state, bookingBundles}
      }
      case actionTypes.SEARCH_PRODUCT_CATEGORY_SUCCESS: {
        const productCategories = action.payload?.productCategories
        return {...state, productCategories}
      }
      case actionTypes.SEARCH_ACTIVITY_SUCCESS: {
        const activities = action.payload?.activities
        return {...state, activities}
      }
      case actionTypes.SEARCH_BUNDLE_SUCCESS: {
        const bundles = action.payload?.bundles
        return {...state, bundles}
      }
      case actionTypes.SEARCH_TICKET_SUCCESS: {
        const tickets = action.payload?.tickets
        return {...state, tickets}
      }
      case actionTypes.SEARCH_TICKET_LOG_SUCCESS: {
        const ticketLogs = action.payload?.ticketLogs
        return {...state, ticketLogs}
      }

      case actionTypes.BOOKING_FORM: {
        const bookingForm = action.payload?.bookingForm
        return {...state, bookingForm}
      }

      case actionTypes.BOOKING_BULK_FORM: {
        const bookingBulkForm = action.payload?.bookingBulkForm
        return {...state, bookingBulkForm}
      }
      case actionTypes.RESERVATION_FORM: {
        const reservationForm = action.payload?.reservationForm
        return {...state, reservationForm}
      }
      case actionTypes.BOOKING_BULK_CONSOLIDATED_FORM: {
        const bookingBulkConsolidatedForm = action.payload?.bookingBulkConsolidatedForm
        return {...state, bookingBulkConsolidatedForm}
      }
      case actionTypes.SEARCH_TIMEZONE_BY_CODE_SUCCESS: {
        const timezone = action.payload?.timezone
        return {...state, timezone}
      }
      default:
        return state
    }
  }
)

// ACTIONS
export const actions = {
  // ********************************************************** //
  // CREATE ACTIONS
  // ********************************************************** //

  // CREATE BOOKING
  createBooking: (payload: BookingModelCreateParams) => ({
    type: actionTypes.CREATE_BOOKING,
    payload,
  }),
  createBookingFailed: () => ({type: actionTypes.CREATE_BOOKING_FAILED}),
  createBookingSuccess: () => ({type: actionTypes.CREATE_BOOKING_SUCCESS}),

  // CREATE BOOKING FORM
  setBookingForm: (payload?: BookingFormValues) => ({
    type: actionTypes.BOOKING_FORM,
    payload: {
      bookingForm: payload,
    },
  }),

  resetBookingForm: () => ({
    type: actionTypes.BOOKING_FORM,
  }),

  setBookingBulkForm: (payload?: BulkBookingFormValues) => ({
    type: actionTypes.BOOKING_BULK_FORM,
    payload: {
      bookingBulkForm: payload,
    },
  }),

  resetBookingBulkForm: () => ({
    type: actionTypes.BOOKING_BULK_FORM,
  }),

  setBookingBulkConsolidatedForm: (payload?: BulkConsolidatedFormValues) => ({
    type: actionTypes.BOOKING_BULK_CONSOLIDATED_FORM,
    payload: {
      bookingBulkConsolidatedForm: payload,
    },
  }),

  resetBookingBulkConsolidatedForm: () => ({
    type: actionTypes.BOOKING_BULK_CONSOLIDATED_FORM,
  }),

  // CREATE RESERVATION FORM
  setReservationForm: (payload?: ReservationFormValues) => ({
    type: actionTypes.RESERVATION_FORM,
    payload: {
      reservationForm: payload,
    },
  }),

  resetReservationForm: () => ({
    type: actionTypes.RESERVATION_FORM,
  }),

  // CREATE BUNDLE
  createBundle: (payload: BundleModelCreateParams) => ({
    type: actionTypes.CREATE_BUNDLE,
    payload,
  }),
  createBundleFailed: () => ({type: actionTypes.CREATE_BUNDLE_FAILED}),
  createBundleSuccess: () => ({type: actionTypes.CREATE_BUNDLE_SUCCESS}),

  // CREATE EVENT
  createEvent: (payload: FormData) => ({type: actionTypes.CREATE_EVENT, payload}),
  createEventFailed: () => ({type: actionTypes.CREATE_EVENT_FAILED}),
  createEventSuccess: () => ({type: actionTypes.CREATE_EVENT_SUCCESS}),

  // CREATE ACTIVITY
  createActivity: (payload: ActivityModelCreateParams) => ({
    type: actionTypes.CREATE_ACTIVITY,
    payload,
  }),
  createActivityFailed: () => ({type: actionTypes.CREATE_ACTIVITY_FAILED}),
  createActivitySuccess: () => ({type: actionTypes.CREATE_ACTIVITY_SUCCESS}),

  // CREATE PRODUCT
  createProduct: (payload: ProductModelCreateParams) => ({
    type: actionTypes.CREATE_PRODUCT,
    payload,
  }),
  createProductFailed: () => ({type: actionTypes.CREATE_PRODUCT_FAILED}),
  createProductSuccess: () => ({
    type: actionTypes.CREATE_PRODUCT_SUCCESS,
  }),

  // CREATE PRODUCT CATEGORY
  createProductCategory: (payload: ProductCategoryModelCreateParams) => ({
    type: actionTypes.CREATE_PRODUCT_CATEGORY,
    payload,
  }),
  createProductCategoryFailed: () => ({type: actionTypes.CREATE_PRODUCT_CATEGORY_FAILED}),
  createProductCategorySuccess: () => ({
    type: actionTypes.CREATE_PRODUCT_CATEGORY_SUCCESS,
  }),

  // ********************************************************** //
  // UPDATE ACTIONS
  // ********************************************************** //

  // UPDATE BOOKING
  updateBooking: (payload: BookingModelCreateParams, bookingCode: string) => ({
    type: actionTypes.UPDATE_BOOKING,
    payload: {booking: payload, code: bookingCode},
  }),
  updateBookingFailed: () => ({type: actionTypes.UPDATE_BOOKING_FAILED}),
  updateBookingSuccess: () => ({type: actionTypes.UPDATE_BOOKING_SUCCESS}),

  // UPDATE BUNDLE
  updateBundle: (payload: BookingModelCreateParams, bundleCode: string) => ({
    type: actionTypes.UPDATE_BUNDLE,
    payload: {booking: payload, code: bundleCode},
  }),
  updateBundleFailed: () => ({type: actionTypes.UPDATE_BUNDLE_FAILED}),
  updateBundleSuccess: () => ({type: actionTypes.UPDATE_BUNDLE_SUCCESS}),

  // UPDATE EVENT
  updateEvent: (payload: EventModelCreateParams, bookingCode: string) => ({
    type: actionTypes.UPDATE_EVENT,
    payload: {event: payload, code: bookingCode},
  }),
  updateEventFailed: () => ({type: actionTypes.UPDATE_EVENT_FAILED}),
  updateEventSuccess: () => ({type: actionTypes.UPDATE_EVENT_SUCCESS}),

  // UPDATE ACTIVITY
  updateActivity: (payload: EventModelCreateParams, bookingCode: string) => ({
    type: actionTypes.UPDATE_ACTIVITY,
    payload: {event: payload, code: bookingCode},
  }),
  updateActivityFailed: () => ({type: actionTypes.UPDATE_ACTIVITY_FAILED}),
  updateActivitySuccess: () => ({type: actionTypes.UPDATE_ACTIVITY_SUCCESS}),

  // UPDATE PRODUCT
  updateProduct: (payload: ProductModelCreateParams) => ({
    type: actionTypes.UPDATE_PRODUCT,
    payload,
  }),
  updateProductFailed: () => ({type: actionTypes.UPDATE_PRODUCT_FAILED}),
  updateProductSuccess: () => ({
    type: actionTypes.UPDATE_PRODUCT_SUCCESS,
  }),

  // UPDATE PRODUCT CATEGORY
  updateProductCategory: (payload: ProductModelCreateParams) => ({
    type: actionTypes.UPDATE_PRODUCT_CATEGORY,
    payload,
  }),
  updateProductCategoryFailed: () => ({type: actionTypes.UPDATE_PRODUCT_CATEGORY_FAILED}),
  updateProductCategorySuccess: () => ({
    type: actionTypes.UPDATE_PRODUCT_CATEGORY_SUCCESS,
  }),

  // ********************************************************** //
  // DELETE ACTIONS
  // ********************************************************** //

  // DELETE BOOKING
  deleteBooking: (bookingCode: string | string[]) => ({
    type: actionTypes.DELETE_BOOKING,
    payload: bookingCode,
  }),
  deleteBookingFailed: () => ({type: actionTypes.DELETE_BOOKING_FAILED}),
  deleteBookingSuccess: () => ({type: actionTypes.DELETE_BOOKING_SUCCESS}),

  // DELETE BUNDLE
  deleteBundle: (bundleCode: string | string[]) => ({
    type: actionTypes.DELETE_BUNDLE,
    payload: bundleCode,
  }),
  deleteBundleFailed: () => ({type: actionTypes.DELETE_BUNDLE_FAILED}),
  deleteBundleSuccess: () => ({type: actionTypes.DELETE_BUNDLE_SUCCESS}),

  // DELETE EVENT
  deleteEvent: () => ({type: actionTypes.DELETE_EVENT}),
  deleteEventFailed: () => ({type: actionTypes.DELETE_EVENT_FAILED}),
  deleteEventSuccess: () => ({type: actionTypes.DELETE_EVENT_SUCCESS}),

  // DELETE ACTIVITY
  deleteActivity: () => ({type: actionTypes.DELETE_ACTIVITY}),
  deleteActivityFailed: () => ({type: actionTypes.DELETE_ACTIVITY_FAILED}),
  deleteActivitySuccess: () => ({type: actionTypes.DELETE_ACTIVITY_SUCCESS}),

  // DELETE PRODUCT
  deleteProduct: (productCode: string) => ({
    type: actionTypes.DELETE_PRODUCT,
    payload: productCode,
  }),
  deleteProductFailed: () => ({type: actionTypes.DELETE_PRODUCT_FAILED}),
  deleteProductSuccess: () => ({type: actionTypes.DELETE_PRODUCT_SUCCESS}),

  // DELETE PRODUCT CATEGORY
  deleteProductCategory: (productCategoryCode: string) => ({
    type: actionTypes.DELETE_PRODUCT_CATEGORY,
    payload: productCategoryCode,
  }),
  deleteProductCategoryFailed: () => ({type: actionTypes.DELETE_PRODUCT_CATEGORY_FAILED}),
  deleteProductCategorySuccess: () => ({type: actionTypes.DELETE_PRODUCT_CATEGORY_SUCCESS}),

  // ********************************************************** //
  // SEARCH ACTIONS
  // ********************************************************** //
  // SEARCH TICKET
  searchTicket: () => ({type: actionTypes.SEARCH_TICKET}),
  searchTicketFailed: () => ({type: actionTypes.SEARCH_TICKET_FAILED}),
  searchTicketSuccess: (tickets: GlobalSearchModel<BookingModel>) => ({
    type: actionTypes.SEARCH_TICKET_SUCCESS,
    payload: {tickets},
  }),

  // SEARCH BOOKING
  searchBooking: () => ({type: actionTypes.SEARCH_BOOKING}),
  searchBookingFailed: () => ({type: actionTypes.SEARCH_BOOKING_FAILED}),
  searchBookingSuccess: (bookings: GlobalSearchModel<BookingModel>) => ({
    type: actionTypes.SEARCH_BOOKING_SUCCESS,
    payload: {bookings},
  }),

  // SEARCH BUNDLE
  searchBundle: () => ({type: actionTypes.SEARCH_BUNDLE}),
  searchBundleFailed: () => ({type: actionTypes.SEARCH_BUNDLE_FAILED}),
  searchBundleSuccess: (bundles: GlobalSearchModel<BookingModel>) => ({
    type: actionTypes.SEARCH_BUNDLE_SUCCESS,
    payload: {bundles},
  }),

  // SEARCH BOOKING PRODUCT
  searchBookingProduct: () => ({
    type: actionTypes.SEARCH_BOOKING_PRODUCT,
  }),
  searchBookingProductFailed: () => ({type: actionTypes.SEARCH_BOOKING_PRODUCT_FAILED}),
  searchBookingProductSuccess: (products: GlobalSearchModel<ProductModel>) => ({
    type: actionTypes.SEARCH_BOOKING_PRODUCT_SUCCESS,
    payload: {products},
  }),

  // SEARCH BOOKING BUNDLE
  searchBookingBundle: () => ({
    type: actionTypes.SEARCH_BOOKING_BUNDLE,
  }),
  searchBookingBundleFailed: () => ({type: actionTypes.SEARCH_BOOKING_BUNDLE_FAILED}),
  searchBookingBundleSuccess: (bundles: GlobalSearchModel<BundleModel>) => ({
    type: actionTypes.SEARCH_BOOKING_BUNDLE_SUCCESS,
    payload: {bundles},
  }),

  // SEARCH EVENT
  searchEvent: () => ({type: actionTypes.SEARCH_EVENT}),
  searchEventFailed: () => ({type: actionTypes.SEARCH_EVENT_FAILED}),
  searchEventSuccess: (events: GlobalSearchModel<EventModel>) => ({
    type: actionTypes.SEARCH_EVENT_SUCCESS,
    payload: {events},
  }),

  // SEARCH ACTIVITY
  searchActivity: () => ({type: actionTypes.SEARCH_ACTIVITY}),
  searchActivityFailed: () => ({type: actionTypes.SEARCH_ACTIVITY_FAILED}),
  searchActivitySuccess: (activities: GlobalSearchModel<EventModel>) => ({
    type: actionTypes.SEARCH_ACTIVITY_SUCCESS,
    payload: {activities},
  }),

  // SEARCH ACTIVITY BY CODE
  searchActivityByCode: (code: string) => ({type: actionTypes.SEARCH_EVENT_BY_CODE, payload: code}),
  searchActivityByCodeFailed: () => ({type: actionTypes.SEARCH_EVENT_BY_CODE_FAILED}),
  searchActivityByCodeSuccess: (event: EventModel) => ({
    type: actionTypes.SEARCH_EVENT_BY_CODE_SUCCESS,
    payload: {event},
  }),

  // SEARCH EVENT BY CODE
  searchEventByCode: (code: string) => ({type: actionTypes.SEARCH_EVENT_BY_CODE, payload: code}),
  searchEventByCodeFailed: () => ({type: actionTypes.SEARCH_EVENT_BY_CODE_FAILED}),
  searchEventByCodeSuccess: (event: EventModel) => ({
    type: actionTypes.SEARCH_EVENT_BY_CODE_SUCCESS,
    payload: {event},
  }),

  // SEARCH EVENT TREE
  searchEventTree: () => ({type: actionTypes.SEARCH_EVENT_TREE}),
  searchEventTreeFailed: () => ({type: actionTypes.SEARCH_EVENT_TREE_FAILED}),
  searchEventTreeSuccess: (eventTree: EventModel[]) => ({
    type: actionTypes.SEARCH_EVENT_TREE_SUCCESS,
    payload: {eventTree},
  }),

  // SEARCH PRODUCT
  searchProduct: () => ({
    type: actionTypes.SEARCH_PRODUCT,
  }),
  searchProductFailed: () => ({type: actionTypes.SEARCH_PRODUCT_FAILED}),
  searchProductSuccess: (products: GlobalSearchModel<ProductModel>) => ({
    type: actionTypes.SEARCH_PRODUCT_SUCCESS,
    payload: {products},
  }),

  // SEARCH PRODUCT CATEGORY
  searchProductCategory: () => ({
    type: actionTypes.SEARCH_PRODUCT_CATEGORY,
  }),
  searchProductCategoryFailed: () => ({type: actionTypes.SEARCH_PRODUCT_CATEGORY_FAILED}),
  searchProductCategorySuccess: (productCategories: GlobalSearchModel<ProductCategoryModel>) => ({
    type: actionTypes.SEARCH_PRODUCT_CATEGORY_SUCCESS,
    payload: {productCategories},
  }),

  // SEARCH TICKET LOG
  searchTicketLog: () => ({
    type: actionTypes.SEARCH_TICKET_LOG,
  }),
  searchTicketLogFailed: () => ({type: actionTypes.SEARCH_TICKET_FAILED}),
  searchTicketLogSuccess: (ticketLogs: GlobalSearchModel<TicketLogModel>) => ({
    type: actionTypes.SEARCH_TICKET_LOG_SUCCESS,
    payload: {ticketLogs},
  }),

  // SEARCH ACTIVITY BY CODE
  searchTimezoneByCode: (code: string) => ({
    type: actionTypes.SEARCH_TIMEZONE_BY_CODE,
    payload: code,
  }),
  searchTimezoneByCodeFailed: () => ({type: actionTypes.SEARCH_TIMEZONE_BY_CODE_FAILED}),
  searchTimezoneByCodeSuccess: (timezone: TimeZoneModel) => ({
    type: actionTypes.SEARCH_TIMEZONE_BY_CODE_SUCCESS,
    payload: {timezone},
  }),

  resetTimezone: () => ({
    type: actionTypes.SEARCH_TIMEZONE_BY_CODE_SUCCESS,
  }),
}

// AFTER EFFECTS
export function* saga() {
  // BUNDLE
  yield takeLatest(actionTypes.SEARCH_TICKET, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters.ticket)
      const {data} = yield GetTickets(filter)
      yield put(actions.searchTicketSuccess(data))
    } catch (e) {
      yield put(actions.searchBundleFailed())
    }
  })

  yield takeEvery([actionTypes.FULFILL_TICKET_SUCCESS], function* refreshEvent() {
    yield put(actions.searchTicket())
  })

  // EVENT
  yield takeLatest(actionTypes.SEARCH_EVENT, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters.event)
      const {data} = yield GetEvents(filter)
      yield put(actions.searchEventSuccess(data))
    } catch (e) {
      yield put(actions.searchEventFailed())
    }
  })

  yield takeLatest(actionTypes.SEARCH_EVENT_TREE, function* refreshEvent() {
    try {
      const {data} = yield GetEventsTree()
      yield put(actions.searchEventTreeSuccess(data))
    } catch (e) {
      yield put(actions.searchEventTreeFailed())
    }
  })

  // EVENT BY CODE
  yield takeLatest(
    actionTypes.SEARCH_EVENT_BY_CODE,
    function* refreshEvent(action: ActionWithPayload<string>) {
      try {
        const {data} = yield GetEventByCode(action.payload)
        yield put(actions.searchEventByCodeSuccess(data))
      } catch (e) {
        yield put(actions.searchEventByCodeFailed())
      }
    }
  )

  // CREATE EVENT
  yield takeEvery(
    actionTypes.CREATE_EVENT,
    function* createEvent(action: ActionWithPayload<FormData>) {
      try {
        yield PostEvent(action.payload)
        yield put(actions.createEventSuccess())
      } catch (e) {
        yield put(actions.createEventFailed())
      }
    }
  )

  // UPDATE EVENT
  yield takeEvery(
    actionTypes.UPDATE_EVENT,
    function* createEvent(action: ActionWithPayload<{booking: FormData; code: string}>) {
      try {
        yield PutEvent(action.payload.booking, action.payload.code)
        yield put(actions.updateEventSuccess())
      } catch (e) {
        yield put(actions.updateEventFailed())
      }
    }
  )

  yield takeEvery(
    [
      actionTypes.UPDATE_EVENT_SUCCESS,
      actionTypes.DELETE_EVENT_SUCCESS,
      actionTypes.CREATE_EVENT_SUCCESS,
    ],
    function* refreshEvent() {
      try {
        yield put(actions.searchEvent())
        const {tree} = yield GetEventsTree()
        yield put(actions.searchEventTreeSuccess(tree))
      } catch (e) {
        yield put(actions.searchEventFailed())
      }
    }
  )

  // ACTIVITY
  yield takeLatest(actionTypes.SEARCH_ACTIVITY, function* refreshActivity() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters.activity)
      const {data} = yield GetActivities(filter)
      yield put(actions.searchActivitySuccess(data))
    } catch (e) {
      yield put(actions.searchBookingFailed())
    }
  })

  // ACTIVITY BY CODE
  yield takeLatest(
    actionTypes.SEARCH_ACTIVITY_BY_CODE,
    function* refreshEvent(action: ActionWithPayload<string>) {
      try {
        const {data} = yield GetEventByCode(action.payload)
        yield put(actions.searchEventByCodeSuccess(data))
      } catch (e) {
        yield put(actions.searchEventByCodeFailed())
      }
    }
  )

  // CREATE ACTIVITY
  yield takeEvery(
    actionTypes.CREATE_ACTIVITY,
    function* createEvent(action: ActionWithPayload<FormData>) {
      try {
        yield PostActivity(action.payload)
        yield put(actions.createEventSuccess())
      } catch (e) {
        yield put(actions.createEventFailed())
      }
    }
  )

  // UPDATE ACTIVITY
  yield takeEvery(
    actionTypes.UPDATE_ACTIVITY,
    function* createEvent(action: ActionWithPayload<{booking: FormData; code: string}>) {
      try {
        yield PutActivity(action.payload.booking, action.payload.code)
        yield put(actions.updateEventSuccess())
      } catch (e) {
        yield put(actions.updateEventFailed())
      }
    }
  )

  yield takeEvery(
    [
      actionTypes.UPDATE_ACTIVITY_SUCCESS,
      actionTypes.DELETE_ACTIVITY_SUCCESS,
      actionTypes.CREATE_ACTIVITY_SUCCESS,
    ],
    function* refreshEvent() {
      yield put(actions.searchActivity())
    }
  )

  // PRODUCT
  yield takeLatest(actionTypes.SEARCH_PRODUCT, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters.product)
      const {data} = yield GetProducts(filter)
      yield put(actions.searchProductSuccess(data))
    } catch (e) {
      yield put(actions.searchProductFailed())
    }
  })

  yield takeEvery(
    [
      actionTypes.CREATE_PRODUCT_SUCCESS,
      actionTypes.UPDATE_PRODUCT_SUCCESS,
      actionTypes.DELETE_PRODUCT_SUCCESS,
    ],
    function* refreshEvent() {
      try {
        yield put(actions.searchProduct())
      } catch (e) {
        yield put(actions.searchProductFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.CREATE_PRODUCT,
    function* afterEffectSaga(action: ActionWithPayload<ProductModelCreateParams>) {
      try {
        yield PostProduct(action.payload)
        yield put(actions.createProductSuccess())
      } catch (e) {
        yield put(actions.createProductFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.DELETE_PRODUCT,
    function* afterEffectSaga(action: ActionWithPayload<string | []>) {
      try {
        const productCode = action.payload
        if (Array.isArray(productCode)) {
          yield DeleteProducts(productCode)
        } else {
          yield DeleteProducts([productCode])
        }
        yield put(actions.deleteProductSuccess())
      } catch (e) {
        yield put(actions.deleteProductFailed())
      }
    }
  )

  // BUNDLE
  yield takeLatest(actionTypes.SEARCH_BUNDLE, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters.bundle)
      const {data} = yield GetBundles(filter)
      yield put(actions.searchBundleSuccess(data))
    } catch (e) {
      yield put(actions.searchBundleFailed())
    }
  })

  yield takeEvery(
    [
      actionTypes.CREATE_BUNDLE_SUCCESS,
      actionTypes.UPDATE_BUNDLE_SUCCESS,
      actionTypes.DELETE_BUNDLE_SUCCESS,
    ],
    function* refreshEvent() {
      try {
        yield put(actions.searchBundle())
      } catch (e) {
        yield put(actions.searchBundleFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.CREATE_BUNDLE,
    function* afterEffectSaga(action: ActionWithPayload<BundleModelCreateParams>) {
      try {
        yield PostBundle(action.payload)
        yield put(actions.createProductSuccess())
      } catch (e) {
        yield put(actions.createProductFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.DELETE_BUNDLE,
    function* afterEffectSaga(action: ActionWithPayload<string | string[]>) {
      try {
        const productCode = action.payload
        if (Array.isArray(productCode)) {
          yield DeleteBundles(productCode)
        } else {
          yield DeleteBundles([productCode])
        }
        yield put(actions.deleteBundleSuccess())
      } catch (e) {
        yield put(actions.deleteBundleFailed())
      }
    }
  )

  // PRODUCT CATEGORY
  yield takeLatest(actionTypes.SEARCH_PRODUCT_CATEGORY, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['product-category'])
      const {data} = yield GetProductCategories(filter)
      yield put(actions.searchProductCategorySuccess(data))
    } catch (e) {
      yield put(actions.searchProductCategoryFailed())
    }
  })

  yield takeEvery(
    [
      actionTypes.CREATE_PRODUCT_CATEGORY_SUCCESS,
      actionTypes.UPDATE_PRODUCT_CATEGORY_SUCCESS,
      actionTypes.DELETE_PRODUCT_CATEGORY_SUCCESS,
    ],
    function* refreshEvent() {
      yield put(actions.searchProductCategory())
    }
  )

  yield takeLatest(
    actionTypes.CREATE_PRODUCT_CATEGORY,
    function* afterEffectSaga(action: ActionWithPayload<ProductCategoryModelCreateParams>) {
      try {
        yield PostProductCategory(action.payload)
        yield put(actions.createProductCategorySuccess())
      } catch (e) {
        yield put(actions.createProductCategoryFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.DELETE_PRODUCT_CATEGORY,
    function* afterEffectSaga(action: ActionWithPayload<string | []>) {
      try {
        const productCategoryCode = action.payload
        if (Array.isArray(productCategoryCode)) {
          yield DeleteProductCategories(productCategoryCode)
        } else {
          yield DeleteProductCategories([productCategoryCode])
        }
        yield put(actions.deleteProductCategorySuccess())
      } catch (e) {
        yield put(actions.deleteProductCategoryFailed())
      }
    }
  )

  // BOOKING
  yield takeLatest(actionTypes.SEARCH_BOOKING, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['booking'])
      const {data} = yield GetBookings(filter)
      yield put(actions.searchBookingSuccess(data))
    } catch (e) {
      yield put(actions.searchBookingFailed())
    }
  })

  yield takeLatest(actionTypes.SEARCH_BOOKING_PRODUCT, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['booking-product'])
      const {data} = yield GetBookingProducts(filter)
      yield put(actions.searchBookingProductSuccess(data))
    } catch (e) {
      yield put(actions.searchBookingProductFailed())
    }
  })

  yield takeLatest(actionTypes.SEARCH_BOOKING_BUNDLE, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['booking-bundle'])
      const {data} = yield GetBookingBundles(filter)
      yield put(actions.searchBookingBundleSuccess(data))
    } catch (e) {
      yield put(actions.searchBookingBundleFailed())
    }
  })

  yield takeEvery(
    [
      actionTypes.CREATE_BOOKING_SUCCESS,
      actionTypes.UPDATE_BOOKING_SUCCESS,
      actionTypes.DELETE_BOOKING_SUCCESS,
    ],
    function* refreshEvent() {
      yield put(actions.searchBooking())
    }
  )

  yield takeLatest(
    actionTypes.UPDATE_BOOKING,
    function* afterEffectSaga(
      action: ActionWithPayload<{booking: BookingModelCreateParams; code: string}>
    ) {
      try {
        yield PutBooking(action.payload.booking, action.payload.code)
        yield put(actions.deleteBookingSuccess())
      } catch (e) {
        yield put(actions.deleteBookingFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.DELETE_BOOKING,
    function* afterEffectSaga(action: ActionWithPayload<string | string[]>) {
      try {
        const productCode = action.payload
        if (Array.isArray(productCode)) {
          yield DeleteProducts(productCode)
        } else {
          yield DeleteProducts([productCode])
        }
        yield put(actions.deleteProductSuccess())
      } catch (e) {
        yield put(actions.deleteProductFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.CREATE_BOOKING,
    function* afterEffectSaga(action: ActionWithPayload<BookingModelCreateParams>) {
      try {
        yield PostBooking(action.payload)
        yield put(actions.deleteProductSuccess())
      } catch (e) {
        yield put(actions.deleteProductFailed())
      }
    }
  )

  // BOOKING
  yield takeLatest(actionTypes.SEARCH_TICKET_LOG, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['ticket-log'])
      const {data} = yield GetTicketLogs(filter)
      yield put(actions.searchTicketLogSuccess(data))
    } catch (e) {
      yield put(actions.searchTicketLogFailed())
    }
  })

  // TIMEZONE BY CODE
  yield takeLatest(
    actionTypes.SEARCH_TIMEZONE_BY_CODE,
    function* refreshEvent(action: ActionWithPayload<string>) {
      try {
        const {data} = yield GetTimezoneByCode(action.payload)
        yield put(actions.searchTimezoneByCodeSuccess(data))
      } catch (e) {
        yield put(actions.searchTimezoneByCodeFailed())
      }
    }
  )
}
