import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'

export const DashboardWrapper = () =>
  //TODO: update
  {
    return (
      <>
            <Toolbar title='Dashboard'>
            </Toolbar>
      </>
    )
  }
