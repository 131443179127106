import moment from 'moment'
import {Time} from './Time'

export abstract class DateUtil {
  public static getDateFromApiString = (dateString: string) => {
    return new Date(dateString)
  }

  public static convertDateToApiString = (date: Date) => {
    return date.toISOString()
  }

  public static getNewDateWithTime = (time: Time, date: Date) => {
    const dateCopy = new Date(date)
    dateCopy.setHours(time.getHours())
    dateCopy.setMinutes(time.getMinutes())
    dateCopy.setSeconds(time.getSeconds())
    return dateCopy
  }

  public static formatToDateString = (date: Date | string) => {
    return moment(date).format('MMM DD, YYYY')
  }

  public static formatToTimeString = (date: Date | string, ampm?: boolean) => {
    if (ampm) {
      return moment(date).format('LT')
    }
    return moment(date).format('HH:mm:ss')
  }

  public static formatToDateTimeString = (date: Date | string) => {
    return `${DateUtil.formatToDateString(date)} ${DateUtil.formatToTimeString(date)}`
  }

  public static convertDateTimeToVenueTimezoneToUTC = (
    dateTimeString: string,
    timezone: string
  ) => {
    const convertedDateTime = moment.tz(`${dateTimeString}`, timezone)
    return convertedDateTime.utc().format('YYYY-MM-DD HH:mm:ss')
  }

  public static convertDateTimeToVenueTimezone = (
    dateTimeString: string,
    timezone: string,
    format?: string
  ) => {
    return timezone
      ? moment.tz(`${dateTimeString}`, timezone).format(format || 'YYYY-MM-DD HH:mm:ss')
      : moment(dateTimeString).format(format || 'YYYY-MM-DD HH:mm:ss')
  }

  public static formatToTimeStringBaseInTimezone = (
    date: Date | string,
    timezone: string,
    ampm?: boolean
  ) => {
    const convertedTime = timezone ? moment.tz(`${date}`, timezone) : date
    if (ampm) {
      return moment(convertedTime).format('LT')
    }
    return moment(convertedTime).format('HH:mm:ss')
  }
}
