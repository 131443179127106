import {useCallback, useState} from 'react'
import {useLoadingState} from '../../../../../../components/hooks/useLoadingState'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {ProductModel} from '../../../../../../models/ems/ProductModel'
import {LocationModel} from '../../../../../../models/acs/LocationModel'
import {ActivityModel} from '../../../../../../models/ems/ActivityModel'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {FilterModel} from '../../../../../../models/FilterModel'
import {
  GetEventParents,
  GetEventsTree,
  GetLocationsByProductCode,
  GetProductTree,
  GetProducts,
} from '../../../redux/EmsCRUD'
import {GetCustomers, SearchVouchers} from '../../../../svc/redux/SvcCRUD'
import {ApiTree} from '../../../../../../utils/Tree/ApiTree'
import {VoucherModel} from '../../../../../../models/svc/VoucherModel'

export interface UseReservationProductDataParams {
  eventCode?: string | null
  productCode?: string | null
}

export const useReservationProductData = ({
  eventCode,
  productCode,
}: UseReservationProductDataParams) => {
  const {setIsLoading, isLoading} = useLoadingState()
  const [locationSearchResults, setLocationSearchResults] = useState<LocationModel[]>()
  const [productSearchResults, setProductSearchResults] =
    useState<GlobalSearchModel<ProductModel>>()
  const [productTree, setProductTree] = useState<ProductModel[]>([])
  const [products, setProducts] = useState<ProductModel[]>([])
  const [activities, setActivities] = useState<ActivityModel[]>([])
  const [customers, setCustomers] = useState<GlobalSearchModel<CustomerModel>>()
  const [events, setEvents] = useState<EventModel[]>([])
  const [voucherSearchResults, setVoucherSearchResults] =
    useState<GlobalSearchModel<VoucherModel>>()
  const {pushError} = useAlerts()

  const searchVouchers = useCallback(
    async (filter?: FilterModel) => {
      try {
        const response = await SearchVouchers({
          ...filter,
          filters: {
            ...filter?.filters,
            isTemplate: true,
            event: eventCode || undefined,
          },
        })
        setVoucherSearchResults(response.data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [eventCode, pushError]
  )

  const removeNoSeatMapLocations = useCallback((tree: LocationModel[]): any => {
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.children && node.children.length) {
        removeNoSeatMapLocations(node.children)
      } else {
        if (!node.seatMap) {
          tree.splice(i, 1)
          removeNoSeatMapLocations(tree)
        }
      }
    }
    return tree
  }, [])

  const getLocations = useCallback(async () => {
    try {
      if (productCode) {
        const {data} = await GetLocationsByProductCode(productCode)
        const mappedLocations = removeNoSeatMapLocations(data)
        setLocationSearchResults(mappedLocations)
      }
    } catch (e: any) {
      pushError(e)
    }
  }, [productCode, pushError])

  const searchCustomers = useCallback(
    async (filter?: FilterModel) => {
      try {
        const response = await GetCustomers({
          ...filter,
          filters: {
            ...filter?.filters,
            type: ['reseller', 'partner'],
          },
        })
        setCustomers(response.data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [pushError]
  )

  const searchProducts = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetProducts({
          ...filter,
          filters: {
            ...filter?.filters,
            event: eventCode || undefined,
          },
        })
        setProductSearchResults({
          data: data.data,
          limit: 1000,
          page: 1,
          total: data.data.length,
        })
      } catch (e: any) {
        pushError(e)
      }
    },
    [eventCode, pushError]
  )

  const getAllProductsByEvent = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetProductTree({
          ...filter,
          filters: {
            ...filter?.filters,
            event: eventCode || undefined,
          },
        })
        setProductTree(data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [eventCode, pushError]
  )

  const refreshProductsList = useCallback(
    async (filter?: FilterModel) => {
      if (eventCode) {
        const doneLoading = setIsLoading('products')
        const newProdFilters = {
          ...filter,
          filters: {
            ...filter?.filters,
            event: eventCode,
          },
        }
        try {
          const {data} = await GetProducts(newProdFilters)
          setProducts(data.data)
          setProductSearchResults({
            data: data.data,
            limit: 1000,
            page: 1,
            total: data.data.length,
          })
        } catch (e: any) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [eventCode, pushError, setIsLoading]
  )

  const resetActivityFieldItems = useCallback(async () => {
    const doneLoading = setIsLoading('activities')
    try {
      const activities: ActivityModel[] = []
      const response = await GetEventsTree()
      const eventTree = new ApiTree(response.data)
      eventTree.forEachNode((node) => {
        if (!node.children) {
          activities.push(node)
        }
      })
      setActivities(activities)
    } catch (e: any) {
      pushError(e)
    } finally {
      doneLoading()
    }
  }, [pushError, setIsLoading])

  const resetEventFieldItems = useCallback(async () => {
    const doneLoading = setIsLoading('events')
    try {
      const {data} = await GetEventParents()
      setEvents(data)
    } catch (e: any) {
      pushError(e)
    } finally {
      doneLoading()
    }
  }, [pushError, setIsLoading])

  return {
    events,
    activities,
    products,
    customers,
    searchCustomers,
    isLoading,
    resetEventFieldItems,
    resetActivityFieldItems,
    refreshProductsList,
    getLocations,
    locationSearchResults,
    searchProducts,
    productSearchResults,
    searchVouchers,
    voucherSearchResults,
    getAllProductsByEvent,
    productTree,
  }
}
