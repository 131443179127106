import { Link, useLocation } from "react-router-dom"
import { NavigationSectionMenuItem } from "../../../../../config/drawer"
import { useDrawerRef } from "../../../Drawer/useDrawerRef"
import { useLayoutPortalData } from "../../DefaultLayout/LayoutPortalProvider"
import { useEffect } from "react"
import { MenuComponent } from "../../../../../_metronic/assets/ts/components"
import clsx from 'clsx'
import { SolaasLabMenuHeaderLogo } from "../../../../../config/logos"
import { NavHeader } from "../../DefaultLayout/NavHeader/NavHeader"
import { KTSVG } from "../../../../../_metronic/helpers"
import { NavDrawer } from "../../DefaultLayout/NavDrawer/NavDrawer"


export interface HeaderProps {
    menus: NavigationSectionMenuItem[]
  }
  
  export const Header = ({menus}: HeaderProps) => {
    const [navDrawer, setNavDrawer] = useDrawerRef()
    const {pathname} = useLocation()
    const {setToolbar, setHeaderContent, setHeaderControls} = useLayoutPortalData()
  
    useEffect(() => {
      MenuComponent.reinitialization()
    }, [pathname])
  
    return (
      <>
        <div id='kt_header' className={clsx('header', 'align-items-stretch')}>
          <div
            className={clsx(
              'd-flex px-3 w-100 align-items-stretch justify-content-between flex-shrink-0'
            )}
          >
            <div className='d-flex align-items-center'>
              <Link style={{display: 'contents'}} to='/'>
                <img
                  alt='Home'
                  style={{maxWidth: '15rem'}}
                  src={SolaasLabMenuHeaderLogo.src}
                  className='w-100 p-3'
                />
              </Link>
            </div>
            <div className='flex-grow-1 d-none d-lg-block overflow-hidden'>
              <NavHeader menus={menus} />
            </div>
            <div className='d-flex align-items-stretch justify-content-between'>
              <div ref={setHeaderContent}></div>
              <div className='d-flex align-items-stretch flex-shrink-0 pe-5'>
                <div ref={setHeaderControls}></div>
                <div
                  className='d-flex align-items-center d-lg-none ms-2 me-n3'
                  title='Show header menu'
                >
                  <button
                    className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                    onClick={navDrawer?.show}
                  >
                    <KTSVG path='/media/icons/duotone/Text/Toggle-Right.svg' className='svg-icon-1' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={setToolbar}></div>
        <NavDrawer ref={setNavDrawer} menus={menus} />
      </>
    )
  }
  