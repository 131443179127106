import axios from "axios"
import { AuthModel } from "../../../models/admin/AuthModel"
import { FilterModel } from "../../../models/FilterModel"
import { CreateSystemUserParams, SystemUserModel } from "../../../models/admin/SystemUserModel"
import { GlobalSearchModel } from "../../../models/GlobalSearchModel"
import { OrganizationModel, OrganizationModelCreateFormData } from "../../../models/admin/OrganizationModel"
import { AddOrganizationApplicationParams, ApplicationModel, UpdateOrganizationApplicationParams } from "../../../models/admin/ApplicationModel"
import { CreateSharewarePackageParams, SharewarePackageModel } from "../../../models/admin/SharewarePackageModel"
import { CreateOrganizationRoleParams, RoleModel } from "../../../models/admin/RoleModel"
import { CreateOrganizationUserParams, OrganizationUserModel } from "../../../models/admin/OrganizationUserModel"
import { UserLogModel } from "../../../models/admin/UserLogModel"

const ADMIN_AUTH_BASE_PATH = '/auth'
const ADMIN_BASE_PATH = '/admin-user'
const LOG_PATH = '/log/list'
const ORGANIZATION_BASE_PATH = '/organization'
const SHAREWARE_BASE_PATH = '/shareware'
const SHAREWARE_PACKAGE_BASE_PATH = '/shareware-package'
const ORGANIZATION_ROLE_BASE_PATH = '/role'
const APPLICATION_BASE_PATH = '/application'
const FEATURE_BASE_PATH = '/feature'
const ORGANIZATION_USER_BASE_PATH = '/user'

export interface EmailTypeParams {
  email: string
  type: string
}

export interface CustomerCodeParams {
  customerCode: string
}

// LOGIN
export const AuthLogin = (username: string, password: string) =>
  axios.post<AuthModel>(`${ADMIN_AUTH_BASE_PATH}/login`, {username, password})

export const RequestForgottenPassword = (email: string) =>
  axios.post<AuthModel>(`${ADMIN_AUTH_BASE_PATH}/forgot-password`, {email})

export const VerifyAdminAuthToken = () =>
  axios.get<AuthModel>(`${ADMIN_AUTH_BASE_PATH}/verify-token`)

export const ResetPassword = (password: string) =>
  axios.post(`${ADMIN_AUTH_BASE_PATH}/reset`, {password})

export const validateMfa = (mfa: string, authToken: string) =>
  axios.post(
    `${ADMIN_AUTH_BASE_PATH}/validate-mfa`,
    {mfa},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

export const validateHash = (hash: string) =>
  axios.post(`${ADMIN_AUTH_BASE_PATH}/validate-hash`, {hash})

export const loginWithHash = (username: string, password: string, hash: string) =>
  axios.post(`${ADMIN_AUTH_BASE_PATH}/login-hash`, {username, password, hash})


//SYSTEM
export const GetSystemLogs = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<UserLogModel>>(LOG_PATH, filter)

export const GetSystemUserByCode = (code: string) =>
  axios.get<SystemUserModel>(`${ADMIN_BASE_PATH}/${code}`)

export const PostSystemUser = (data: CreateSystemUserParams) =>
  axios.post(`${ADMIN_BASE_PATH}`, data)

export const PutSystemUser = (data: CreateSystemUserParams, userCode: string) =>
  axios.put(`${ADMIN_BASE_PATH}/${userCode}`, data)

export const DeleteSystemUser = (userCode: string[] | string) =>
  axios.delete(`${ADMIN_BASE_PATH}`, {
    data: {codes: Array.isArray(userCode) ? userCode : [userCode]},
  })

export const SearchSystemUserList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<SystemUserModel>>(
    `${ADMIN_BASE_PATH}/list`,
    filter
  )

export const ActivateSystemUsers = (userCodes: string[]) =>
  axios.post(`${ADMIN_BASE_PATH}/send-credentials`, {codes: userCodes})

export const ResetSystemUserMFA = (userCodes: string[]) =>
  axios.post(`${ADMIN_BASE_PATH}/reset-mfa`, {
    codes: userCodes,
  })

export const PostSystemUserSmartPin = (smartPin: string, codes: string[]) =>
  axios.post(`${ADMIN_BASE_PATH}/smart-pin`, {
    smartPin,
    codes,
  })

//ORGANIZATION
export const GetOrganizationByCode = (code: string) =>
  axios.get<OrganizationModel>(`${ORGANIZATION_BASE_PATH}/${code}`)

export const PostOrganization = (data: OrganizationModelCreateFormData) =>
  axios.post(`${ORGANIZATION_BASE_PATH}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const PutOrganization = (data: OrganizationModelCreateFormData, code: string) =>
  axios.put(`${ORGANIZATION_BASE_PATH}/${code}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })


export const DeleteOrganization = (userCode: string[] | string) =>
  axios.delete(`${ORGANIZATION_BASE_PATH}`, {
    data: {codes: Array.isArray(userCode) ? userCode : [userCode]},
  })

export const SearchOrganizationList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<OrganizationModel>>(
    `${ORGANIZATION_BASE_PATH}/list`,
    filter
  )

//APPLICATION
export const GetAllApplicationFeaturesByOrganizanition = (code: string) =>
  axios.get<Array<ApplicationModel>>(`${APPLICATION_BASE_PATH}/${FEATURE_BASE_PATH}/${code}`)


export const GetOrganizationApplicationByCode = (code: string) =>
  axios.get<Array<ApplicationModel>>(`${APPLICATION_BASE_PATH}/${code}`)

export const GetOrganizationApplicationDetailsByKey = (key: string) =>
  axios.get<ApplicationModel>(`${APPLICATION_BASE_PATH}/details/${key}`)

export const AddSharewareToOrganization = (data: AddOrganizationApplicationParams, code: string) =>
  axios.post(`${APPLICATION_BASE_PATH}/${code}`, data)

export const PutOrganizationApplication = (data: UpdateOrganizationApplicationParams, key: string) =>
  axios.put(`${APPLICATION_BASE_PATH}/${key}`, data)

export const RemoveApplicationFromOrganization = (applicationCode: string[] | string, organizationCode: string) =>
  axios.delete(`${APPLICATION_BASE_PATH}/${organizationCode}`, {
    data: {applicationKeys: Array.isArray(applicationCode) ? applicationCode : [applicationCode]},
  })

export const SearchSharewareList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ApplicationModel>>(
    `${APPLICATION_BASE_PATH}/list`,
    filter
  )
export const RegenerateApplication = (keys: string[]) =>
  axios.post<GlobalSearchModel<ApplicationModel>>(
    `${APPLICATION_BASE_PATH}/regenerate`,
    {
       applicationKeys: Array.isArray(keys) ? keys : [keys],
    }
  )



//SHAREWARE
export const GetAllApplication = () =>
  axios.get<ApplicationModel[]>(`${SHAREWARE_BASE_PATH}`)

export const SearchSharewarePackageList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<SharewarePackageModel>>(`${SHAREWARE_PACKAGE_BASE_PATH}/list`, filter)

export const GetAllRemainingSharewareByOrganizationCode = (code: string) =>
  axios.get<Array<ApplicationModel>>(`${SHAREWARE_BASE_PATH}/remaining/${code}`)


export const GetSharewarePackageByCode = (code: string) =>
  axios.get<SharewarePackageModel>(`${SHAREWARE_PACKAGE_BASE_PATH}/${code}`)

export const PostSharewarePackage = (data: CreateSharewarePackageParams) =>
  axios.post(`${SHAREWARE_PACKAGE_BASE_PATH}`, data)

export const PutSharewarePackage = (data: CreateSharewarePackageParams, code: string) =>
  axios.put(`${SHAREWARE_PACKAGE_BASE_PATH}/${code}`, data)

export const DeleteSharewarePackage = (code: string[] | string) =>
  axios.delete(`${SHAREWARE_PACKAGE_BASE_PATH}`, {
    data: {codes: Array.isArray(code) ? code : [code]},
  })

//ORGANIZATION ROLES

export const GetAllRolesByOrganizationCode = (code: string) =>
  axios.get<Array<RoleModel>>(`${ORGANIZATION_ROLE_BASE_PATH}/all/${code}`)

export const GetOrganizationRoleByCode = (code: string) =>
  axios.get<RoleModel>(`${ORGANIZATION_ROLE_BASE_PATH}/${code}`)

export const PostOrganizationRole = (data: CreateOrganizationRoleParams) =>
  axios.post(`${ORGANIZATION_ROLE_BASE_PATH}`, data)

export const PutOrganizationRole = (data: CreateOrganizationRoleParams, code: string) =>
  axios.put(`${ORGANIZATION_ROLE_BASE_PATH}/${code}`, data)

export const DeleteOrganizationRole = (code: string[] | string) =>
  axios.delete(`${ORGANIZATION_ROLE_BASE_PATH}`, {
    data: {codes: Array.isArray(code) ? code : [code]},
  })

export const SearchOrganizationRoleList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<RoleModel>>(
    `${ORGANIZATION_ROLE_BASE_PATH}/list`,
    filter
  )

//ORGANIZATION USER

export const GetOrganizationUserByCode = (code: string) =>
  axios.get<OrganizationUserModel>(`${ORGANIZATION_USER_BASE_PATH}/${code}`)

export const PostOrganizationUser = (data: CreateOrganizationUserParams) =>
  axios.post(`${ORGANIZATION_USER_BASE_PATH}`, data)

export const PutOrganizationUser = (data: CreateOrganizationUserParams, userCode: string) =>
  axios.put(`${ORGANIZATION_USER_BASE_PATH}/${userCode}`, data)

export const DeleteOrganizationUser = (userCode: string[] | string) =>
  axios.delete(`${ORGANIZATION_USER_BASE_PATH}`, {
    data: {codes: Array.isArray(userCode) ? userCode : [userCode]},
  })

export const SearchOrganizationUserList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<OrganizationUserModel>>(
    `${ORGANIZATION_USER_BASE_PATH}/list`,
    filter
  )

export const ActivateOrganizationUsers = (userCodes: string[]) =>
  axios.post(`${ORGANIZATION_USER_BASE_PATH}/send-credentials`, {codes: userCodes})

export const ResetOrganizationUserMFA = (userCodes: string[]) =>
  axios.post(`${ORGANIZATION_USER_BASE_PATH}/reset-mfa`, {
    codes: userCodes,
  })

export const PostOrganizationUserSmartPin = (smartPin: string, codes: string[]) =>
  axios.post(`${ORGANIZATION_USER_BASE_PATH}/smart-pin`, {
    smartPin,
    codes,
  })