import {FormikContextType} from 'formik'
import {useSelector} from 'react-redux'
import {
  BookingWizardFinalSeatsStepFormValues,
  BulkConsolidatedFormValues,
} from '../../../../../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {RootState} from '../../../../../../../../setup'
import {BookingWizardSharedConsolidatedFinaleStep} from '../../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardSharedConsolidatedFinaleStep'

export interface BookingWizardBulkConsolidatedFinaleStepProps<
  T extends BookingWizardFinalSeatsStepFormValues
> {
  formik: FormikContextType<T>
}

export const BookingWizardBulkConsolidatedFinaleStep = <
  T extends BookingWizardFinalSeatsStepFormValues
>({
  formik,
}: BookingWizardBulkConsolidatedFinaleStepProps<T>) => {
  const bookingBulkForm: BulkConsolidatedFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingBulkConsolidatedForm
  ) as BulkConsolidatedFormValues

  return (
    <BookingWizardSharedConsolidatedFinaleStep formik={formik} bookingBulkForm={bookingBulkForm} />
  )
}
