import { Redirect, Route, Switch } from "react-router-dom"
import { useAdminAuth } from "../hooks/useAdminAuth"
import {  PoweredBySolaasLab, SolaasLabMenuHeaderLogo } from "../../../../config/logos"
import { Login } from "../components/auth/Login"
import FirstLoginPasswordResetForm from "../components/forms/FirstLoginPasswordResetForm"
import { ForgotPassword } from "../components/forms/ForgotPassword"

export const AuthPage = () => {
    const auth = useAdminAuth()
    return (
      <div className='d-flex flex-column flex-column-fluid'>
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
          <img
            alt={SolaasLabMenuHeaderLogo.alt} 
            src={SolaasLabMenuHeaderLogo.src}
            className='img-fluid w-200px w-100 mb-8'
          />
          <div className='w-sm-500px w-300px bg-body opacity-75 rounded shadow-sm p-10 p-lg-15 rounded-0 mx-auto'>
            {auth?.isFirstLogin() && <Redirect to='/auth/password-reset' />}
            {auth && !auth.isFirstLogin() && <Redirect to='/' />}
            <Switch>
              <Route path='/auth/login/:hash'>
                <Login />
              </Route>
              <Route path='/auth/login'>
                <Login />
              </Route>
              <Route path='/auth/password-reset' component={FirstLoginPasswordResetForm} />
              <Route path='/auth/forgot-password'>
                <ForgotPassword />
              </Route>
              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>
          </div>
          <img alt={PoweredBySolaasLab.alt} src={PoweredBySolaasLab.src} className='h-75px mt-12' />
        </div>
      </div>
    )
  }
  