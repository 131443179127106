import React, {useCallback, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {ProductCard} from '../components/product-card/ProductCard'
import clsx from 'clsx'
import style from './RetailProductsStyle.module.scss'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'
import {ProductModel} from '../../../models/ems/ProductModel'
import {GetProductList} from '../components/redux/RetailBookingCrud'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {GetAppConfig} from '../../app-config/redux/AppConfigCRUD'
import {AppConfigModel} from '../../../models/app-config/AppConfigModel'

export const RetailProductsPage = () => {
  const history = useHistory()
  const {eventCode} = useParams<{eventCode?: string}>()
  const {pushError} = useAlerts()
  const [products, setProducts] = useState<GlobalSearchModel<ProductModel>>()
  const [appconfigContent, setAppconfigContent] = useState<AppConfigModel[]>()

  const handleBookNowClick = (code: string) => {
    history.push(`/auth/book/${code}/${eventCode}`)
  }

  const getProducts = useCallback(
    async (filters?: FilterModel) => {
      try {
        const {data} = await GetProductList({
          ...filters,
          filters: {
            event: eventCode,
            isSeated: false,
            isTimeslot: true,
          },
        })
        setProducts(data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [eventCode, pushError]
  )
  const GetConfigGroup = useCallback(async () => {
    try {
      const {data} = await GetAppConfig()
      setAppconfigContent(data)
    } catch (e: any) {
      pushError(e)
    }
  }, [pushError])

  useOnMount(() => {
    if (eventCode) {
      getProducts()
      GetConfigGroup()
    }
  })

  const appConfig = useMemo(() => {
    if (appconfigContent) {
      const siteObj = appconfigContent?.filter((item) => item.code === 'SITEURL')
      return siteObj[0]?.data
    }
  }, [appconfigContent])

  return (
    <>
      <h2 className={clsx('d-flex', 'justify-content-center', style.retailPageContainer)}>
        RETAIL PRODUCTS
      </h2>
      <div className={style.cardContainer}>
        {products &&
          appConfig &&
          products.data.map((product) => (
            <ProductCard
              key={product.code}
              product={product}
              onBookNow={() => handleBookNowClick(product.code)}
              config={appConfig}
            />
          ))}
      </div>
    </>
  )
}
