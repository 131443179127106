import {useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {v4 as uuidv4} from 'uuid'
import {
  ProductInputItem,
  ProductInputItemAttributes,
  ProductInputItemValue,
} from './ProductInputItem'
import {Button} from '../Button'

export interface ProductInputProps {
  className?: string
  items: ProductInputItemAttributes[]
  values: Array<ProductInputItemValue>
  onChange: (newValues: ProductInputItemValue[]) => void
  label: string
  disabled?: boolean
}

export const ProductInput = ({
  className,
  onChange,
  items,
  values,
  label,
  disabled,
}: ProductInputProps) => {
  const getInputId = useCallback(() => {
    return uuidv4()
  }, [])

  const handleOnAdd = useCallback(() => {
    const newValues: ProductInputItemValue[] = [...values, {count: 0, value: '', id: getInputId()}]
    onChange(newValues)
  }, [values, onChange, getInputId])

  const handleInputChange = useCallback(
    (changed: ProductInputItemValue) => {
      const inputIndex = values.findIndex((item) => item.id === changed.id)
      if (inputIndex >= 0) {
        const newValues = [...values]
        const modifiedItem = newValues[inputIndex]
        modifiedItem.count = changed.count
        modifiedItem.value = changed.value
        modifiedItem.type = changed.type
        modifiedItem.name = changed.name
        modifiedItem.price = changed.price
        if (modifiedItem.value && modifiedItem.count === 0) {
          modifiedItem.count = 1
        }
        onChange(newValues)
      }
    },
    [values, onChange]
  )

  const handleRemove = useCallback(
    (removed: ProductInputItemValue) => {
      const inputIndex = values.findIndex((item) => item.value === removed.value)
      if (inputIndex >= 0) {
        const newValues = [...values]
        newValues.splice(inputIndex, 1)
        onChange(newValues)
      }
    },
    [onChange, values]
  )

  const selectedItems = useMemo(() => {
    return values.map((item) => item.value)
  }, [values])

  const inputNodes = useMemo(() => {
    const nodes = values.map((item, i) => {
      return (
        <ProductInputItem
          key={`${item.value}${i}`}
          placeholder='Select a Product'
          onChange={handleInputChange}
          selectedItems={selectedItems}
          items={items}
          label={`${label} ${i + 1}`}
          onRemove={handleRemove}
          value={item}
        />
      )
    })
    return nodes
  }, [values, handleInputChange, handleRemove, label, selectedItems, items])

  const isAllItemsSelected = useMemo(() => {
    return items.every((item) => {
      const isSelected = values.some((value) => value.value === item.value)
      if (isSelected) {
        return true
      }
      return false
    })
  }, [items, values])

  const hasBlankItem = useMemo(() => {
    return values.some((some) => some.value === '')
  }, [values])

  const button = useMemo(() => {
    let label = 'Add Product'
    if (hasBlankItem) {
      return null
    }
    if (!values.length && isAllItemsSelected) {
      label = 'No products available'
    } else if (isAllItemsSelected) {
      label = 'No more products available'
    }

    return (
      <Button
        className='mb-5'
        size='sm'
        variant='primary'
        onClick={handleOnAdd}
        disabled={isAllItemsSelected || disabled}
        uppercase={false}
      >
        {label}
      </Button>
    )
  }, [disabled, handleOnAdd, hasBlankItem, isAllItemsSelected, values.length])

  return (
    <div className={clsx('', className)}>
      <div>{inputNodes}</div>
      {button}
    </div>
  )
}
