import {useCallback, useMemo, useState} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {useDispatch} from 'react-redux'
import {TicketPortalModel} from '../../../models/ems/TicketModel'
import {actions} from '../redux/CustomerPortalRedux'
import {
  ChangeTicketSeat,
  CancelTicket,
  DownloadTicket,
  ShareEmailTicketProps,
  ShareTicketViaEmail,
} from '../redux/CustomerPortalCRUD'
import {SwalUtils} from '../../../utils/SwalUtils'
import {TicketSeatReselectionModalFormValues} from '../../../components/forms/TicketSeatReselectionModalForm'
import {BlobUtils} from '../../../utils/BlobUtils'
import {InitialValuesProps} from '../components/modals/EmailModal'

export const useTicketTableActions = (onRefresh?: () => void, onRefreshCallback?: () => void) => {
  const {pushError, push} = useAlerts()
  const [loader, setLoader] = useState<boolean>(false)
  const {isLoading, isKeyLoading, setIsLoading} = useLoadingState()
  const dispatch = useDispatch()
  const reassignSeat = useCallback(
    async (values: Required<TicketSeatReselectionModalFormValues>) => {
      const doneLoading = setIsLoading(values.ticketCode)
      try {
        const {data} = await ChangeTicketSeat(values.ticketCode, {
          locationCode: values.locationCode,
          reason: values.reason,
          seatNo: values.column,
          seatRow: values.row,
        })
        BlobUtils.downloadBlob(data, `${Date.now()}.pdf`)
        dispatch(actions.bookingProducts.search())
      } catch (e: any) {
        pushError(e)
      } finally {
        onRefresh && onRefresh()
        onRefreshCallback && onRefreshCallback()
        doneLoading()
      }
    },
    [dispatch, onRefresh, onRefreshCallback, pushError, setIsLoading]
  )

  const cancel = useCallback(
    async (codes: string[]) => {
      await SwalUtils.asyncSpecifyReason(
        async (reason) => {
          const doneLoading = setIsLoading(codes)
          try {
            await CancelTicket(codes, reason)
            push({
              message: `successfully cancelled.`,
              timeout: 5000,
              variant: 'success',
            })
            onRefresh && onRefresh()
            onRefreshCallback && onRefreshCallback()
          } catch (e: any) {
            pushError(e)
          } finally {
            doneLoading()
          }
        },
        {
          titleText: 'Cancel Ticket',
          text: 'Please specify a reason.',
          confirmButtonText: 'Cancel!',
          icon: 'question',
          cancelButtonText: "Don't Cancel!",
        }
      )
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const handleCancelBulk = useCallback(
    (data: TicketPortalModel[]) => {
      cancel(data.map((bookings) => bookings.code))
    },
    [cancel]
  )

  const handleCancelSingle = useCallback(
    (data: TicketPortalModel) => {
      cancel([data.code])
    },
    [cancel]
  )
  const download = useCallback(
    async (codes: string[]) => {
      const doneLoading = setIsLoading(codes)
      try {
        const {data} = await DownloadTicket(codes)
        if (data) {
          const name = `Ticket_Details_${Date.now()}'
        )}`
          BlobUtils.downloadBlob(data, name)
        }
        push({
          message: `Successfully downloaded.`,
          timeout: 5000,
          variant: 'success',
        })
      } catch (e: any) {
        pushError(e)
      } finally {
        onRefresh && onRefresh()
        onRefreshCallback && onRefreshCallback()
        doneLoading()
      }
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const handleDownloadBulk = useCallback(
    (data: TicketPortalModel[]) => {
      download(data.map((bookings) => bookings.code))
    },
    [download]
  )

  const downloadSingle = useCallback(
    (data: TicketPortalModel) => {
      download([data.code])
    },
    [download]
  )

  const shareByEmail = useCallback(
    async (values: InitialValuesProps, codes: string[]) => {
      setLoader(true)
      try {
        if (values.email) {
          const payload: ShareEmailTicketProps = {
            email: values.email,
            ticketCodes: codes,
            name: values.name,
            eventCode: values.eventCode,
          }

          const {data} = await ShareTicketViaEmail(payload)
          push({
            message: `Successfully shared.`,
            timeout: 5000,
            variant: 'success',
          })
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
          // clearSelected && clearSelected()
          return data
        }
      } catch (err: any) {
        pushError(err)
        return err
      } finally {
        setLoader(false)
      }
      return []
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  return useMemo(
    () => ({
      isLoading,
      isKeyLoading,
      handleCancelBulk,
      cancelSingle: handleCancelSingle,
      reassignSeat,
      handleDownloadBulk,
      downloadSingle,
      shareByEmail,
      loader,
    }),
    [
      isLoading,
      isKeyLoading,
      handleCancelBulk,
      handleCancelSingle,
      reassignSeat,
      handleDownloadBulk,
      downloadSingle,
      shareByEmail,
      loader,
    ]
  )
}

export const isTicketCancel = (ticket: TicketPortalModel) => {
  return ticket.status === 'active' || ticket.status === 'sent'
}

export const isTicketIsReassignable = (ticket: TicketPortalModel) => {
  return ticket.status === 'active' && ticket.isSeated
}

export const isTicketDownloadable = (ticket: TicketPortalModel) => {
  return ticket.status === 'active' && ticket.productIsDownloadable
}
