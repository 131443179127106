import { useCallback, useMemo } from "react"
import { useRootStateSelector } from "../../../components/hooks/useRootStateSelector"

export const useAdminAuth = () => {
    const user = useRootStateSelector((state) => state.adminPortal.auth?.user)
    const getUser = useCallback(() => {
      if (!user) {
        throw new Error('User is not logged in.')
      }
      return user
    }, [user])
  
    const isFirstLogin = useCallback(() => {
      return getUser().isFirstLogin
    }, [getUser])
  
    return useMemo(() => {
      if (user) {
        return {
          getUser,
          isFirstLogin,
          user,
        }
      }
    }, [user, getUser, isFirstLogin])
  }
  