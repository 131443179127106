import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {Button} from '../../../inputs/Button'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import {SweetAlert} from '../../../modals/SweetAlert'
import {LoadingSpinner} from '../../../utils/LoadingSpinner'
import {ProductVoucherModalType} from '../../../../models/booking-wizard/BookingWizard'
import {ProductVoucherBulkModel} from '../../../../models/booking-wizard/BulkBookingWizard'

interface EditBulkProductVoucherModalProps {
  modalType: ProductVoucherModalType
  onModalClose: () => void
  onEdit: (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => void
  data: ProductVoucherBulkModel
}

export const EditBulkProductVoucherModal = ({
  modalType,
  onModalClose,
  onEdit,
  data,
}: EditBulkProductVoucherModalProps) => {
  const [value, setValue] = useState<ProductVoucherBulkModel>(data)

  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // const count = e.target.value
      let count = e.target.value
      if (Number(count) === 0) {
        count = '1'
      }
      const newValue: ProductVoucherBulkModel = {...data, qty: Number(count)}
      setValue(newValue)
    },
    [data]
  )

  const handleEdit = useCallback(() => {
    if (modalType === 'product' && value) onEdit(modalType, value)
    if (modalType === 'voucher' && value) onEdit(modalType, value)
  }, [modalType, onEdit, value])

  const updatedData = useMemo(() => {
    return {...data, qty: value.qty}
  }, [data, value])

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      <div className='h-100 d-flex flex-column'>
        <div className='d-flex flex-column flex-grow-1 text-start'>
          <div className='position-absolute top-0 end-0'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onModalClose}
            />
          </div>
          <h2 className='text-start mb-5'>{`Edit ${data.name || ''}`}</h2>
          <label className='text-start mb-5'>Quantity</label>
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            value={updatedData.qty}
            min={1}
          />
        </div>
        <div className='d-flex gap-5 justify-content-center mt-5'>
          <Button variant='primary' onClick={handleEdit}>
            <LoadingSpinner loading={false}>Save</LoadingSpinner>
          </Button>
          <Button className='me-1' onClick={onModalClose}>
            Close
          </Button>
        </div>
      </div>
    </SweetAlert>
  )
}
