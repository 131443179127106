import {useMemo} from 'react'
import {ProductVoucherBulkModel} from '../../../../models/booking-wizard/BulkBookingWizard'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ColumnStyle} from '../../..//tables/constants/ColumnStyle'
import {TableColumnOptions} from '../../..//tables/TableColumn'
import {Badge} from '../../../badge/Badge'
import {MetronicIcon} from '../../../inputs/MetronicIcon'
import {BasicTable} from '../../../tables/BasicTable'

export interface ProductVoucherBulkTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<ProductVoucherBulkModel>
  onRefresh?: () => void
  onDelete?: (code: string[]) => void
  onEdit?: (data: ProductVoucherBulkModel) => void
}

export const ProductVoucherBulkTable = ({
  data,
  onFilter,
  onDelete,
  onEdit,
}: ProductVoucherBulkTableProps) => {
  const tableColumns = useMemo(() => {
    return columns
  }, [])

  return (
    <>
      <BasicTable
        data={data}
        onFilter={onFilter}
        onDelete={onDelete}
        name='Product & Voucher'
        columns={tableColumns}
        hideSelections
        onEdit={onEdit}
      />
    </>
  )
}

export const columns: TableColumnOptions<ProductVoucherBulkModel>[] = [
  {
    field: 'code',
    label: 'Code',
    sortable: true,
    cellStyle: ColumnStyle.CODE,
  },
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    cellStyle: ColumnStyle.CODE,
  },
  {
    field: 'qty',
    label: 'Qty',
    sortable: true,
    hideable: true,
    cellStyle: ColumnStyle.NAME,
  },

  {
    field: 'type',
    label: 'Type',
    sortable: true,
    hideable: true,
    render: ({data}) => (
      <Badge
        className='text-nowrap'
        uppercase
        variant={data.type === 'product' ? 'info' : 'warning'}
      >
        {data.type}
      </Badge>
    ),
  },
  {
    field: 'isSeated',
    label: 'Seated?',
    sortable: true,
    hideable: true,
    render: ({data}) =>
      data.isSeated ? (
        <MetronicIcon
          className='svg-icon-success svg-icon-1hx'
          iconType='Navigation'
          iconName='Check'
        />
      ) : null,
  },
]
