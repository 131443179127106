import {useMemo} from 'react'
import moment from 'moment'
import {DateUtil} from '../../../utils/DateUtil'
import {useRootStateSelector} from '../../hooks/useRootStateSelector'

export interface DateFormatterProps {
  className?: string
  children: Date | string | null
  moment?: {
    parse?: string
    format: string
  }
  timezone?: string
  isConvert?: boolean
}

export const DateFormatter = ({
  children,
  className,
  moment: momentProp,
  timezone,
  isConvert = false,
}: DateFormatterProps) => {
  const momentParse = momentProp?.parse
  const momentFormat = momentProp?.format
  const dateString = useMemo(() => {
    if (!children) {
      return ''
    }
    if (isConvert) {
      return children
    }
    if (timezone) {
      return DateUtil.convertDateTimeToVenueTimezone(
        `${children}`,
        timezone,
        momentFormat || 'MMM DD, YYYY'
      )
    }

    if (momentFormat) {
      return moment(children, momentParse).format(momentFormat)
    }

    return DateUtil.formatToDateString(children)
  }, [children, momentFormat, momentParse, timezone, isConvert])

  return <time className={className}>{dateString}</time>
}
