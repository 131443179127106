import {FormikContextType} from 'formik'
import React from 'react'
import {ScheduleMeeting} from 'react-schedule-meeting'
import { DateUtil } from '../../../../../utils/DateUtil'
// import moment from 'moment'

export interface BookingWizardCalendarInformationValues {
  bookingDate: Date | null
}

export interface BookingWizardCalendarInformationProps<T
  extends BookingWizardCalendarInformationValues > {
  formik: FormikContextType<T>
  onDateSelected: () => void
}

export interface StartTimeEventEmit {
  availableTimeslot: AvailableTimeslot
  startTime: Date
  splitTimeslot?: [SplitTimeslot, SplitTimeslot]
  resetDate: () => void
  resetSelectedTimeState: () => void
}
type AvailableTimeslot = {
  startTime: Date
  endTime: Date
  id?: string | number | undefined
}

type SplitTimeslot = null | ModifiedTimeslot

type ModifiedTimeslot = AvailableTimeslot & {
  oldId: string | number | undefined
}

export const BookingWizardCalendarInformation = <T extends BookingWizardCalendarInformationValues>({
  formik,
  onDateSelected,
}: BookingWizardCalendarInformationProps<T>) => {

  const availableTimeslots = [
    0, 1, 3, 4, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 21, 22, 23, 24, 25, 28, 29, 30, 31, 32, 33,
    35, 36, 37, 38, 39, 40, 41,42,44,45
  ].map((id) => {
    return {
      id,
      startTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setHours(9, 0, 0, 0)
      ),
      endTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setHours(20, 0, 0, 0)
      ),
    }
  })

  const handleTimeslotClicked = (data: any) => {
    formik.setFieldValue('bookingDate', DateUtil.convertDateToApiString(data.startTime))
    // data.resetDate();
    // data.resetSelectedTimeState();
    // alert(`Time selected: ${moment(data.startTime, 'cccc, LLLL do h:mm a')}`);
  }
  return (
    <>
      <ScheduleMeeting
        borderRadius={10}
        primaryColor='#3f5b85'
        eventDurationInMinutes={60}
        availableTimeslots={availableTimeslots}
        format_selectedDateDayTitleFormatString='LLLL do'
        startTimeListStyle='scroll-list'
        onStartTimeSelect={handleTimeslotClicked}
        lang_confirmButtonText='Confirm this time? '
        skipConfirmCheck    
        // lang_selectedButtonText=''
      />
    </>
  )
}

export const STEP_CALENDAR_KEYS: Array<keyof BookingWizardCalendarInformationValues> = [
  'bookingDate'
]
