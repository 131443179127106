import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ProductCategoryModel} from '../../../../models/retail/ProductCategoryModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GetRetailProductCategories, GetProducts, GetProductDateRange} from './RetailCRUD'
import {ProductModel} from '../../../../models/retail/ProductModel'
import {ProductDateRangeModel} from '../../../../models/retail/ProductDateRangeModel'

interface ActionWithPayload<T> extends Action {
  payload: T
}

interface IPagesState {
  productCategories?: GlobalSearchModel<ProductCategoryModel>
  products?: GlobalSearchModel<ProductModel>
  productsDateRange?: GlobalSearchModel<ProductDateRangeModel>
}

const initialAuthState: IPagesState = {
  productCategories: undefined,
  products: undefined,
  productsDateRange: undefined,
}

const actionTypes = {
  SEARCH_PRODUCT_CATEGORY: '[RETAIL] SEARCH PRODUCT CATEGORY',
  SEARCH_PRODUCT_CATEGORY_SUCCESS: '[RETAIL] SEARCH PRODUCT CATEGORY SUCCESS',
  SEARCH_PRODUCT_CATEGORY_FAILED: '[RETAIL] SEARCH PRODUCT CATEGORY FAILED',

  SEARCH_PRODUCT: '[RETAIL] SEARCH PRODUCT',
  SEARCH_PRODUCT_SUCCESS: '[RETAIL] SEARCH PRODUCT SUCCESS',
  SEARCH_PRODUCT_FAILED: '[RETAIL] SEARCH PRODUCT FAILED',

  SEARCH_PRODUCT_DATERANGE: '[RETAIL] SEARCH PRODUCT DATE RANGE',
  SEARCH_PRODUCT_DATERANGE_SUCCESS: '[RETAIL] SEARCH PRODUCT DATE RANGE SUCCESS',
  SEARCH_PRODUCT_DATERANGE_FAILED: '[RETAIL] SEARCH PRODUCT DATE RANGE FAILED',
}

export const reducer = persistReducer(
  {storage, key: 'webapp-master', whitelist: []},
  (state: IPagesState = initialAuthState, action: Partial<ActionWithPayload<IPagesState>>) => {
    switch (action.type) {
      case actionTypes.SEARCH_PRODUCT_CATEGORY_SUCCESS: {
        const productCategories = action.payload?.productCategories
        return {...state, productCategories}
      }
      case actionTypes.SEARCH_PRODUCT_SUCCESS: {
        const products = action.payload?.products
        return {...state, products}
      }
      case actionTypes.SEARCH_PRODUCT_DATERANGE_SUCCESS: {
        const productsDateRange = action.payload?.productsDateRange
        return {...state, productsDateRange}
      }
      default:
        return state
    }
  }
)

export const actions = {
  searchProductCategory: () => ({
    type: actionTypes.SEARCH_PRODUCT_CATEGORY,
  }),
  searchProductCategoryFailed: () => ({type: actionTypes.SEARCH_PRODUCT_CATEGORY_FAILED}),
  searchProductCategorySuccess: (productCategories: GlobalSearchModel<ProductCategoryModel>) => ({
    type: actionTypes.SEARCH_PRODUCT_CATEGORY_SUCCESS,
    payload: {productCategories},
  }),
  searchProduct: () => ({
    type: actionTypes.SEARCH_PRODUCT,
  }),
  searchProductFailed: () => ({type: actionTypes.SEARCH_PRODUCT_FAILED}),
  searchProductSuccess: (products: GlobalSearchModel<ProductModel>) => ({
    type: actionTypes.SEARCH_PRODUCT_SUCCESS,
    payload: {products},
  }),
  searchProductDateRange: () => ({
    type: actionTypes.SEARCH_PRODUCT_DATERANGE,
  }),
  searchProductDateRangeFailed: () => ({type: actionTypes.SEARCH_PRODUCT_DATERANGE_FAILED}),
  searchProductDateRangeSuccess: (productsDateRange: GlobalSearchModel<ProductDateRangeModel>) => ({
    type: actionTypes.SEARCH_PRODUCT_DATERANGE_SUCCESS,
    payload: {productsDateRange},
  }),
}

export function* saga() {
  // PRODUCT CATEGORY
  yield takeLatest(actionTypes.SEARCH_PRODUCT_CATEGORY, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['product-category'])
      const {data} = yield GetRetailProductCategories(filter)
      yield put(actions.searchProductCategorySuccess(data))
    } catch (e) {
      yield put(actions.searchProductCategoryFailed())
    }
  })

  yield takeLatest(actionTypes.SEARCH_PRODUCT, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters.product)
      const {data} = yield GetProducts(filter)
      yield put(actions.searchProductSuccess(data))
    } catch (e) {
      yield put(actions.searchProductFailed())
    }
  })

  yield takeLatest(actionTypes.SEARCH_PRODUCT_DATERANGE, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['product-date-range']
      )
      const {data} = yield GetProductDateRange(filter)
      yield put(actions.searchProductDateRangeSuccess(data))
    } catch (e) {
      yield put(actions.searchProductDateRangeFailed())
    }
  })
}
